import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Text.module.sass";

function Text({ children, variant, gutterBottom, gutterTop, center, className, }) {
  return (
    <div
      className={cx(
        styles.wrap,
        gutterBottom ? styles.gutterBottom : "",
        gutterTop ? styles.gutterTop : "",
        center ? styles.center : "",
        variant === "h1" ? styles.heading1 : "",
        variant === "h2" ? styles.heading2 : "",
        variant === "h3" ? styles.heading3 : "",
        variant === "h4" ? styles.heading4 : "",
        variant === "p" ? styles.paragraph : "",
        variant === "note" ? styles.note : "",
        variant === "ex" ? styles.ex : "",
        className,
      )}
    >
      {children}
    </div>
  );
}

Text.propTypes = {
  children: PropTypes.node,
  gutterTop: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  center: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
};

Text.defaultProps = {
  variant: "p",
};

export default Text;
