import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.sass';

const renderErrorMessages = (errorMessages) => {
  let rows = [];
  errorMessages.map((error, index) => {
    rows.push(
    <div key={index} className={styles.errorMessage}>{error}</div>)
  })
  return rows;
}

const Input = ({
  type,
  size,
  readOnly,
  disabled,
  disabledStyle,
  error,
  errorMessages,
  placeholder,
  value,
  name,
  ...props
}) => (
  <div className={cx(
    styles.wrap,
    error && styles.error,
    errorMessages && errorMessages.length > 0 ? styles.error : null
  )}>
    <input
      {...props}
      className={cx(
        styles.control,
        size == "large" ? styles.large : null,
        disabledStyle ? styles.disabledStyle : null,
      )}
      name={name}
      value={value}
      type={type}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
    />
    {errorMessages && renderErrorMessages(errorMessages)}
  </div>
)

Input.propTypes = {
  style: PropTypes.shape({}),
  type: PropTypes.oneOf([
    'text',
    'email',
    'tel',
    'password',
    'number',
    'search',
    'color',
    'date',
    'time',
    'datetime-local',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledStyle: PropTypes.bool,
  error: PropTypes.bool,
  errorMessages: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
}

Input.defaultProps = {
  value: '',
  style: undefined,
  type: 'text',
  size: undefined,
  readOnly: false,
  disabled: false,
  disabledStyle: false,
  error: false,
  placeholder: '',
  name: undefined,
}


export default Input