import cx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Alert from "../../partials/Alert";
import Button from "../../partials/Button";
import ButtonClose from "../../partials/ButtonClose";
import { CheckUser } from "../../partials/checkUser";
import Input from "../../partials/Input";
import Modal from "../../partials/Modal";
import Text from "../../partials/Text";
import styles from "./routerSettings.module.sass";

function RouterSettings({ router, status, ...props }) {
  const [isEditing, setEditing] = useState(false);
  const [routerName, setRouterName] = useState("");
  const [routerOriginalName, setRouterOriginalName] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [user, setUser] = useState(null);
  const [deviceList, setDeviceList] = useState([]);
  const [isShowingAwaitingResultModal, setIsShowingAwaitingResultModal] =
    useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    onload();
    return () => {};
  }, []);

  const onload = async () => {
    let newUser = await CheckUser(props.Auth);
    if (newUser) {
      setUser(newUser);
    }
    setRouterName(router.name);
    setRouterOriginalName(router.name);
    let newDeviceList = [];
    router.devices.map((d) => {
      if (!d.suspended) {
        newDeviceList.push(d);
      }
    });
    setDeviceList(newDeviceList);
  };

  const handleChangeSample = (event) => {
    setRouterName(event.target.value);
  };

  const startEditing = () => {
    props.EE.emit("stopreload");
    setEditing(true);
  };

  const endEditing = () => {
    props.EE.emit("startreload");
    setRouterName(routerOriginalName);
    setShowAlert(false);
    setErrorMessages([]);
    setEditing(false);
  };

  const saveData = async () => {
    setShowAlert(false);
    openAwaitingResultModal();
    let errors = [];
    setErrorMessages([]);
    if (routerName == "" || routerName == null) {
      errors.push("親機の名前を入力してください");
    } else if (routerName.length > 20) {
      errors.push("親機の名前は20文字以内です");
    }
    if (errors.length > 0) {
      closeAwaitingResultModal();
      setErrorMessages(errors);
      return;
    } else {
      const setting = {
        name: routerName,
        active: router.active,
        user_id: user.attributes.sub,
        router_id: router.router_id,
      };
      try {
        const session = await props.Auth.currentSession();
        const response = await props.API.post(
          "UpdateRouter",
          "/update_router",
          {
            headers: { Authorization: session.idToken.jwtToken },
            body: setting,
          }
        );
        props.closeRouterSettingModal(true);
      } catch (e) {
        setShowAlert(true);
        setErrorMessages(["親機の名前を保存できませんでした"]);
        closeAwaitingResultModal();
      }
    }
  };

  const handleOpenDeviceSetting = (device) => {
    return (e) => {
      //props.openSensorSetting(device);
      props.EE.emit("openDeviceSettingModal", { device: device });
      props.closeRouterSettingModal(false);
    };
  };

  const openAwaitingResultModal = (e) => {
    document.body.classList.add("showing-modal");
    setIsShowingAwaitingResultModal(true);
  };

  const closeAwaitingResultModal = (e) => {
    document.body.classList.remove("showing-modal");
    setIsShowingAwaitingResultModal(false);
  };

  return (
    <>
      {/* 名前の変更モーダル */}
      <Modal show={isEditing} type="Dialog" title="名前の変更">
        {showAlert && (
          <Alert>
            ルーターの更新に失敗しました。もう一度、「変更を適用」をタップしてください。
          </Alert>
        )}
        <Input
          errorMessages={errorMessages}
          type="text"
          value={routerName}
          onChange={handleChangeSample}
        />
        <div className={styles.actions}>
          <Button onClick={saveData} fit positive>
            変更する
          </Button>
          <Button onClick={endEditing} fit>
            キャンセル
          </Button>
        </div>
      </Modal>

      {/* 表示用のテンプレート */}
      <div className={styles.viewWrap}>
        <div className={styles.viewHeader}>
          <div className={styles.right}>
            <ButtonClose onClick={() => props.closeRouterSettingModal()} />
          </div>
        </div>
        <div className={styles.viewBody}>
          <div className={styles.header}>
            <div className={styles.image}>
              <img
                src="/assets/images/icon-router-blue.png"
                alt="親機ルーター起動中"
              />
            </div>
            <div className={styles.name}>
              {status === "Inactive" ? router.router_id : router.name}
            </div>
            <div className={styles.serialNumber}>
              製造番号：{router.router_id}
            </div>
            {status !== "Inactive" && (
              <div className={styles.button}>
                <Button size="small" onClick={startEditing}>
                  名前の変更
                </Button>
              </div>
            )}
            {/* 死活の「確認中」の場合 */}
            {!props.statusChecked && (
              <div className={styles.routerStatus}>
                親機ルーターの状態を確認しています...
              </div>
            )}
            {/* 死活の結果が「正常」の場合 */}
            {(props.statusChecked && router.isAlive) && (
              <div className={cx(styles.routerStatus, styles.nomal)}>
                この親機ルーターは正常に動作しています。近くにある子機センサーは、この親機を使って、計測した温度や湿度の値を送り、てるちゃんで確認することができます。
              </div>
            )}
            {/* 死活の結果が「利用不可」の場合 */}
            {(props.statusChecked && router.isAlive === false) && (
              <div className={cx(styles.routerStatus, styles.error)}>
                <div>
                  この親機ルーターは利用できません。ルーターの電源が入っていないか、電波の届かない場所に設置されている可能性があります。
                  <br />
                  <br />
                  問題が解決しない場合は再起動をお試しください。
                </div>
                <img
                  src="/assets/images/fig-router-reset.png"
                  alt="親機ルーターリセット図"
                  className={styles.routerResetImage}
                />
                <div className={styles.inactiveText}>
                  本体ボタンを７秒以上押すと、回転発光していたLEDが止まります。ボタンから指を離すと虹色に回転発光が始まり、再起動します。
                </div>
              </div>
            )}
          </div>
          {/* 接続している子機センサー */}
          {status !== "Inactive" && (
            <div className={styles.sensorLists}>
              {deviceList.length > 0 && (
                <>
                  <Text variant="h4">最近この親機を利用した子機センサー</Text>
                  <Text gutterBottom>
                    子機センサーは接続できる親機ルーターへ自動的に繋がります。
                  </Text>
                  <div className={styles.lists}>
                    {deviceList.map((device, n) => (
                      <div key={n} className={styles.list}>
                        <div className={styles.icon}>
                          <img
                            src="/assets/images/icon-sensor.png"
                            alt="子機センサー"
                          />
                        </div>
                        <div className={styles.name}>{device.name}</div>
                        <div className={styles.action}>
                          <Button
                            size="small"
                            onClick={handleOpenDeviceSetting(device)}
                          >
                            設定
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {deviceList.length === 0 && (
                <div className={styles.sensorListsBlank}>
                  <div className={styles.image}>
                    <img
                      src="/assets/images/icon-sensor-disabled.png"
                      alt="子機センサー停止中"
                    />
                  </div>
                  <div className={styles.title}>
                    接続されている子機センサーはありません
                  </div>
                  <div className={styles.text}>
                    子機センサーは接続できる親機ルーターへ自動的に繋がります。
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal show={isShowingAwaitingResultModal} type="AwaitingResult" />
    </>
  );
}

RouterSettings.propTypes = {
  router: PropTypes.object,
};

export default RouterSettings;
