import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Textarea.module.sass';

const Textarea = ({
  readOnly,
  disabled,
  error,
  placeholder,
  value,
  rows,
  name,
  ...props
}) => (
  <div className={cx(
    styles.wrap,
    error && styles.error
  )}>
    <textarea
      {...props}
      className={styles.control}
      name={name}
      value={value}
      rows={rows}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
    />
  </div>
)

Textarea.propTypes = {
  style: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  rows: PropTypes.number
}

Textarea.defaultProps = {
  value: '',
  style: undefined,
  readOnly: false,
  disabled: false,
  error: false,
  placeholder: '',
  name: undefined,
  rows: 5,
}


export default Textarea