import React, { useEffect, useState } from 'react';
import { A, navigate } from 'hookrouter';
import PasswordStrengthBar from 'react-password-strength-bar';
import AuthorizedHeader from '../../partials/AuthorizedHeader';
import { ValidateUser } from '../../partials/validateUser';
import { EncryptPassword } from "../../partials/encryptPassword";
import Footer from '../../partials/Footer';
import PageTitle from '../../partials/PageTitle';
import Button from '../../partials/Button';
import Field from '../../partials/Field';
import Input from '../../partials/Input';
import Modal from '../../partials/Modal';
import RegistrationGuidePhoneNumberSignIn from '../../partials/RegistrationGuidePhoneNumberSignIn';
import Text from '../../partials/Text';
import Wrap from '../../partials/Wrap';
import styles from './index.module.sass';
import { Telchan } from '../../Telchan';
import { CheckUser } from '../../partials/checkUser';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import UpdatePhoneNumber from '../../partials/UpdatePhoneNumber';
export const useDidMount = (func) => useEffect(() => { func() }, []);

function Account(props) {
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [phoneNumberCode, setPhoneNumberCode] = useState('');
  const [isShowingEditNameModal, setShowingEditNameModal] = useState(false);
  const [isShowingEditPhoneNumberModal, setShowingEditPhoneNumberModal] = useState(false);
  const [isShowingEditEmailModal, setShowingEditEmailModal] = useState(false);
  const [isShowingEditLineModal, setShowingEditLineModal] = useState(false);
  const [isShowingEditPasswordModal, setShowingEditPasswordModal] = useState(false);
  const [isShowingConfirmEmailModal, setShowingConfirmEmailModal] = useState(false);
  const [isShowingConfirmPhoneNumberModal, setShowingConfirmPhoneNumberModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  const initErrorMessages = async () => {
    let newErrorMessages = {};
    await Promise.all(['email', 'name', 'password', 'password_confirmation', 'phoneNumber', 'code'].map(async (key) => {
      newErrorMessages[key] = await [];
    }));
    return newErrorMessages;
  };

  const onload = async () => {
    // https://github.com/tel-chan/tel-chan/issues/1268
    if (Telchan.env == 'development') {
      const test = ['password', 'pass1234', 'pass123$', '12345678', 'パスワードだよ', '!@#$%+=-', 'PASSWORD', 'PASSWORD1234', 'Pass1234', 'Pass123$'];
      test.forEach((pass) => {
        console.log(pass, ValidateUser.validatePassword(pass));
      });
    }
    setErrorMessages(await initErrorMessages());
    try {
      const session = await props.Auth.currentSession();
      let user = await CheckUser(props.Auth, true, session);
      if (user) {
        setUser(user);
      } else {
        navigate('/');
      }
    } catch(e) {
      navigate('/');
    }
  };

  useDidMount(async () => {
    onload();
  });

  const clearErrorMessage = async () => {
    if (isShowingEditNameModal == false
    ||  isShowingEditPhoneNumberModal == false
    ||  isShowingEditEmailModal == false
    ||  isShowingEditLineModal == false
    ||  isShowingEditPasswordModal == false
    ||  isShowingConfirmEmailModal == false
    ||  isShowingConfirmPhoneNumberModal == false
    ) {
      await setErrorMessages(await initErrorMessages());
    }
  };

  useEffect(() => {
    clearErrorMessage();
  }, [isShowingEditNameModal, isShowingEditPhoneNumberModal, isShowingEditEmailModal, isShowingEditLineModal, isShowingEditPasswordModal, isShowingConfirmEmailModal, isShowingConfirmPhoneNumberModal]);

  useEffect(() => {
    if (user && user.attributes) {
      let pn;
      try {
        pn = user.attributes.phone_number ? parsePhoneNumber(user.attributes.phone_number).formatNational() : '';
      } catch(e) {
        pn = '';
      }
      setProfile({
        name: user.attributes.name,
        email: user.attributes.email,
        phoneNumber: pn,
        address1: user.attributes['custom:address1'],
        address2: user.attributes['custom:address2'],
        address3: user.attributes['custom:address3'],
        zipcode: user.attributes['custom:zipcode'],
      });
    }
  }, [user]);

  if (!user.attributes) {
    return (<div />);
  }

  if (profile.name === null) { return <div />; }

  const parsePhoneNumber = (number) => {
    return parsePhoneNumberFromString(number, 'JP');
  };

  const openConfrimEmailModal = () => {
    setShowingEditEmailModal(false)
    setShowingConfirmEmailModal(true)
  }

  const openConfrimPhoneNumberModal = () => {
    setShowingEditPhoneNumberModal(false)
    setShowingConfirmPhoneNumberModal(true)
  }

  const updateProfile = (data) => {
    let newProfile = {};
    Object.keys(profile).map((key) => {
      newProfile[key] = profile[key];
    });
    Object.keys(data).map((key) => {
      newProfile[key] = data[key];
    });
    setProfile(newProfile);
  };

  const updatePassword = async () => {
    await setErrorMessages(await initErrorMessages());
    let errors = [];
    let newErrors = [];
    if (oldPassword === '') {
      errors.push('現在のパスワードを入力してください');
    }
    if (password === '') {
      newErrors.push('新しいパスワードを入力してください');
    } else {
      newErrors = newErrors.concat(ValidateUser.validatePassword(password));
    }
    if (password !== passwordConfirmation) {
      newErrors.push('パスワードが一致しません');
    }
    if (errors.length > 0 || newErrors.length > 0) {
      let newErrorMessages = await initErrorMessages();
      newErrorMessages.password = errors;
      newErrorMessages.password_confirmation = newErrors;
      setErrorMessages(newErrorMessages);
    } else {
      try {
        const savedUser = await props.Auth.currentAuthenticatedUser()
        await props.Auth.changePassword(savedUser, oldPassword, password);
        await EncryptPassword.encryptPassword(props, password);
        setShowingEditPasswordModal(false);
      } catch(e) {
        let newErrorMessage;
        if (e.message == 'Incorrect username or password.') {
          newErrorMessage = '現在のパスワードに誤りがあります';
        } else if (e.message == 'Attempt limit exceeded, please try after some time.') {
          newErrorMessage = 'パスワードの試行回数が上限に達しました';
        } else {
          newErrorMessage = '申し訳ございません。エラーが発生しました';
        }
        let newErrorMessages = await initErrorMessages();
        newErrorMessages.password = [newErrorMessage];
        setErrorMessages(newErrorMessages);
      }
    }
  };

  const saveTmpProfile = async (key, callback) => {
    await setErrorMessages(await initErrorMessages());
    const saveData = {action: `profile-${key}`, data: {}};
    saveData.data[key] = profile[key];
    const session = await props.Auth.currentSession();
    try {
      const checkData = {};
      if (key == 'phoneNumber') {
        try {
          checkData['phone_number'] = parsePhoneNumberFromString(profile[key], 'JP').number;
        } catch(e) {
          checkData['phone_number'] = '';
        }
      }
      if (key == 'email') { checkData[key] = profile[key]; }
      const users = await ValidateUser.checkIfUserExists(checkData);
      if (key == 'phoneNumber' && checkData['phone_number'] == '') {
        let phoneErrorMessages = await initErrorMessages();
        phoneErrorMessages[key].push(`電話番号の形式に誤りがあります`);
        setErrorMessages(phoneErrorMessages);
      } else if (key == 'email' && !checkData[key].match(/@/)) {
        let emailErrorMessages = await initErrorMessages();
        emailErrorMessages[key].push(`メールアドレスに＠を挿入してください`);
        setErrorMessages(emailErrorMessages);
      } else if (parseInt(users) <= 0) {
        try {
          await props.API.post('SaveTmpData', '/save_tmp_data', {
            headers: {Authorization: session.idToken.jwtToken}, body: saveData});
          callback();
        } catch (e) {
          let newErrorMessages = await initErrorMessages();
          newErrorMessages[key].push('通信エラーが発生しました');
          setErrorMessages(newErrorMessages);
        }
      } else {
        let newErrorMessages = await initErrorMessages();
        switch(key) {
        case 'email':
          newErrorMessages[key].push(`このメールアドレスは既に利用されています`);
          break;
        case 'phoneNumber':
          newErrorMessages[key].push(`この電話番号は既に利用されています`);
          break;
        }
        setErrorMessages(newErrorMessages);
      }
    } catch(e) {
      let newErrorMessages = await initErrorMessages();
      newErrorMessages[key].push('通信エラーが発生しました');
      setErrorMessages(newErrorMessages);
    }
  };

  const authConfirmationCode = async (callback) => {
    await setErrorMessages(await initErrorMessages());
    const saveData = {
      code: phoneNumberCode,
      action: 'profile-phoneNumber'
    };
    try {
      const session = await props.Auth.currentSession();
      await props.API.post('ConfirmCode', '/confirm_code', {
        headers: {Authorization: session.idToken.jwtToken}, body: saveData});
      callback();
    } catch (e) {
    }
  };

  const resendPassCode = async () => {
    await setErrorMessages(await initErrorMessages());
    const saveData = {action: 'profile-phoneNumber'};
    try {
      const session = await props.Auth.currentSession();
      await props.API.post('ResendConfirmCode', '/resend_confirm_code', {
        headers: {Authorization: session.idToken.jwtToken}, body: saveData});
    } catch (e) {
    }
  };

  const deleteTmpData = async (key) => {
  };

  const saveName = async () => {
    await setErrorMessages(await initErrorMessages());
    try {
      if (profile.name == '') {
        setErrorMessages({name: ['名前を入力してください']});
        return;
      } else if (profile.name.length > 100) {
        setErrorMessages({name: ['名前が長すぎます']});
      } else {
        let saveData = {name: profile.name};
        const session = await props.Auth.currentSession();
        saveData.accessToken = session.accessToken.jwtToken;
        const alert_to = await props.API.post('UpdateAccount', '/update_account', {
          headers: {Authorization: session.idToken.jwtToken}, body: saveData});
        setShowingEditNameModal(false);
      }
    } catch(e) {
      setErrorMessages({name: ['通信エラーが発生しました']});
    }
  };

  return (
    <>
      <AuthorizedHeader current="menu" />
      <Wrap>
        <PageTitle title="お客様情報" backURL="/menu" />
        <div className={styles.customerInformation}>
          <div className={styles.customerDetail}>
            <div className={styles.nameLabel}>名前：請求書宛名に記載します</div>
            <div className={styles.customerName} data-private>{profile.name}</div>
          </div>
          <Button
            onClick={() => setShowingEditNameModal(true)} size="small"
          >
            変更
          </Button>
        </div>

        <div className={styles.customerInformation}>
          <div className={styles.customerDetail}>
            <div className={styles.nameLabel}>メールアドレス</div>
            <div className={styles.customerName} data-private>{profile.email}</div>
          </div>
          <Button onClick={() => setShowingEditEmailModal(true)} size="small">変更</Button>
        </div>

        <div className={styles.customerInformation}>
          <div className={styles.customerDetail}>
            <div className={styles.nameLabel}>パスワード</div>
            <div className={styles.customerName}>設定済み</div>
          </div>
          <Button onClick={() => setShowingEditPasswordModal(true)} size="small">変更</Button>
        </div>

        <div className={styles.customerInformation}>
          <div className={styles.customerDetail}>
            <div className={styles.nameLabel}>電話番号</div>
            <div className={styles.customerName} data-private>{profile.phoneNumber}</div>
          </div>
          <Button onClick={() => setShowingEditPhoneNumberModal(true)} size="small">変更</Button>
        </div>

        {/*
        <div className={styles.phoneNumberSignin}>
          <RegistrationGuidePhoneNumberSignIn />
        </div>
        */}
      </Wrap>

      {/* 名前の変更 */}
      <Modal show={isShowingEditNameModal} type="Dialog" title="名前の変更">
        <Field label="名前：請求書宛名に記載します">
          <Input maxLength="20" type="text" value={profile.name} onChange={
             (e) => { updateProfile({name: e.target.value}); }
          } errorMessages={errorMessages['name']} />
        </Field>
        <div className={styles.modalContentArea}>
          <Button positive fit onClick={saveName}>変更する</Button>
          <Button fit onClick={() => setShowingEditNameModal(false)}>閉じる</Button>
        </div>
      </Modal>

      {/* 電場番号の変更 */}
      <Modal show={isShowingEditPhoneNumberModal} type="Dialog" title="電話番号の変更">
        {/*<UpdatePhoneNumber onClose={() => setShowingEditPhoneNumberModal(false)} />*/}
        <Field label="電話番号">
          <Input type="tel" maxLength="20" value={profile.phoneNumber} onChange={
            (e) => {
              updateProfile({phoneNumber: e.target.value})
            }
          } errorMessages={errorMessages['phoneNumber']} />
        </Field>
        <div className={styles.modalContentArea}>
          <div className={styles.noticeMessage}>通知先も変更になります。</div>
          <Button positive fit onClick={async () => {
            await saveTmpProfile('phoneNumber', () => {
              setShowingEditPhoneNumberModal(false);
              setShowingConfirmPhoneNumberModal(true);
            });
          }}>変更する</Button>
          <Button fit onClick={() => setShowingEditPhoneNumberModal(false)}>閉じる</Button>
        </div>
      </Modal>

      {/* メールアドレスの変更 */}
      <Modal show={isShowingEditEmailModal} type="Dialog" title="メールアドレスの変更">
        <Field label="メールアドレス">
          <Input maxLength="100" type="email" value={profile.email} onChange={(e) => {
            updateProfile({email: e.target.value});
          }} errorMessages={errorMessages.email} />
        </Field>
        <div className={styles.modalContentArea}>
          <div className={styles.noticeMessage}>通知先も変更になります。</div>
          <Button positive fit onClick={() => {
            saveTmpProfile('email', () => {
              setShowingConfirmEmailModal(true);
              setShowingEditEmailModal(false);
            });
          }}>変更する</Button>
          <Button fit onClick={() => setShowingEditEmailModal(false)}>閉じる</Button>
        </div>
      </Modal>

      {/* 連携LINEアカウントの変更 */}
      <Modal show={isShowingEditLineModal} type="Dialog" title="通知先LINEを登録">
        <div className={styles.modalContentArea}>
          <div className={styles.lineOpenArea}>
            <div className={styles.dummyQrcode}><img src="/assets/images/dummy-qr-code.png" width='157px' alt="QRコードのダミー画像" /></div>
            <Button primary onClick={() => setShowingEditLineModal(false)}>LINEを起動</Button>
          </div>
          <Text center gutterBottom gutterTop>QRを読み込むか、ボタンをタップして「てるちゃん」をフォローしてください</Text>
          <Button fit onClick={() => setShowingEditLineModal(false)}>閉じる</Button>
        </div>
      </Modal>

      {/* パスワードの変更 */}
      <Modal show={isShowingEditPasswordModal} type="Dialog">
        <Text variant="h2" center gutterBottom>パスワードの変更</Text>
        <Text center gutterBottom>現在のパスワードと新しいパスワードを入力してください。</Text>
        <Field label="現在のパスワード">
          <Input type="password" maxLength="64" onChange={(e) => {setOldPassword(e.target.value)}}  value={oldPassword} errorMessages={errorMessages.password} />
        </Field>
        <Field label="新しいパスワード">
          <Input type="password" maxLength="64" onChange={(e) =>  {setPassword(e.target.value);}} value={password} errorMessages={errorMessages.password_confirmation} />
        </Field>
        <Text>数字、英字(小)、英字(大)を全て含む8文字以上</Text>
        <PasswordStrengthBar
            style={{marginTop: "4px", marginBottom: "4px"}}
            password={password}
            scoreWords={['弱い - 推測されやすいパスワードです', '弱い - 推測されやすいパスワードです', '良い - 比較的推測されにくいパスワードです', '非常に良い - 推測されにくいパスワードです', '最高 - 推測ができず、とても堅牢なパスワードです']}
            scoreWordStyle={{color: "#2D2E31"}}
            shortScoreWord='数字、英字(小)、英字(大)を全て含む8文字以上'
            minLength={8}
        />
        <Field label="新しいパスワード（確認）">
          <Input type="password" maxLength="64" onChange={(e) =>  {setPasswordConfirmation(e.target.value);}} value={passwordConfirmation} errorMessages={errorMessages.password_confirmation} />
        </Field>
        <Text gutterBottom>確認のためもう一度ご入力ください</Text>
        <div className={styles.modalContentArea}>
          <Button onClick={updatePassword} fit positive>変更する</Button>
          <Button onClick={() => setShowingEditPasswordModal(false)} fit>閉じる</Button>
        </div>
      </Modal>

      {/* メール通知の疎通確認 */}
      <Modal show={isShowingConfirmEmailModal} type="Dialog" title="メールをご確認ください">
        <Text gutterBottom>本人確認のためメールを送信しました。メール本文にある「認証」をクリックしてください。メールが届かない場合は、ご指定のメールアドレスに誤りがあるか、既に利用されている可能性があります。再度ご確認の上、やり直してください。</Text>
        <div>
          <Button onClick={() => setShowingConfirmEmailModal(false)} fit>閉じる</Button>
        </div>
      </Modal>

      {/* 電話番号の疎通確認 */}
      <Modal show={isShowingConfirmPhoneNumberModal}>
        <Wrap>
          <Text variant="h2" center gutterBottom>本人確認コードを入力</Text>
          <div data-private>
            <Text gutterBottom center>{profile.phoneNumber}に届いた<br/>4桁の確認コードをご入力ください</Text>
          </div>
          <Input type="number" errorMessages={errorMessages.code} onChange={(e) => {setPhoneNumberCode(e.target.value);}} value={phoneNumberCode} />
          <div className={styles.modalContentArea}>
            <Button onClick={() => {
              authConfirmationCode(() => {
                setPhoneNumberCode("");
                setShowingConfirmPhoneNumberModal(false);
              });
            }} fit primary>認証する</Button>
            <div className={styles.divider} />
            <Text gutterBottom center>4桁の確認コードが届かない場合は？</Text>
            <Button fit onClick={resendPassCode}>音声通話で確認する</Button>
            <Button onClick={() => {
              deleteTmpData('profile-phoneNumber');
              setShowingConfirmPhoneNumberModal(false);
            }} fit>中止する</Button>
          </div>
        </Wrap>
      </Modal>

      <Footer />
    </>
  );
}

export default Account;
