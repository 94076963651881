import PropTypes from "prop-types";
import React from "react";
import cx from "clsx";
import styles from "./SensorHumidityDeficitValue.module.sass";

function SensorHumidityDeficitValue({ value, status }) {
  return (
    <div className={styles.component}>
      <div className={styles.value}>
        <div className={styles.number}>{value ? Math.floor(value * 10) / 10 : "-"}</div>
        <div className={styles.unit}>g/㎥</div>
      </div>
      <div className={styles.statusWrap}>
        <div className={styles.statusLabels}>
          <div
            className={cx(styles.statusLabel, {
              [styles.alert]: status === "very-humid",
              [styles.warning]: status === "humid",
            })}
          >
            湿潤
          </div>
          <div
            className={cx(styles.statusLabel, {
              [styles.normal]: status === "normal",
            })}
          >
            最適
          </div>
          <div
            className={cx(styles.statusLabel, {
              [styles.alert]: status === "very-dry",
              [styles.warning]: status === "dry",
            })}
          >
            乾燥
          </div>
        </div>
        <div className={styles.statusBarWrap}>
          <div
            className={cx(styles.statusBar, {
              [styles.alert]: status === "very-humid" || status === "very-dry",
              [styles.warning]: status === "humid" || status === "dry",
            })}
            style={{ width: (value / 10) * 100 + "%" }}
          />
        </div>
        <div className={styles.statusBarScaleWrap}>
          <div />
          <div className={styles.statusBarScale} style={{ left: "32%" }}>
            3.0
          </div>
          <div />
          <div className={styles.statusBarScale} style={{ left: "65%" }}>
            7.0
          </div>
          <div />
        </div>
      </div>
    </div>
  );
}

SensorHumidityDeficitValue.propTypes = {
  value: PropTypes.number,
  status: PropTypes.string,
};

export default SensorHumidityDeficitValue;
