import cx from "clsx";
import { A } from "hookrouter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AlertBox from "../../partials/AlertBox";
import Box from "../../partials/Box";
import Button from "../../partials/Button";
import CheckoutSummary from "../../partials/CheckoutSummary";
import Field from "../../partials/Field";
import Footer from "../../partials/Footer";
import Input from "../../partials/Input";
import InvoicePaymentGuideModal from "../../partials/InvoicePaymentGuideModal";
import Modal from "../../partials/Modal";
import PageTitle from "../../partials/PageTitle";
import ProgressIndicator from "../../partials/ProgressIndicator";
import PurchaseHeader from "../../partials/PurchaseHeader";
import Select from "../../partials/Select";
import Stepper from "../../partials/Stepper";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import { CalcPrice } from "../../partials/calcPrice";
import { Telchan } from "../../Telchan";
import styles from "./Billing.module.sass";

function Billing(props) {
  const [isShowingCvvGuideModal, setShowingCvvGuideModal] = useState(false);
  const [
    isShowingInvoicePaymentGuideModal,
    setShowingInvoicePaymentGuideModal,
  ] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("credit"); //キャリアをデフォルトにする方法を考える FIXME
  const [carrierBillingType, setCarrierBillingType] = useState("au");
  const [errorMessages, setErrorMessages] = useState({
    cardNumber: [],
    cardCVV: [],
    cardMonth: [],
    cardYear: [],
  });
  const [cardNumber, setCardNumber] = useState(props.cardNumber || "");
  const [cardYear, setCardYear] = useState(props.cardYear || "");
  const [cardMonth, setCardMonth] = useState(props.cardMonth || "");
  const [cardCVV, setCardCVV] = useState(props.cardCVV || "");
  const [showCardError, setShowCardError] = useState(false);
  const [showRegisteredCard, setShowRegisteredCard] = useState(false);
  const [cardChecked, setCardChecked] = useState(false);

  useEffect(() => {
    fetchCardList();
  }, []);

  useEffect(() => {
    if (cardNumber) {
      props.updateProps("cardNumber", cardNumber);
    }
    if (cardYear) {
      props.updateProps("cardYear", cardYear);
    }
    if (cardMonth) {
      props.updateProps("cardMonth", cardMonth);
    }
    if (cardCVV) {
      props.updateProps("cardCVV", cardCVV);
    }
    if (carrierBillingType) {
      props.updateProps("carrierBillingType", carrierBillingType);
    }
    if (paymentMethod) {
      props.updateProps("paymentMethod", paymentMethod);
    }
  }, [
    cardNumber,
    cardYear,
    cardMonth,
    cardCVV,
    carrierBillingType,
    paymentMethod,
  ]);

  const fetchCarrierPayment = async () => {
    const session = await props.Auth.currentSession();
    const response = await props.API.get("Purchase", "/payments", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    if (response.length > 0) {
      setShowRegisteredCard(true);
      if (response[0].carrier == 'GMO掛け払い') {
        setPaymentMethod('GMO掛け払い');
        props.updateProps("carrierToken", response[0].paymentToken);
      } else {
        setCarrierBillingType(response[0].carrier);
        setPaymentMethod("carrier");
        props.updateProps("carrierToken", response[0].paymentToken);
        props.updateProps("carrierBillingType", response[0].carrier);
      }
    }
  };

  const fetchCardList = async () => {
    try {
      const session = await props.Auth.currentSession();
      await fetchCarrierPayment();
      const response = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          action: "list-cards",
        },
      });
      if (response[0]) {
        setShowRegisteredCard(true);
        setCardNumber(response[0].cardNo);
        setCardYear(response[0].expire);
        props.updateProps("cardSeq", response[0].cardSeq);
      }
      setCardChecked(true);
    } catch (e) {
      setCardChecked(true);
    }
  };

  const submit = async (e) => {
    setErrorMessages({});
    let errors = {
      cardNumber: [],
      cardCVV: [],
      cardMonth: [],
      cardYear: [],
    };

    if (paymentMethod === "credit") {
      if (props.cardSeq == null) {
        if (!cardNumber) {
          errors["cardNumber"].push("カード番号を入力して下さい");
        }
        if (cardYear == null) {
          errors["cardYear"].push("有効期限（年）を入力して下さい");
        }
        if (cardMonth == null) {
          errors["cardMonth"].push("有効期限（月）を入力して下さい");
        }
        if (cardCVV == null) {
          errors["cardCVV"].push("セキュリティコードを入力して下さい");
        }
      }
    }

    let hasError = false;
    for (let key in errors) {
      if (errors[key].length > 0) {
        hasError = true;
      }
    }

    if (hasError) {
      setErrorMessages(errors);
      setShowCardError(true);
      window.scrollTo(0, 0);
      return;
    } else {
      //navigate("/shop/review", false);
      props.setPage("review");
    }
  };

  return (
    <>
      <PurchaseHeader />
      <Wrap>
        <PageTitle title="お支払い方法を入力"></PageTitle>
        <Stepper steps={[1, 2, 3, 4]} current={3} />
        {!cardChecked && <ProgressIndicator gutter={true} />}
        {cardChecked && showCardError && (
          <AlertBox
            gutterBottom
            text="クレジットカード情報が正しくありません。カード番号と有効期限をご確認のうえ、入力しなおしてください。"
          />
        )}

        {/* 決済の情報がない場合（初回） */}
        {cardChecked && !showRegisteredCard && (
          <>
            <div
              onClick={() => setPaymentMethod("credit")}
              className={cx(
                styles.paymentMethod,
                paymentMethod === "credit" ? styles.active : null
              )}
            >
              <div className={styles.paymentSelectArea}>
                <div className={styles.paymentSelectAreaLeft}>
                  <div className={styles.paymentSelectButton}></div>
                </div>
                <div className={styles.paymentSelectAreaRight}>
                  <div className={styles.paymentMethodTitle}>
                    クレジットカード決済
                  </div>
                </div>
              </div>
              {paymentMethod === "credit" && (
                <div className={styles.paymentDetailArea}>
                  <Field label="カード番号">
                    <Input
                      value={cardNumber}
                      onChange={(e) => {
                        setCardNumber(e.target.value);
                      }}
                      type="number"
                      placeholder="半角数字のみ"
                      errormessage={errorMessages["cardNumber"]}
                    />
                  </Field>
                  <div className={styles.creditCardCompanyArea}>
                    <div className={styles.creditCardCompany}>
                      <img
                        src="/assets/images/icon-creditcard-visa.png"
                        height="22"
                        alt="VISA"
                      />
                    </div>
                    <div className={styles.creditCardCompany}>
                      <img
                        src="/assets/images/icon-creditcard-master.png"
                        height="22"
                        alt="Master"
                      />
                    </div>
                    <div className={styles.creditCardCompany}>
                      <img
                        src="/assets/images/icon-creditcard-jcb.png"
                        height="22"
                        alt="JCB"
                      />
                    </div>
                    <div className={styles.creditCardCompany}>
                      <img
                        src="/assets/images/icon-creditcard-american-express.png"
                        height="22"
                        alt="AmericanExpress"
                      />
                    </div>
                    <div className={styles.creditCardCompany}>
                      <img
                        src="/assets/images/icon-creditcard-diners-club.png"
                        height="22"
                        alt="DinersClub"
                      />
                    </div>
                  </div>
                  <Field label="有効期限">
                    <div className={styles.creditCardFieldWrap}>
                      <div className={styles.creditCardField}>
                        <Select
                          onChange={(e) => {
                            setCardMonth(e.target.value);
                          }}
                          value={cardMonth}
                          errormessage={errorMessages["cardMonth"]}
                        >
                          <option>----</option>
                          {[...Array(12).keys()].map((n) => {
                            const v = n + 1 < 10 ? `0${n + 1}` : n + 1;
                            return (
                              <option key={`m-${v}`} value={v}>
                                {v}
                              </option>
                            );
                          })}
                        </Select>
                        <div className={styles.creditCardUnit}>月</div>
                      </div>
                      <div className={styles.creditCardField}>
                        <Select
                          onChange={(e) => {
                            setCardYear(e.target.value);
                          }}
                          value={cardYear}
                          errormessage={errorMessages["cardYear"]}
                        >
                          <option>----</option>
                          {[...Array(10).keys()].map((n) => {
                            const year = moment()
                              .add(n, "years")
                              .format("YYYY");
                            return (
                              <option value={year} key={`y-${year}`}>
                                {year}
                              </option>
                            );
                          })}
                        </Select>
                        <div className={styles.creditCardUnit}>年</div>
                      </div>
                    </div>
                  </Field>
                  <Field label="セキュリティーコード（CVV）">
                    <Input
                      value={cardCVV}
                      onChange={(e) => {
                        setCardCVV(e.target.value);
                      }}
                      type="number"
                      placeholder="3桁もしくは4桁の番号"
                      errormessage={errorMessages["cardCVV"]}
                    />
                  </Field>
                  <span
                    className={styles.creditCvcArea}
                    onClick={() => setShowingCvvGuideModal(true)}
                  >
                    <img src="/assets/images/icon-cvc.png" width="17" />
                    <div className={styles.cvcText}>
                      セキュリティーコードとは？
                    </div>
                  </span>
                  <div className={styles.creditSaveText}>
                    お客様のクレジットカード情報はGMOペイメントゲートウェイ株式会社のシステムで管理します。
                  </div>
                </div>
              )}
            </div>
            <div
              onClick={() => setPaymentMethod("carrier")}
              className={cx(
                styles.paymentMethod,
                paymentMethod === "carrier" ? styles.active : null
              )}
            >
              <div className={styles.paymentSelectArea}>
                <div className={styles.paymentSelectAreaLeft}>
                  <div className={styles.paymentSelectButton}></div>
                </div>
                <div className={styles.paymentSelectAreaRight}>
                  <div className={styles.paymentMethodTitle}>キャリア決済</div>
                </div>
              </div>
              {paymentMethod === "carrier" && (
                <div className={styles.paymentDetailArea}>
                  <div className={styles.carrierBillingText}>
                    キャリアを選択してください
                  </div>
                  <Select
                    value={carrierBillingType}
                    onChange={(e) => setCarrierBillingType(e.target.value)}
                  >
                    <option value="au">au</option>
                    <option value="docomo">docomo</option>
                    <option value="softbank">SoftBank</option>
                  </Select>
                </div>
              )}
            </div>
            <>
              {/* GMO掛け払いを選択中のみ詳しい情報を表示する */}
              {paymentMethod === "GMO掛け払い" ? (
                <div
                  onClick={() => setPaymentMethod("GMO掛け払い")}
                  className={cx(
                    styles.paymentMethod,
                    paymentMethod === "GMO掛け払い" ? styles.active : null
                  )}
                >
                  <div className={styles.paymentSelectArea}>
                    <div className={styles.paymentSelectAreaLeft}>
                      <div className={styles.paymentSelectButton}></div>
                    </div>
                    <div className={styles.paymentSelectAreaRight}>
                      <div className={styles.paymentMethodTitle}>
                        <div>請求書払い</div>
                        <div className={styles.sub}>
                          銀行振込/コンビニ/スマホ決済
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.paymentDetailArea}>
                    <Text>
                      今月の利用料を翌月に請求書でお支払いできます。翌月に発送します。
                      <br />
                      請求書の発行費用として¥{CalcPrice.gmoChargeWithTax}かかります。
                      <br />
                      お支払い期限日までにお支払いください。
                      <div
                        className={styles.link}
                        onClick={() =>
                          setShowingInvoicePaymentGuideModal(true)
                        }
                      >
                        ご利用可能なお振込先の一覧
                      </div>
                    </Text>
                    <Text variant="ex">
                      お客様の個人情報はGMOペイメントサービス株式会社のシステムで管理します。
                    </Text>
                  </div>
                </div>
              ) : (
                <div className={styles.invoicePaymentDescription}>
                  <Text variant="note">
                    上記のお支払い方法が難しい場合、請求書払いを選択することもできます。※別途手数料が発生いたします。
                    <div
                      className={styles.link}
                      onClick={() => setPaymentMethod("GMO掛け払い")}
                    >
                      請求書払いを選択する
                    </div>
                  </Text>
                </div>
              )}
            </>
          </>
        )}
        {/* 決済の情報がある場合（2回目） */}
        {showRegisteredCard && (
          <>
            <div
              className={cx(
                styles.paymentMethod,
                paymentMethod === "credit" ? styles.active : null
              )}
            >
              {paymentMethod == "credit" && (
                <div className={styles.paymentMethodArea}>
                  <strong>クレジットカード</strong>
                  <div>{cardNumber}</div>
                  <div>有効期限 {cardYear}</div>
                </div>
              )}
              {paymentMethod == "carrier" && (
                <div className={styles.paymentDetailArea}>
                  <div className={styles.paymentMethodArea}>
                    <strong>キャリア決済</strong>
                    <div>{carrierBillingType}</div>
                  </div>
                </div>
              )}
              {paymentMethod == "GMO掛け払い" && (
                <div className={styles.paymentDetailArea}>
                  <div className={styles.paymentMethodArea}>
                    <strong>請求書払い</strong>
                    <div>料金を請求書でお支払いできます。</div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.paymentMethodDetailText}>
              決済方法は、月額利用料のお支払い方法として登録されています。
              <A href="/payment">お支払い方法設定</A>から変更してください。
            </div>
          </>
        )}
        <div className={styles.action}>
          <Button onClick={submit} primary fit>
            確認画面へすすむ
          </Button>
        </div>
        <div className={styles.backPageButton}>
          <span
            onClick={() => {
              props.setPage("shipping");
            }}
            className={styles.backPageButtonColor}
          >
            前の画面に戻る
          </span>
        </div>

        <Box basic>
          <CheckoutSummary
            title="初回のみのお支払い料金"
            items={[
              {
                name:
                  props.routerCount > 1
                    ? `親機ルーター端末代金 ×${props.routerCount}`
                    : "親機ルーター端末代金",
                price: props.routerPrice,
              },
              {
                name:
                  props.deviceCount > 1
                    ? `子機センサー端末代金 ×${props.deviceCount}`
                    : "子機センサー端末代金",
                price: props.devicePrice,
              },
              { name: "送料", price: props.shipmentFee },
            ]}
          />
          <div className={styles.divider} />
          <CheckoutSummary
            title="毎月のお支払い料金"
            items={[
              {
                name:
                  props.routerCount > 1
                    ? `親機ルーター月額利用料 ×${props.routerCount}`
                    : "親機ルーター月額利用料",
                price: props.routerMonthlyFee,
              },
              {
                name:
                  props.deviceCount > 1
                    ? `子機センサー月額利用料 ×${props.deviceCount}`
                    : "子機センサー月額利用料",
                price: props.deviceMonthlyFee,
              },
            ]}
          />
          <div className={styles.divider} />
          <div className={styles.checkoutNote}>
            ※上記以外に、監視料金、通知料金、確認料金がそれぞれかかります。詳しくは、
            <a
              href="https://support.tel-chan.com/hc/ja/articles/900004699186-%E8%B3%AA%E5%95%8F-%E3%81%A6%E3%82%8B%E3%81%A1%E3%82%83%E3%82%93%E3%81%AE%E6%96%99%E9%87%91%E4%BD%93%E7%B3%BB%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
              target="_blank"
            >
              料金体系
            </a>
            をご確認ください。
            <br />
            ※表示の料金は、すべて税込です。
          </div>
        </Box>
      </Wrap>
      <Footer openLinkInBlank={true} />

      {/* セキュリティコードの解説モーダル */}
      <Modal
        show={isShowingCvvGuideModal}
        type="Dialog"
        title="セキュリティコード（CVV）
      "
      >
        <div className={styles.securityCodeText}>
          カードの裏面もしくは前面にある
          <br />
          3桁または4桁の番号です。
        </div>
        <div className={styles.securityCodeImage}>
          <img
            src="/assets/images/icon-creditcard-back.png"
            width="88"
            className={styles.creditBackside}
          />
          <img src="/assets/images/icon-creditcard-front.png" width="88" />
        </div>
        <div>
          <Button fit onClick={() => setShowingCvvGuideModal(false)}>
            閉じる
          </Button>
        </div>
      </Modal>

      {/* 請求書払いの解説モーダル */}
      <InvoicePaymentGuideModal
        open={isShowingInvoicePaymentGuideModal}
        onClose={() => setShowingInvoicePaymentGuideModal(false)}
      />
    </>
  );
}

export default Billing;
