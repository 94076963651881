const md5 = require('md5');
const Config = {
  betacode: 'TERU3188',
  api_url_base: 'https://backend.prd.tel-chan.com',
  api_url_base2: 'https://api.prd.tel-chan.com',
  cancel_url: 'http://localhost:3000/signup?errorCode=2',
  slack_url: 'https://backend.prd.tel-chan.com/slack',
  shop_password: 'fs2dfymr',
  //shop_password: 'p8pqbwke',
  site_password: '8h497sxn',
  //site_password: '2bhv655m',
  //開発用（本番はp01）
  gmo_base_url: 'https://pt01.mul-pay.jp/link/',
  //gmo_base_url: 'https://p01.mul-pay.jp/link/',
  gmo_id: 'mst2000022357',
  //gmo_id: 'tsite00037067',
  gmo_shop_id: '9200001923976',
  //gmo_shop_id: 'tshop00042063',
  service_tel: '070-4521-7008',
  service_mail: 'tel-chan_support@kddi-web.com',
  service_url: 'https://tel-chan.com',
  gmo_shop_pass_string: (args) => {
    let data = [];
    data.push(args.shopId);
    data.push(args.orderId);
    data.push(args.amount);
    data.push('');
    data.push(args.password);
    data.push(args.date);
    return md5(data.join('|'));
  },
  gmo_member_id: (args) => {
    let data = [];
    data.push(args.shopId);
    data.push(args.memberId);
    data.push(args.password);
    data.push(args.date);
    return md5(data.join('|'));
  },
  serviceName: 'てるちゃん',
  serviceNameSubscribe: 'てるちゃん新規登録',
  docomoTimeout: 1200,
  //インボイス対応
  companyName: '株式会社ＫＤＤＩウェブコミュニケーションズ',
  companyNumber: 'T5010001065739',
  ostiariesMode: 'asp',
  ostiariesServiceId: 'KGIF7llaKMpXkDTf5WmgCLWy48rQwkQ8K0WDQIkG'
};
export{Config};
