import { navigate } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { Config } from "../../config";
import { Delimiter } from '../../delimiter';
import AuthorizedHeader from '../../partials/AuthorizedHeader';
import Button from '../../partials/Button';
import DefinitionList from '../../partials/DefinitionList';
import Footer from '../../partials/Footer';
import PageTitle from '../../partials/PageTitle';
import Text from '../../partials/Text';
import Wrap from '../../partials/Wrap';
import { CalcPrice } from '../../partials/calcPrice';
import { CheckUser } from '../../partials/checkUser';
import styles from './Detail.module.sass';
export const useDidMount = (func) => useEffect(() => { func() }, []);

function PaymentDetail(props) {
  const [user, setUser] = useState({});
  const [cardNumber, setCardNumber] = useState(props.cardNumber || "");
  const [cardYear, setCardYear] = useState(props.cardYear || "");
  const [carrierList, setCarrierList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const onload = async () => {
    let newUser = await CheckUser(props.Auth);
    if (newUser) {
      setUser(newUser);
      /* 意味のないAPI呼び出しなので削除
      const session = await props.Auth.currentSession();
      const response = await props.API.post('Purchase', '/billing', {
        headers: {Authorization: session.idToken.jwtToken}, body: {
        action: 'list-cards'
      }});
      if (response && response[0]) {
        setCardNumber(response[0].cardNo);
        setCardYear(response[0].expire);
      }
      const carrierResponse = await props.API.post('Purchase', '/billing', {
        headers: {Authorization: session.idToken.jwtToken}, body: {
        action: 'list-carriers'
      }});
      if (carrierResponse.length > 0) {
        if (carrierResponse[0].carrier == 'GMO掛け払い') {
          setPaymentMethod(carrierResponse[0].carrier);  
        } else if (carrierResponse[0].carrier == 'credit') {
          setPaymentMethod(carrierResponse[0].carrier);  
        } else {
          setPaymentMethod('carrier');  
          setCarrierList(carrierResponse.map((c) => {if (c.carrier != 'credit') {return c;}}).filter((n) => {return n;}));
        }
      }
      */
    } else {
      navigate('/', false);
    }
  };

  const List = ({title, description, fee}) => {
    return(
      <div className={styles.paymentDetailList}>
        <div className={styles.detail}>
        {title}<br/>
        {description}
        </div>
        <div className={styles.price}>
          {fee}
        </div>
      </div>
    )
  }

  useDidMount(async () => {
    onload();
  });

  if (!user.attributes) {
    return (<div />);
  }
  const mode = props.history.amount >= 0 ? "請求" : "返金";
  return (
    <>
      <AuthorizedHeader current="menu" user={user} simple />
      <Wrap>
        <PageTitle title={`ご${mode}明細`} />
        <Text variant="h4">{mode}確定日：{props.history.fixed_date}</Text>
        {props.history.used_date &&
          <Text variant="h4">{props.history.used_date}分ご利用金額</Text>
        }
        {props.history.summary &&
          <Text variant="h4" className={styles.paymentSummary}>{props.history.summary}</Text>
        }
        <div className={styles.paymentAmount}>
          ¥{Delimiter(props.history.amount + (props.history.gmoFee ?? 0))}
        </div>
        <div className={styles.paymentTaxInclude}>
          内消費税{CalcPrice.showTaxRate()}
          <span className={styles.paymentTaxIncludeDetail}>¥{Delimiter(CalcPrice.taxFromTaxIncluded(props.history.amount + (props.history.gmoFee ?? 0)))}</span>
        </div>
        {(mode === '請求') &&
        <div className={styles.paymentMethod}>
          {props.history.paymentMethod === 'credit' &&
            <DefinitionList title="お支払い方法" gutterBottom>クレジットカード {cardNumber}</DefinitionList>
          }
          {props.history.paymentMethod === 'carrier' &&
            <DefinitionList title="お支払い方法" gutterBottom>キャリア決済 </DefinitionList>
          }
          {props.history.paymentMethod === 'GMO掛け払い' &&
            <DefinitionList title="お支払い方法" gutterBottom>GMO掛け払い</DefinitionList>
          }
        </div>
        }
        <Text variant="h4" className={styles.paymentInformation}>ご{mode}先<br />{user.attributes.name} 様</Text>
        <Text variant="h4" className={styles.paymentInformation}>ご{mode}元<br />{Config.companyName}<br />登録番号 {Config.companyNumber}</Text>

{(props.history.feeData) &&
        <div className={styles.paymentDetailLists}>
          <div className={styles.paymentDetailListHeader}>
            システム利用料
          </div>
          <List 
            title={`親機ルーター利用料`}
            description={`¥${Delimiter(CalcPrice.routerDataFee)} × ${props.history.feeData.current_active_routers.length}台`}
            fee={`¥${Delimiter(props.history.feeData.current_active_router_fee)}`}
          />
          <List 
            title={`子機センサー利用料`}
            description={`¥${Delimiter(CalcPrice.deviceDataFee)} × ${props.history.feeData.current_active_devices.length}台`}
            fee={`¥${Delimiter(props.history.feeData.current_active_device_fee)}`}
          />
          <List 
            title={`親機ルーター（停止）利用料`}
            description={`¥${Delimiter(CalcPrice.routerInactiveFee)} × ${props.history.feeData.current_inactive_routers.length}台`}
            fee={`¥${Delimiter(props.history.feeData.current_inactive_router_fee)}`}
          />
          <List 
            title={`子機センサー（停止）利用料`}
            description={`¥0 × ${props.history.feeData.current_inactive_devices.length}台`}
            fee={`¥${Delimiter(props.history.feeData.current_inactive_device_fee)}`}
          />
          {props.history.gmoFee ? (
          <List 
            title={`手数料`}
            description=""
            fee={`¥${Delimiter(props.history.gmoFee)}`}
          />
          ) : ""}
          <div className={styles.paymentDetailListHeader}>
            監視料金
          </div>
          <List 
            title={`監視 ¥${CalcPrice.uplinkFee} x ${props.history.feeData.total_uplink_count || 0}回`}
            fee={`¥${Delimiter(props.history.feeData.setting_fee)}`}
          />
          <div className={styles.paymentDetailListHeader}>
            通知料金
          </div>
          <List 
            title={`電話通知料`}
            description={`¥${Delimiter(CalcPrice.phoneFee)} × ${props.history.feeData.phone_count}回`}
            fee={`¥${Delimiter(props.history.feeData.phone_fee)}`}
          />
          <List 
            title={`SMS通知料`}
            description={`¥${Delimiter(CalcPrice.smsFee)} × ${props.history.feeData.sms_count}回`}
            fee={`¥${Delimiter(props.history.feeData.sms_fee)}`}
          />
          <div className={styles.paymentDetailListHeader}>
            確認料金
          </div>
          <List 
            title={`電話での確認`}
            description=""
            fee={`¥${Delimiter(props.history.feeData.incoming_phone_fee) || 0}`}
          />
        </div>
}
{false &&
        <Text variant="h4" gutterBottom>注意事項</Text>
}
{false &&
        <Text>ここに注意事項を表示しますここに注意事項を表示しますここに注意事項を表示しますここに注意事項を表示しますここに注意事項を表示しますここに注意事項を表示しますここに注意事項を表示します</Text>
}
      </Wrap>
      <Wrap>
        <Button onClick={() => {
          props.setHistoryData(null);
          window.scrollTo(0, 0);
        }} fit>一覧へ戻る</Button>
      </Wrap> 
      <Footer />
    </>
  );
}

export default PaymentDetail;
