import {
  mdiEmail,
  mdiFireAlert,
  mdiMessageProcessing,
  mdiPhone,
  mdiRefresh,
  mdiArrowULeftBottom,
} from "@mdi/js";
import Icon from "@mdi/react";
import cx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Backdrop } from "../../partials/backdrop";
import DeviceIcon from "../../partials/DeviceIcon";
import Modal from "../../partials/Modal";
import SensorNotUpdatingModal from "../../partials/SensorNotUpdatingModal";
import SensorValues from "../../partials/SensorValues";
import UpdateDevicesNetworkStatus from "../../partials/UpdateDevicesNetworkStatus";
import NotificationSettingsDetail from "../NotificationSettings/NotificationSettingsDetail";
import styles from "./sensorArea.module.sass";
import SensorSettings from "./sensorSettings";

export const useDidMount = (func) =>
  useEffect(() => {
    func();
  }, []);

let reqStatus = {};

const SensorArea = ({ device, ...props }) => {
  reqStatus[device.device_id] = "default";
  const [isShowingSensorSettingModal, setIsShowingSensorSettingModal] =
    useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [progress] = useState(10);
  const [checked, setChecked] = useState(device.active);
  const [alertSetting, setAlertSetting] = useState(null);
  const [
    isShowingNotificationDetailModal,
    setIsShowingNotificationDetailModal,
  ] = useState(false);
  const [isShowingSensorNotUpdatingModal, setShowingSensorNotUpdatingModal] =
    useState(false);
  const [currentSettings, setCurrentSettings] = useState(null);
  const [requestManualUplinkStatus, setRequestManualUplinkStatus] =
    useState("default"); // default | inProgress | failure

  useEffect(() => {
    if (currentSettings == null) {
      setIsShowingNotificationDetailModal(false);
    } else {
      setIsShowingNotificationDetailModal(true);
    }
  }, [currentSettings]);

  useEffect(() => {
    if (alertSetting && checked != null) {
      let newAlertSetting = alertSetting;
      newAlertSetting.active = checked;
      setAlertSetting(newAlertSetting);
    }
  }, [checked]);

  useEffect(() => {
    if (device) {
      if (device.failure) {
        setRequestManualUplinkStatus("failure");
      }
      if (device.uplink_status) {
        switch (device.uplink_status) {
          case "in-progress":
            if (moment(device.uplink_requested_at).isAfter(moment().add(-10, "minutes"))) {
              setRequestManualUplinkStatus("inProgress");
            } else {
              setRequestManualUplinkStatus("nonArrival");
            }
            break;
          case "error":
            setRequestManualUplinkStatus("failure");
            break;
          case "processed":
            setRequestManualUplinkStatus("default");
            break;
        }
      }
    }
  }, [device]);

  useEffect(() => {
    let current_setting = {},
      my_setting;

    if (!current_setting.alert_to_id) {
      current_setting.alert_to_id = props.alertTo.alert_to_id;
      current_setting.setting_id = device.device_id;
      current_setting.user_id = device.user_id;
    }

    switch (props.type) {
      case "temperature":
        my_setting = {
          lowest: current_setting.temperature_lowest,
          highest: current_setting.temperature_highest,
          frequency: current_setting.temperature_frequency,
          from: current_setting.temperature_from,
          to: current_setting.temperature_to,
        };
        break;
      case "moisture":
        my_setting = {
          lowest: current_setting.moisture_lowest,
          highest: current_setting.moisture_highest,
          frequency: current_setting.moisture_frequency,
          from: current_setting.moisture_from,
          to: current_setting.moisture_to,
        };
        break;
      case "illuminance":
        my_setting = {
          lowest: current_setting.illuminance_lowest,
          highest: current_setting.illuminance_highest,
          frequency: current_setting.illuminance_frequency,
          from: current_setting.illuminance_from,
          to: current_setting.illuminance_to,
        };
        break;
    }
    my_setting.router_id = device.router_id;
    my_setting.alert_to_id = current_setting.alert_to_id;
    my_setting.setting_id = current_setting.setting_id;
    my_setting.active = device.active;
    setChecked(device.active);
    setAlertSetting(my_setting);

    const newStatus = getStatus();

    props.EE.addListener("openDeviceSettingModal", openDeviceSettingModal);
    props.EE.addListener("startRequestUplink", startRequestUplink);
    props.EE.addListener("cancelRequestUplink", cancelRequestUplink);
    props.EE.addListener("timeoutRequestUplink", timeoutRequestUplink);
    props.EE.addListener("deviceListUpdated", deviceListUpdated);
    return () => {
      props.EE.removeListener("openDeviceSettingModal", openDeviceSettingModal);
      props.EE.removeListener("startRequestUplink", startRequestUplink);
      props.EE.removeListener("cancelRequestUplink", cancelRequestUplink);
      props.EE.removeListener("timeoutRequestUplink", timeoutRequestUplink);
      props.EE.removeListener("deviceListUpdated", deviceListUpdated);
    };
  }, []);

  useEffect(() => {
    reqStatus[device.device_id] = requestManualUplinkStatus;
  }, [requestManualUplinkStatus]);

  const deviceListUpdated = (args) => {
    if (args && device?.device_id == args.device_id) {
      setRequestManualUplinkStatus("default");
    }
  };

  const startRequestUplink = (args) => {
    if (args.devices.indexOf(device.device_id) >= 0) {
      setRequestManualUplinkStatus("inProgress");
    }
  };

  const cancelRequestUplink = (args) => {
    if (args.devices.indexOf(device.device_id) >= 0) {
      setRequestManualUplinkStatus("default");
    }
  };

  const timeoutRequestUplink = (args) => {
    if (args.devices[device.device_id] == undefined) {
      setRequestManualUplinkStatus("failure");
    } else {
      setRequestManualUplinkStatus("default");
    }
  };

  const getStatus = () => {
    //デバイスのステータスを設定
    if (!device) {
      return "Unknown";
    }
    if (device.suspended === true) {
      return "Inactive";
    } else if (Object.keys(device.value).length <= 0) {
      return "Warning";
    } else {
      return "Operating";
    }
  };

  const openDeviceSettingModal = (args) => {
    if (args.device.device_id == device.device_id) {
      setIsShowingSensorSettingModal(true);
    }
  };

  const closeBackdrop = async () => {
    setShowBackdrop(false);
  };

  if (!alertSetting) {
    return <div />;
  }

  const formatDate = (date) => {
    return "";
    if (date == "") {
      return "";
    }

    const the_date = moment(date).format("MM/DD");
    const today = moment().format("MM/DD");
    if (the_date == today) {
      return "今日";
    }
    return the_date;
  };

  const created_at = device.sensorValue ? device.sensorValue.date : "";
  const updated_at = created_at == "" ? "" : moment(created_at).format("HH:mm");

  if (!device) {
    return <div />;
  }

  const sensorType = () => {
    const type = device.sensor_type;
    const settings =
      device.alertSettings && device.alertSettings[0]
        ? device.alertSettings[0]
        : {};
    return {
      active: device.active,
      temperature: device.value != null ? device.value.temperature : null,
      temperatureLowest:
        settings.temperature_lowest != null
          ? settings.temperature_lowest
          : null,
      temperatureHighest:
        settings.temperature_highest != null
          ? settings.temperature_highest
          : null,
      moisture: device.value != null ? device.value.moisture : null,
      moistureLowest:
        settings.moisture_lowest != null ? settings.moisture_lowest : null,
      moistureHighest:
        settings.moisture_highest != null ? settings.moisture_highest : null,
      illuminance: device.value != null ? device.value.illuminance : null,
      illuminanceLowest:
        settings.illuminance_lowest != null
          ? settings.illuminance_lowest
          : null,
      illuminanceHighest:
        settings.illuminance_highest != null
          ? settings.illuminance_highest
          : null,
      waterlevel: device.value != null ? device.value.watermark : null,
      waterlevelLowest:
        settings.waterlevel_lowest != null ? settings.waterlevel_lowest : null,
      waterlevelHighest:
        settings.waterlevel_highest != null
          ? settings.waterlevel_highest
          : null,
      acceleration: device.value ? device.value.acceleration : null,
      disabledTemperature: type.match(/t/) ? false : true,
      disabledMoisture: type.match(/m/) ? false : true,
      disabledIlluminance: type.match(/i/) ? false : true,
      disabledWaterlevel: type.match(/w/) || type === "ELTRES" ? false : true,
      disabledAcceleration: type.match(/a/) ? false : true,
      deviceUnavailable: device.sensorValue == null,
      battery:
        device.value && device.value.battery != null
          ? device.value.battery
          : device.sensorValue != null &&
            device.sensorValue.device != null &&
            device.sensorValue.device.battery != null
          ? device.sensorValue.device.battery
          : null,
      rssi: device.sensorValue?.device_rssi
        ? device.sensorValue.device_rssi
        : null,
    };
  };

  const openSensorSettingModal = (e) => {
    props.EE.emit("stopreload");
    document.body.classList.add("showing-modal");
    setIsShowingSensorSettingModal(true);
  };

  const closeSensorSettingModal = (e) => {
    props.EE.emit("startreload");
    e.preventDefault();
    e.stopPropagation();
    document.body.classList.remove("showing-modal");
    setIsShowingSensorSettingModal(false);
  };

  const timestampToString = (timestamp) => {
    if (!timestamp) {
      return "";
    }
    const base = moment(timestamp);
    //1時間以上前...1日以上前...7日以上前
    if (moment().diff(base, 'days') >= 1) {
      return `${parseInt(moment().diff(base, 'days'))}日以上前`;// + moment(timestamp).format("YYYY年MM月DD日 HH:mm");
    } else if (moment().diff(base, 'hours') >= 1) {
      return `${moment().diff(base, 'hours')}時間以上前`;// + moment(timestamp).format("YYYY年MM月DD日 HH:mm");
    } else {
      let diff = String(moment().diff(base, 'minutes'));
      if (diff.length > 1) {
        diff = `${diff[0]}0`;
      }
      if (parseInt(diff) < 10) {
        return '10分以内';
      }
      return `${diff}分以上前`;
    }
  };

  const RelatedNotificationSettings = (props) => {
    const settings = props.setting;
    let monitoringConditionSetting;
    if (
      settings.settings.conditions &&
      settings.settings.conditions.length <= 1
    ) {
      monitoringConditionSetting = "";
    } else if (settings.settings.conditions) {
      const settingCondition =
        settings.settings && settings.settings.conditionSetting
          ? settings.settings.conditionSetting
          : "";
      monitoringConditionSetting =
        settingCondition == "all" ? "すべての条件 " : "いずれかの条件 ";
    }
    const targets = settings.settings.targets || {};
    const waterlevelLabel = {
      0: "水位なし",
      1: "低水位",
      2: "中水位",
      3: "高水位",
    };

    let conditions;
    if (settings.settings.conditions) {
      conditions = settings.settings.conditions;
    } else {
      conditions = [
        {
          sensor_type: settings.settings.sensor_type,
          sensor_values: settings.settings.sensor_value,
          operator: settings.settings.operator,
        },
      ];
    }

    return (
      <div
        className={cx(
          styles.monitoringSettingWrap,
          props.setting.alert ? styles.alert : null
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCurrentSettings(settings);
          return false;
        }}
      >
        <div className={styles.settingValueWrap}>
          {targets.email.status && (
            <div className={styles.iconWrap}>
              <Icon size={0.9} path={mdiEmail} />
            </div>
          )}
          {targets.sms.status && (
            <div className={styles.iconWrap}>
              <Icon size={0.9} path={mdiMessageProcessing} />
            </div>
          )}
          {targets.phone.status && (
            <div className={styles.iconWrap}>
              <Icon size={0.9} path={mdiPhone} />
            </div>
          )}
        </div>
        <div className={styles.settingValues}>
          {conditions.map((cond, idx) => {
            let value, suffix, operator;
            operator = cond.operator == "more" ? "以上" : "以下";
            value = cond.sensor_value;
            switch (cond.sensor_type) {
              case "temperature":
                suffix = "℃";
                break;
              case "moisture":
              case "humidity":
                suffix = "%";
                break;
              case "illuminance":
                suffix = "lx";
                break;
              case "waterlevel":
              case "watermark":
                suffix = "";
                value = waterlevelLabel[cond.sensor_value];
                break;
            }
            return (
              <span className={styles.settingValue} key={`cond-${idx}`}>
                {`${cond.sensor_value}${suffix}${operator}`}
              </span>
            );
          })}
          <span className={styles.settingValue}>
            {(settings.monitoringMethod == null ||
              settings.monitoringMethod == "range") && (
              <>
                {monitoringConditionSetting}
                {settings.start_at}〜{settings.end_at}{" "}
                {settings.settings.alert_interval}分ごと
              </>
            )}
            {settings.monitoringMethod != null &&
              settings.monitoringMethod == "fixed_times" && (
                <>
                  {monitoringConditionSetting}
                  {settings.monitoringTimes.join(", ")}
                </>
              )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={cx(styles.component, {
          [styles.inactive]: device.suspended,
        })}
        onClick={openSensorSettingModal}
      >
        {/* 停止中の機器は名前をdevice_idにする。現在はバックエンド側で名前を変更していますが、初期ユーザーはその処理が適用されていないため分岐しています。 */}
        <div className={styles.deviceNameWrap}>
          {!device.suspended ? (
            device.name
          ) : (
            <div className={styles.deviceName}>
              <DeviceIcon type="sensor" size={40} inactive={device.suspended} />
              <div className={styles.deviceNameDetail}>{device.device_id}</div>
            </div>
          )}
        </div>
        {/* センサーの値 */}
        {!device.suspended && (
          <>
            <div className={styles.deviceDetailWrap}>
              <div className={styles.deviceDetail}>
                <div className={styles.timeWrap}>
                  {/* TODO:センサーの値が古い場合の条件分岐の作成をお願いします */}
                  {true ? (
                    <div className={styles.time}>
                      {`${formatDate(created_at)}`}
                      <div className={styles.detailTime}>{`${timestampToString(
                        device.sensorValue ? device.sensorValue.date : ""
                      )}`}</div>
                    </div>
                  ) : (
                    <>
                      <div className={cx(styles.time, styles.notUpToDate)}>
                        {`${formatDate(created_at)}`}
                        <div
                          className={styles.detailTime}
                        >{`${updated_at}`}</div>
                      </div>
                    </>
                  )}
                </div>
                {/* 他のセンサーが更新中の場合に「setShowingSensorNotUpdatingModal」を表示してください*/}
                {device.sensor_type !== 'ELTRES' && (
                  <div
                    className={styles.updateButton}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (props.updateInProgress) {
                        setShowingSensorNotUpdatingModal(true);
                        return;
                      }
                      props.requestUplink({devices: [device.device_id]})
                    }}
                  >
                    <Icon size={1.1} path={mdiRefresh} />
                    <div className={styles.updateButtonText}>更新</div>
                  </div>
                )}
              </div>
              {/* TODO:センサーの値が古い場合に「textColor="#EDC804"」を追加してください */}
              <SensorValues {...sensorType()} humidityDeficitChecked={props.humidityDeficitChecked} dewPointChecked={props.dewPointChecked} />

              {/* 更新中の時 */}
              {requestManualUplinkStatus === "inProgress" && (
                <div className={styles.deviceStatusWrap}>
                  <div className={styles.deviceStatusText}>
                    <div>更新中です...</div>
                    <div className={styles.deviceStatusTextDetail}>
                      10分ほどかかる可能性があります
                    </div>
                    <UpdateDevicesNetworkStatus />
                  </div>
                </div>
              )}

              {/* 更新を失敗した時 */}
              {requestManualUplinkStatus === "failure" && (
                <div className={cx(styles.deviceStatusWrap, styles.alert)}>
                  <div className={styles.deviceStatusIcon}>
                    <img src="/assets/images/icon-alert-large.svg" alt="注意" />
                  </div>
                  <div className={styles.deviceStatusText}>
                    <div>更新に失敗しました</div>
                    <div className={styles.deviceStatusTextDetail}>
                      電波環境および電池残量を確認して改めてお試しください。
                    </div>
                  </div>
                </div>
              )}

              {/* データが取得できない時 */}
              {/* 更新処理は正常に完了しているのにデータが取得できなかった */}
              {requestManualUplinkStatus === "nonArrival" && (
                <div className={cx(styles.deviceStatusWrap, styles.alert)}>
                  <div className={styles.deviceStatusIcon}>
                    <img
                      src="/assets/images/icon-alert-large.svg"
                      alt="注意"
                    />
                  </div>
                  <div className={styles.deviceStatusText}>
                    <div>データが取得できません</div>
                    <div className={styles.deviceStatusTextDetail}>
                      電波環境および電池残量を確認して改めてお試しください。
                    </div>
                    <div
                      className={styles.backButton}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.cancelUplink({devices: [device.device_id]})
                      }}
                    >
                      <Icon size={1.1} path={mdiArrowULeftBottom} />
                      <div className={styles.backButtonText}>戻る</div>
                    </div>
                  </div>
                </div>
              )}

              {/* データが取得できない時 */}
              {/* 更新処理は正常に完了しているのにデータが取得できなかった */}
              {requestManualUplinkStatus === "default" &&
                Object.keys(device.value).length <= 0 && (
                <div className={cx(styles.deviceStatusWrap, styles.alert)}>
                  <div className={styles.deviceStatusIcon}>
                    <img
                      src="/assets/images/icon-alert-large.svg"
                      alt="注意"
                    />
                  </div>
                  <div className={styles.deviceStatusText}>
                    <div>データが取得できません</div>
                  </div>
                </div>
              )}
            </div>
            {/* 通知設定 */}
            <>
              {/* 関連する監視設定（複数表示されることがあります） */}
              {device.settings
                .sort((a, b) => {
                  if (a.notification_status > b.notification_status) {
                    return -1;
                  }
                  if (a.notification_status < b.notification_status) {
                    return 1;
                  }
                  if (a.notification_type > b.notification_type) {
                    return -1;
                  }
                  if (a.notification_type < b.notification_type) {
                    return 1;
                  }
                  if (a.start_at > b.start_at) {
                    return 1;
                  }
                  if (a.start_at < b.start_at) {
                    return -1;
                  }
                })
                .map((setting, idx) => {
                  if (parseInt(setting.notification_status) === 1) {
                    return (
                      <RelatedNotificationSettings
                        key={`rel-${idx}`}
                        setting={setting}
                        {...props}
                      />
                    );
                  }
                })}
            </>
            <div>
              {sensorType().temperature != null &&
                parseInt(sensorType().temperature) >= 60 && (
                  <div className={styles.alertWrap}>
                    <div className={styles.alert}>
                      <Icon size={0.9} path={mdiFireAlert} />
                      <div className={styles.alertTitle}>高温注意</div>
                    </div>
                    <div className={styles.alertText}>
                      温度が60℃を超えると、故障する可能性があります。センサーの取り付け位置を変更してください。
                    </div>
                  </div>
                )}
            </div>
          </>
        )}
      </div>

      {showBackdrop && (
        <Backdrop
          show={showBackdrop}
          title="保存しています"
          label={"値をチェックしています"}
          progress={progress}
          onClose={closeBackdrop}
        />
      )}

      <Modal
        show={isShowingSensorSettingModal}
        title={device.name}
        onClose={() => {}}
      >
        <SensorSettings
          {...props}
          routers={props.routers}
          device={device}
          sensorValues={sensorType()}
          closeSensorSettingModal={closeSensorSettingModal}
          dataNotAvailable={Object.keys(device.value).length <= 0}
        />
      </Modal>
      <Modal
        show={isShowingNotificationDetailModal}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      >
        <NotificationSettingsDetail
          closeNotificationDetailModal={async () => {
            setCurrentSettings(null);
            props.onChange();
          }}
          settings={currentSettings}
          {...props}
          settingsList={[]}
        />
      </Modal>

      {/* 他のセンサーが更新中の場合に表示するモーダル */}
      <SensorNotUpdatingModal
        open={isShowingSensorNotUpdatingModal}
        onClose={() => setShowingSensorNotUpdatingModal(false)}
      />
    </>
  );
};

SensorArea.propTypes = {
  device: PropTypes.object,
};

export default SensorArea;
