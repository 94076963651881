import cx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import styles from "./ButtonClose.module.sass";

const ButtonClose = ({ onClick, ...props }) => {
  return (
    <button {...props} onClick={onClick} className={cx(styles.wrap)}>
      <div className={styles.button}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.76909 22.7691L9.23063 25.2306L16 18.4613L22.7694 25.2307L25.2309 22.7691L18.4615 15.9997L25.2306 9.23064L22.7691 6.7691L16 13.5382L9.23089 6.76911L6.76935 9.23065L13.5385 15.9997L6.76909 22.7691Z"
            fill="black"
            className={styles.icon}
          />
        </svg>
      </div>
    </button>
  );
};

ButtonClose.propTypes = {
  onClick: PropTypes.func,
};

ButtonClose.defaultProps = {
  onClick: () => null,
};

export default ButtonClose;
