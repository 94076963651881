import cx from "clsx";
import React, { useEffect, useState } from "react";
import { Backdrop } from "../../partials/backdrop";
import { CheckUser } from "../../partials/checkUser";
import Modal from "../../partials/Modal";
import ProgressIndicator from "../../partials/ProgressIndicator";
import styles from "./routerArea.module.sass";
import RouterSettings from "./routerSettings";

export const useDidMount = (func) =>
  useEffect(() => {
    func();
  }, []);
function RouterArea(props) {
  const [rssi, setRssi] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [label, setLabel] = useState("設定を保存しています");
  const [progress, setProgress] = useState(10);
  const [routerEditable, setRouterEditable] = useState(false);
  //const [router, setRouter] = useState(null);
  const [routerActive, setRouterActive] = useState(true);
  const [routerName, setRouterName] = useState("");
  const [editable, setEditable] = useState(false);
  const [isShowingRouterSettingModal, setIsShowingRouterSettingModal] =
    useState(false);
  const [isShowingSensorSettingModal, setIsShowingSensorSettingModal] =
    useState(null);
  const [deviceInitialized, setDeviceInitialized] = useState(false);
  const [routerStatus, setRouterStaus] = useState("Unknown");

  useDidMount(async () => {
    ///setRouter(props.router);
    if (props.router.rssi) {
      setRssi(props.router.rssi);
    }
    setRouterActive(props.router.active);
    setRouterName(props.router.name);
    let devices = {};
    if (props.router.devices) {
      props.router.devices.map((d) => {
        devices[d.device_id] = false;
      });
    }
    setIsShowingSensorSettingModal(devices);
  });

  useEffect(() => {
    if (isShowingSensorSettingModal) {
      setDeviceInitialized(true);
    }
    //デバイスのステータスを設定
    if (props.router.active === true) setRouterStaus("Active");
    else setRouterStaus("Inactive");
  }, [isShowingSensorSettingModal]);

  const toggleRouterSetting = (e) => {
    if (e.target.id != "routerName") {
      if (routerEditable) {
        setEditable(false);
      }
      // FIXME ルータの編集エリアを隠す
      //setRouterEditable(!routerEditable)
      setRouterEditable(false);
    }
  };

  const saveSetting = async () => {
    let errors = [];
    setShowBackdrop(true);
    if (!routerName) {
      //errors.push('親機の名前を入力してください');
    } else if (routerName.length > 20) {
      errors.push("親機の名前が長すぎます");
    }
    if (errors.length > 0) {
      setErrorMessages(errors);
      setShowBackdrop(false);
      return;
    } else {
      setErrorMessages([]);
    }
    let user = await CheckUser(props.Auth);
    if (user) {
      setProgress(40);
      const session = await props.Auth.currentSession();
      setProgress(60);
      const setting = {
        name: routerName || props.router.router_id,
        active: routerActive,
        user_id: user.attributes.sub,
        router_id: props.router.router_id,
      };
      try {
        const response = await props.API.post(
          "UpdateRouter",
          "/update_router",
          {
            headers: { Authorization: session.idToken.jwtToken },
            body: setting,
          }
        );
        setProgress(80);
        setRouterEditable(false);
        await props.reload();
        setProgress(90);
        setProgress(100);
        setEditable(false);
        //if (props.router.active != setting.active) {
        window.location.reload();
        //} else {
        //  setShowBackdrop(false);
        //}
      } catch (e) {}
    }
  };

  const openRouterSettingModal = () => {
    props.EE.emit("stopreload");
    document.body.classList.add("showing-modal");
    setIsShowingRouterSettingModal(true);
  };

  const closeRouterSettingModal = (reload = false) => {
    if (reload) {
      props.EE.emit("startreload");
      window.location.reload();
    } else {
      document.body.classList.remove("showing-modal");
      setIsShowingRouterSettingModal(false);
    }
  };

  if (!props.router) {
    return <div></div>;
  }

  //  if (props.router.devices.length <= 0) { return <div />; }

  const closeSensorSettingModal = (device) => {
    let showModal = isShowingSensorSettingModal;
    showModal[device.device_id] = false;
    setIsShowingSensorSettingModal(showModal);
  };

  //  const openSensorSettingModal = (device) => {
  //    let showModal = isShowingSensorSettingModal;
  //    showModal[device.device_id] = true;
  //console.log('open showmodal', showModal);
  //    setIsShowingSensorSettingModal(showModal);
  //  };

  let areaClass = routerEditable
    ? "Router-area Router-area-selected"
    : "Router-area";
  let deviceCount = 0;
  if (props.router.devices) {
    props.router.devices.map((d) => {
      if (!d.suspended) {
        deviceCount++;
      }
    });
  }
  return (
    <>
      {showBackdrop && (
        <Backdrop
          show={showBackdrop}
          title="保存しています"
          label={label}
          progress={progress}
          onClose={() => {
            showBackdrop(false);
          }}
        />
      )}
      <div
        className={cx(
          styles.routerBody,
          routerStatus === "Active" ? null : styles.inactive
        )}
        onClick={openRouterSettingModal}
      >
        <div className={styles.routerIconWrap}>
          {/* 停止中の機器は名前をrouter_idにする。現在はバックエンド側で名前を変更していますが、初期ユーザーはその処理が適用されていないため分岐しています。 */}
          <div className={styles.routerName}>
            {routerStatus === "Active"
              ? props.router.name
              : props.router.router_id}
          </div>
          <div className={styles.routerIcon}>
            <img src="/assets/images/icon-router-blue.png" alt="親機ルーター" />
          </div>
        </div>
        {routerStatus === "Active" && (
          <div className={styles.routerStatusWrap}>
            {false && (
              <div className={styles.routerStatusUpdateTime}>
                {/* TODO:更新日時の実装お願いします */}
                {"XX時間以上前"}
              </div>
            )}
            <div>
              {/* 死活の「確認中」の場合 */}
              {!props.statusChecked && (
                <div className={styles.routerStatusUpdatingWrap}>
                  <ProgressIndicator type={"xxSmall"} color={"#fff"} />
                  <div className={styles.routerStatusUpdatingText}>
                    更新中です...
                  </div>
                </div>
              )}
              {/* 死活の結果が「正常」の場合 */}
              {props.statusChecked && props.router.isAlive && (
                <div className={styles.routerStatus}>
                  <div className={styles.routerStatusIcon} />
                  <div className={styles.routerStatusText}>正常</div>
                </div>
              )}
              {/* 死活の結果が「利用不可」の場合 */}
              {props.statusChecked && props.router.isAlive === false && (
                <div className={cx(styles.routerStatus, styles.error)}>
                  <div className={styles.routerStatusIcon} />
                  <div className={styles.routerStatusText}>利用不可</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        show={isShowingRouterSettingModal}
        onClose={closeRouterSettingModal}
      >
        <RouterSettings
          {...props}
          router={props.router}
          closeRouterSettingModal={closeRouterSettingModal}
          status={routerStatus}
        />
      </Modal>
    </>
  );
}

export { RouterArea };
