import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import styles from "./BatteryStatus.module.sass";

function BatteryStatus({
  capacity,
  showLabel,
  negative,
  labelOnly,
  className,
}) {
  const BatteryIconPath = () => {
    if (capacity === null) {
      return {
        src: "/assets/images/icon-battery/icon-battery-warning.svg",
        negativeSrc:
          "/assets/images/icon-battery/icon-battery-warning-white.svg",
        label: "不明",
      };
    }
    if (capacity > 85) {
      return {
        src: "/assets/images/icon-battery/icon-battery-lv4.svg",
        negativeSrc: "/assets/images/icon-battery/icon-battery-lv4-white.svg",
        label: "充分",
      };
    } else if (capacity > 65) {
      return {
        src: "/assets/images/icon-battery/icon-battery-lv3.svg",
        negativeSrc: "/assets/images/icon-battery/icon-battery-lv3-white.svg",
        label: "中位",
      };
    } else if (capacity > 35) {
      return {
        src: "/assets/images/icon-battery/icon-battery-lv2.svg",
        negativeSrc: "/assets/images/icon-battery/icon-battery-lv2-white.svg",
        label: "低下",
      };
    } else if (capacity > 0) {
      return {
        src: "/assets/images/icon-battery/icon-battery-lv1.svg",
        negativeSrc: "/assets/images/icon-battery/icon-battery-lv1-white.svg",
        label: "交換",
      };
    } else {
      return {
        src: "/assets/images/icon-battery/icon-battery-lv0.svg",
        negativeSrc: "/assets/images/icon-battery/icon-battery-lv0-white.svg",
        label: "なし",
      };
    }
  };

  if (labelOnly) {
    return BatteryIconPath().label;
  }

  return (
    <div
      className={cx(styles.icon, negative ? styles.negative : "", className)}
    >
      <img
        src={negative ? BatteryIconPath().negativeSrc : BatteryIconPath().src}
        alt="バッテリーの状態"
      />
      {showLabel && (
        <div className={styles.label}>電池：{BatteryIconPath().label}</div>
      )}
    </div>
  );
}

BatteryStatus.propTypes = {
  showLabel: PropTypes.bool,
  capacity: PropTypes.number,
  negative: PropTypes.bool,
  className: PropTypes.string,
};

BatteryStatus.defaultProps = {
  showLabel: false,
  negative: false,
};

export default BatteryStatus;
