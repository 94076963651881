import React, { useEffect, useState } from 'react';
import { A, navigate } from 'hookrouter';
import Button from "../../partials/Button";
import Wrap from "../../partials/Wrap";
import styles from "./PasswordFormFinish.module.sass";

function PasswordFormFinish(props) {
  return (
    <Wrap>
    <A href="/">
      <div className={styles.headerLogo}><img src="/assets/images/word_logo.svg" alt="logo" width="79"/></div>
    </A>
    <div className={styles.body}>
      <img src="/assets/images/thanks.svg" alt="てるちゃん完了" />
      <div className={styles.text}>
        パスワードを更新しました
      </div>
      <div className={styles.buttonArea}>
        <Button fit primary onClick={() => navigate("/")}>
          トップページへ
        </Button>
      </div>
    </div>
  </Wrap>
  );
}

export default PasswordFormFinish;
