import cx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Button.module.sass';

const Button = ({
  children,
  loading,
  disabled,
  onClick,
  primary,
  positive,
  negative,
  negativeTextOnly,
  size,
  fit,
  className,
  ...props
}) => {
 
  return (
    <button
      tabIndex={disabled ? -1 : 0}
      {...props}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      className={cx(
        styles.button,
        loading ? styles.loading : '',
        primary ? styles.primary : '',
        positive ? styles.positive : '',
        negative ? styles.negative : '',
        negativeTextOnly ? styles.negativeTextOnly : '',
        fit ? styles.fit : '',
        size === 'small' ? styles.sizeSmall : '',
        size === 'large' ? styles.sizeLarge : '',
        className,
      )}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  negativeTextOnly: PropTypes.bool,
  fit: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  onClick: () => null,
  loading: false,
  disabled: false,
  primary: false,
  positive: false,
  negative: false,
  negativeTextOnly: false,
  fit: false,
  size: undefined,
};

export default Button;