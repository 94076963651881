import { A } from "hookrouter";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "../../partials/Button";
import Input from "../../partials/Input";
import Text from "../../partials/Text";
import { ValidateUser } from "../../partials/validateUser";
import Wrap from "../../partials/Wrap";
import styles from "./Create.module.sass";

function Create(props) {
  const [emailAddress, setEmailAddress] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [emailSent, setEmailSent] = useState(false);

  const submit = async (e) => {
    setErrorMessages({});
    let errors = {};

    //メールアドレスのバリデーション
    errors.email = ValidateUser.validateEmail(emailAddress);
    let existingUserData = await ValidateUser.checkUserExists({
      email: emailAddress,
    });
    if (
      existingUserData.data &&
      existingUserData.data.Users &&
      existingUserData.data.Users.length > 0
    ) {
      errors.email.push("メールアドレスは既に利用されています");
    }

    const username = emailAddress;
    const password = btoa(uuidv4());

    let hasError = false;
    for (let key in errors) {
      if (errors[key].length > 0) {
        hasError = true;
      }
    }

    if (hasError) {
      setErrorMessages(errors);
      return;
    } else {
      //navigate("/signup/confirmemail", false, { emailAddress });
      try {
        const { user } = await props.Amplify.Auth.signUp({
          username: username,
          password: password,
          attributes: {
            email: emailAddress,
            phone_number: "",
            address: "",
            name: "",
          },
        });
        setEmailSent(true);
      } catch (error) {
        errors.email.push(
          "通信エラーが発生しました。恐れ入りますが再度お試しください"
        );
        setErrorMessages(errors);
      }
    }
  };

  return (
    <>
      {!emailSent && (
        <Wrap>
          <A href="/">
            <div className={styles.headerLogo}>
              <img src="/assets/images/word_logo.svg" alt="logo" width="79" />
            </div>
          </A>
          <div className={styles.inputMailAddressTitle}>
            <Text variant="h1" center>
              登録するメールアドレスを入力してください
            </Text>
          </div>
          <div>
            <Input
              type="text"
              placeholder="taro@telchan.com"
              errorMessages={errorMessages.email}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              value={emailAddress}
              size="large"
            />
          </div>
          <div className={styles.confirmMailNote}>
            <div className={styles.confirmMailNoteTitle}>
              docomoメール（@docomo.ne.jp）をご利用のお客様は、メールが届かない場合があります。
            </div>
            「tel-chan_support@tel-chan.jp」をあらかじめ受信リストに加えてください。
            <div>
              <a
                href="https://www.nttdocomo.co.jp/info/spam_mail/domain/"
                target="_blank"
                norefferer
              >
                docomo受信リスト設定方法
              </a>
            </div>
          </div>
          <div className={styles.action}>
            <Button onClick={submit} fit primary>
              次へ
            </Button>
          </div>
          <div className={styles.inputMailAddressText}>
            登録することで、
            <A href="/terms" className={styles.link}>
              利用規約
            </A>
            をお読みの上、それに同意したものとみなします。
          </div>
          <div className={styles.backPageButton}>
            <A href="/">
              <span className={styles.backPageButtonColor}>前の画面に戻る</span>
            </A>
          </div>
        </Wrap>
      )}
      {emailSent && (
        <Wrap>
          <A href="/">
            <div className={styles.headerLogo}>
              <img src="/assets/images/word_logo.svg" alt="logo" width="79" />
            </div>
          </A>
          <div className={styles.confirmMailText}>
            <Text variant="h1" center>
              メールをご確認ください
            </Text>
          </div>
          <div className={styles.confirmMailImage}>
            <img
              src="/assets/images/icon-confirm-mail.png"
              alt="logo"
              width="150"
            />
          </div>
          <div className={styles.confirmMailDetailText} data-private>
            <strong>{emailAddress}</strong>
            へメールを送信しました。メール内のURLをクリックしてください。
          </div>
        </Wrap>
      )}
    </>
  );
}

export default Create;
