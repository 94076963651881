import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.sass';

const Select = ({
  style,
  size,
  loading,
  readOnly,
  disabled,
  error,
  group,
  value,
  multiple,
  children,
  name,
  ...props
}) => (
  <div 
    className={cx(
      styles.wrap,
      group && styles.group,
      error && styles.error,
    )}
    style={{width: size ? `${size}px` : ''}}
  >
    <select
      {...props}
      className={styles.control}
      multiple={multiple}
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      name={name}
  >
    {children}
  </select>
  </div>
)

Select.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  group: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string,
};

Select.defaultProps = {
  children: null,
  value: '',
  size: undefined,
  readOnly: false,
  disabled: false,
  error: false,
  group: false,
  multiple: false,
  loading: false,
  name: undefined,
};


export default Select