import React, { useEffect, useState } from 'react';
import AlertBox from "../../partials/AlertBox";
import { A, navigate } from 'hookrouter';
import AuthorizedHeader from '../../partials/AuthorizedHeader';
import Footer from '../../partials/Footer';
import PageTitle from '../../partials/PageTitle';
import Button from '../../partials/Button';
import Text from '../../partials/Text';
import Wrap from '../../partials/Wrap';
import styles from './Finish.module.sass';
import Box from '../../partials/Box';
import ItemCard from '../../partials/ItemCard';
import { CheckUser } from '../../partials/checkUser';

function Finish(props) {
  const [deviceCount, setDeviceCount] = useState(0);
  const [routerCount, setRouterCount] = useState(0);
  const [user, setUser] = useState(null);
  const [pageReady, setPageReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    onload();
  }, []);

  useEffect(() => {
    fetchOrder();
  }, [user]);

  const onload = async () => {
    const me = await CheckUser(props.Auth);
    if (props.status == '審査中') {
      setErrorMessage('請求書払いがご利用いただけるか審査中です。');
      setShowErrorMessage(true);
    }
    setUser(me);
  };

  const fetchOrder = async () => {
    const url = new URL(window.location.href);
    const query = new URLSearchParams(url.searchParams);
    const paths = url.pathname.split("/");
    if (paths[3]) {
      const session = await props.Auth.currentSession();
      const order = await props.API.get("PriceData", `/order/${paths[3]}`, {
        headers: { Authorization: session.idToken.jwtToken },
      });
      //console.log(order);
      if (props.routerCount == null) {
        setRouterCount(order.routerCount);
      }
      if (props.deviceCount == null) {
        setDeviceCount(order.deviceCount);
      }
      setPageReady(true);
    } else {
      setPageReady(true);
    }
  };

  if (!pageReady) {
    return <div />;
  }

  return (
    <>
      <AuthorizedHeader current="menu" />
      <Wrap>
        <div className={styles.orderFinishedArea}>
          <div className={styles.orderFinishedText}>
            ご注文ありがとうございます
          </div>
          {showErrorMessage && (
            <div className={styles.orderFinishedTextAlert}>
              請求書払いがご利用いただけるか審査中です。審査結果により、注文がキャンセルになることがあります。結果がわかるまで数日ほどお時間をいただくことがございます。
            </div>
          )}
          <Box basic gutterBottom>
            <ItemCard
              imageUrl="/assets/images/icon-router-blue.png"
              name="親機ルーター"
              description="Braveridge BraveROUTE"
              quantity={props.routerCount || routerCount}
            />
            <div className={styles.divider} />
            <ItemCard
              imageUrl="/assets/images/icon-sensor.png"
              name="子機センサー（温度・湿度・照度）"
              description="Braveridge TETRA 防水"
              quantity={props.deviceCount || deviceCount}
            />
          </Box>
          <div className={styles.deliveryDateText}>
            お届け予定日
            <strong>約2週間前後</strong>
          </div>
          <div className={styles.action}>
            <A href="/dashboard">
              <Button fit primary>
                ホーム画面へ
              </Button>
            </A>
          </div>
        </div>
      </Wrap>
      <Footer />
    </>
  );
}

export default Finish;
