import React, { useEffect, useState } from "react";
import { A, navigate } from "hookrouter";
import Footer from "../../partials/Footer";
import PageTitle from "../../partials/PageTitle";
import Button from "../../partials/Button";
import Checkbox from "../../partials/Checkbox";
import Field from "../../partials/Field";
import Input from "../../partials/Input";
import PurchaseHeader from '../../partials/PurchaseHeader';
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import Select from "../../partials/Select";
import Stepper from "../../partials/Stepper";
import CheckoutSummary from "../../partials/CheckoutSummary";
import Box from "../../partials/Box";
import styles from "./Shipping.module.sass";
import { ValidateUser } from "../../partials/validateUser";
import { CalcPrice } from '../../../../web/src/partials/calcPrice';
import { Telchan } from '../../Telchan';

function Shipping(props) {
  const [useShippingAddressState, setUseShippingAddressState] = useState(true);

  const handleUseShippingAddress = () => {
    setUseShippingAddressState(!useShippingAddressState);
  };

  const [shippingAddressName, setShippingAddressName] = useState(props.shippingAddressName || "");
  const [shippingAddress1, setShippingAddress1] = useState(props.shippingAddress1 || "北海道");
  const [shippingAddress2, setShippingAddress2] = useState(props.shippingAddress2 || "");
  const [shippingAddress3, setShippingAddress3] = useState(props.shippingAddress3 || "");
  const [shippingAddressZipcode, setShippingAddressZipcode] = useState(props.shippingAddressZipcode || "");
  const [shippingAddressPhoneNumber, setShippingAddressPhoneNumber] = useState(
    props.shippingAddressPhoneNumber || ""
  );

  const [billingAddressName, setBillingAddressName] = useState(props.billingAddressName || "");
  const [billingAddress1, setBillingAddress1] = useState(props.billingAddress1 || "");
  const [billingAddress2, setBillingAddress2] = useState(props.billingAddress2 || "");
  const [billingAddress3, setBillingAddress3] = useState(props.billingAddress3 || "");
  const [billingAddressZipcode, setBillingAddressZipcode] = useState(props.billingAddressZipcode || "");
  const [billingAddressPhoneNumber, setBillingAddressPhoneNumber] = useState(
    props.billingAddressPhoneNumber || ""
  );

  const [errorMessages, setErrorMessages] = useState({});

  const [organizationName, setOrganizationName] = useState(props.organizationName || "");
  const [billingOrganizationName, setBillingOrganizationName] = useState(props.billingOrganizationName || "");
  const [divisionName, setDivisionName] = useState(props.divisionName || "");
  const [billingDivisionName, setBillingDivisionName] = useState(props.billingDivisionName || "");

  useEffect(() => {
  }, []);

  useEffect(()=>{
    if (shippingAddressName != null) {props.updateProps('shippingAddressName', shippingAddressName);}
    if (shippingAddress2 != null) {props.updateProps('shippingAddress2', shippingAddress2);}
    if (shippingAddress3 != null) {props.updateProps('shippingAddress3', shippingAddress3);}
    if (shippingAddressZipcode != null) {props.updateProps('shippingAddressZipcode', shippingAddressZipcode);}
    if (shippingAddressPhoneNumber != null) {props.updateProps('shippingAddressPhoneNumber', shippingAddressPhoneNumber);}
    if (divisionName != null) {props.updateProps('divisionName', divisionName);}
    if (organizationName != null) {props.updateProps('organizationName', organizationName);}
  }, [shippingAddressName, shippingAddress2, shippingAddress3, shippingAddressZipcode, shippingAddressPhoneNumber, organizationName, divisionName]);

  useEffect(() => {
    if (billingAddressName != null) {props.updateProps('billingAddressName', billingAddressName);}
    if (billingAddress1 != null) {props.updateProps('billingAddress1', billingAddress1);}
    if (billingAddress2 != null) {props.updateProps('billingAddress2', billingAddress2);}
    if (billingAddress3 != null) {props.updateProps('billingAddress3', billingAddress3);}
    if (billingAddressZipcode != null) {props.updateProps('billingAddressZipcode', billingAddressZipcode);}
    if (billingAddressPhoneNumber != null) {props.updateProps('billingAddressPhoneNumber', billingAddressPhoneNumber);}
    if (billingDivisionName != null) {props.updateProps('billingDivisionName', billingDivisionName);}
    if (billingOrganizationName != null) {props.updateProps('billingOrganizationName', billingOrganizationName);}
  }, [billingAddressName, billingAddress1, billingAddress2, billingAddress3, billingAddressPhoneNumber, billingAddressPhoneNumber, billingOrganizationName, billingDivisionName]);

  useEffect(() => {
    if (shippingAddress1) {
      props.updateProps('shippingAddress1', shippingAddress1);
      props.setShipmentFee(CalcPrice.shipmentFee(shippingAddress1, parseInt(props.routerCount), parseInt(props.deviceCount)));
    }
  }, [shippingAddress1]);

  const renderPrefectureOptions = () => {
    let options = [];
    let prefectures = [
      "北海道",
      "青森県",
      "岩手県",
      "宮城県",
      "秋田県",
      "山形県",
      "福島県",
      "茨城県",
      "栃木県",
      "群馬県",
      "埼玉県",
      "千葉県",
      "東京都",
      "神奈川県",
      "新潟県",
      "富山県",
      "石川県",
      "福井県",
      "山梨県",
      "長野県",
      "岐阜県",
      "静岡県",
      "愛知県",
      "三重県",
      "滋賀県",
      "京都府",
      "大阪府",
      "兵庫県",
      "奈良県",
      "和歌山県",
      "鳥取県",
      "島根県",
      "岡山県",
      "広島県",
      "山口県",
      "徳島県",
      "香川県",
      "愛媛県",
      "高知県",
      "福岡県",
      "佐賀県",
      "長崎県",
      "熊本県",
      "大分県",
      "宮崎県",
      "鹿児島県",
      "沖縄県",
    ];
    prefectures.map((prefecture, index) => {
      options.push(<option key={index}>{prefecture}</option>);
    });
    return options;
  };

  const submit = async (e) => {
    setErrorMessages({});
    let errors = {};

    //お届け先住所のバリデーション
    const formattedShippingAddressZipcode = ValidateUser.formatZipcode(
      shippingAddressZipcode
    );
    errors.shippingAddressZipcode = ValidateUser.validateZipcode(
      formattedShippingAddressZipcode
    );
    errors.shippingAddressName = ValidateUser.validateUsername(
      shippingAddressName
    );
    errors.shippingAddress2 = ValidateUser.validateAddress2(shippingAddress2);
    errors.shippingAddress3 = ValidateUser.validateAddress3(shippingAddress3);
    errors.shippingAddressPhoneNumber = ValidateUser.validatePhoneNumber(shippingAddressPhoneNumber);
    if (errors.shippingAddressPhoneNumber.length <= 0) {
      const formattedShippingAddressPhoneNumber = ValidateUser.formatPhoneNumber(
        shippingAddressPhoneNumber
      );
    }

    //請求先住所のバリデーション
    if (!useShippingAddressState) {
      const formattedBillingAddressZipcode = ValidateUser.formatZipcode(
        billingAddressZipcode
      );
      errors.billingAddressZipcode = ValidateUser.validateZipcode(
        formattedBillingAddressZipcode
      );
      errors.billingAddressName = ValidateUser.validateUsername(
        billingAddressName
      );
      errors.billingAddress2 = ValidateUser.validateAddress2(billingAddress2);
      errors.billingAddress3 = ValidateUser.validateAddress3(billingAddress3);
      errors.billingAddressPhoneNumber = ValidateUser.validatePhoneNumber(billingAddressPhoneNumber);
      if (errors.billingAddressPhoneNumber.length <= 0) {
        const formattedBillingAddressPhoneNumber = ValidateUser.formatPhoneNumber(
          billingAddressPhoneNumber
        );
      }
    } else {
      // 記入途中のデータがあれば削除する
      setBillingAddressName("");
      setBillingAddress1("");
      setBillingAddress2("");
      setBillingAddress3("");
      setBillingAddressPhoneNumber("");
      setBillingAddressZipcode("");
    }

    let hasError = false;
    for (let key in errors) {
      if (errors[key].length > 0) {
        hasError = true;
      }
    }

    if (hasError) {
      setErrorMessages(errors);
      window.scrollTo(0, 0);
      return;
    } else {
      //navigate("/shop/billing", false);
      props.setPage('billing');
    }
  };

  return (
    <>
      <PurchaseHeader />
      <Wrap>
        <PageTitle title="お届け先の入力">
        </PageTitle>
        <Stepper steps={[1, 2, 3, 4]} current={2} />
        <Field label="お名前 ※スペース含む20文字まで" required>
          <Input
            maxLength={20}
            type="text"
            placeholder="例）山田てる子"
            onChange={(e) => {
              setShippingAddressName(e.target.value);
            }}
            value={shippingAddressName}
            errorMessages={errorMessages.shippingAddressName}
          />
        </Field>
        <Field label="組織名">
          <Input
            maxLength={50}
            type="text"
            placeholder="例）〇〇組合・〇〇株式会社"
            onChange={(e) => {
              setOrganizationName(e.target.value);
            }}
            value={organizationName}
            errorMessages={errorMessages.organizationName}
          />
        </Field>
        <Field label="部署名">
          <Input
            maxLength={50}
            type="text"
            placeholder="例）〇〇課"
            onChange={(e) => {
              setDivisionName(e.target.value);
            }}
            value={divisionName}
            errorMessages={errorMessages.divisionName}
          />
        </Field>
        <Field label="郵便番号" required>
          <Input
            type="tel"
            placeholder="例）1234567"
            maxLength="10"
            onChange={(e) => {
              setShippingAddressZipcode(e.target.value.replace(/[^\d]/g, ''));
            }}
            value={shippingAddressZipcode}
            errorMessages={errorMessages.shippingAddressZipcode}
          />
        </Field>
        <Field label="都道府県" required>
          <Select
            onChange={(e) => {
              setShippingAddress1(e.target.value);
            }}
            value={shippingAddress1}
          >
            {renderPrefectureOptions()}
          </Select>
        </Field>
        <Field label="市区町村" required>
          <Input
            type="text"
            placeholder="例）港区"
            onChange={(e) => {
              setShippingAddress2(e.target.value);
            }}
            value={shippingAddress2}
            errorMessages={errorMessages.shippingAddress2}
          />
        </Field>
        <Field label="それ以降の住所" required>
          <Input
            type="text"
            placeholder="例）南青山2-26-1 南青山ビル105"
            onChange={(e) => {
              setShippingAddress3(e.target.value);
            }}
            value={shippingAddress3}
            errorMessages={errorMessages.shippingAddress3}
          />
        </Field>
        <Field label="電話番号" required>
          <Input
            type="tel"
            placeholder="例）09012345678"
            onChange={(e) => {
              setShippingAddressPhoneNumber(e.target.value);
            }}
            value={shippingAddressPhoneNumber}
            errorMessages={errorMessages.shippingAddressPhoneNumber}
          />
        </Field>

        <div className={styles.shippingAddress}>
          <Text variant="h3" gutter-bottom>
            請求先
          </Text>
          <Checkbox
            label="お届け先住所を使用"
            checked={useShippingAddressState}
            onClick={handleUseShippingAddress}
            onChange={() => {}}
          />
          {useShippingAddressState &&
            (shippingAddress2 && shippingAddressName ? (
              <div data-private>
                <Text>
                  {shippingAddressName} 様<br />
                  {organizationName &&
                    <>{organizationName}<br /></>
                  }
                  {divisionName &&
                    <>{divisionName}<br /></>
                  }
                  〒{shippingAddressZipcode}
                  <br />
                  {shippingAddress1}
                  {shippingAddress2}
                  {shippingAddress3}
                </Text>
              </div>
            ) : (
              <div>お届け先を入力してください</div>
            ))}
          {!useShippingAddressState && (
            <div className={styles.billingAddress}>
              <Field label="お名前 ※スペース含む20文字まで" required>
                <Input
                  maxLength={20}
                  type="text"
                  placeholder="例）山田てる子"
                  onChange={(e) => {
                    setBillingAddressName(e.target.value);
                  }}
                  value={billingAddressName}
                  errorMessages={errorMessages.billingAddressName}
                />
              </Field>
              <Field label="組織名">
                <Input
                  maxLength={50}
                  type="text"
                  placeholder="例）〇〇組合・〇〇株式会社"
                  onChange={(e) => {
                    setBillingOrganizationName(e.target.value);
                  }}
                  value={billingOrganizationName}
                  errorMessages={errorMessages.billingOrganizationName}
                />
              </Field>
              <Field label="部署名">
                <Input
                  maxLength={50}
                  type="text"
                  placeholder="例）〇〇課"
                  onChange={(e) => {
                    setBillingDivisionName(e.target.value);
                  }}
                  value={billingDivisionName}
                  errorMessages={errorMessages.billingDivisionName}
                />
              </Field>
              <Field label="郵便番号" required>
                <Input
                  type="tel"
                  maxLength="10"
                  placeholder="例）1234567"
                  onChange={(e) => {
                    setBillingAddressZipcode(e.target.value.replace(/[^\d]/g, ''));
                  }}
                  value={billingAddressZipcode}
                  errorMessages={errorMessages.billingAddressZipcode}
                />
              </Field>
              <Field label="都道府県" required>
                <Select
                  onChange={(e) => {
                    setBillingAddress1(e.target.value);
                  }}
                  value={billingAddress1}
                >
                  {renderPrefectureOptions()}
                </Select>
              </Field>
              <Field label="市区町村" required>
                <Input
                  type="text"
                  placeholder="例）港区"
                  onChange={(e) => {
                    setBillingAddress2(e.target.value);
                  }}
                  value={billingAddress2}
                  errorMessages={errorMessages.billingAddress2}
                />
              </Field>
              <Field label="それ以降の住所" required>
                <Input
                  type="text"
                  placeholder="例）南青山2-26-1 南青山ビル105"
                  onChange={(e) => {
                    setBillingAddress3(e.target.value);
                  }}
                  value={billingAddress3}
                  errorMessages={errorMessages.billingAddress3}
                />
              </Field>
              <Field label="電話番号" required>
                <Input
                  type="tel"
                  placeholder="例）09012345678"
                  onChange={(e) => {
                    setBillingAddressPhoneNumber(e.target.value);
                  }}
                  value={billingAddressPhoneNumber}
                  errorMessages={errorMessages.billingAddressPhoneNumber}
                />
              </Field>
            </div>
          )}
        </div>
        <div>
          <Button primary fit onClick={submit}>
            お支払い方法へすすむ
          </Button>
        </div>
        <div className={styles.backPageButton}>
          <span onClick={(e) => {e.preventDefault(); props.setPage('items');}} className={styles.backPageButtonColor}>前の画面に戻る</span>
        </div>

        <Box basic>
          <CheckoutSummary
            title="初回のみのお支払い料金"
            items={[
              { name: props.routerCount > 1 ? `親機ルーター端末代金 ×${props.routerCount}` : "親機ルーター端末代金", price: props.routerPrice },
              { name: props.deviceCount > 1 ? `子機センサー端末代金 ×${props.deviceCount}` : "子機センサー端末代金", price: props.devicePrice },
              { name: "送料", price: props.shipmentFee },
            ]}
          />
          <div className={styles.divider} />
          <CheckoutSummary
            title="毎月のお支払い料金"
            items={[
              { name: props.routerCount > 1 ? `親機ルーター月額利用料 ×${props.routerCount}` : "親機ルーター月額利用料", price: props.routerMonthlyFee },
              { name: props.deviceCount > 1 ? `子機センサー月額利用料 ×${props.deviceCount}` : "子機センサー月額利用料", price: props.deviceMonthlyFee },
            ]}
          />
          <div className={styles.divider} />
          <div className={styles.checkoutNote}>※上記以外に、監視料金、通知料金、確認料金がそれぞれかかります。詳しくは、<a href="https://support.tel-chan.com/hc/ja/articles/900004699186-%E8%B3%AA%E5%95%8F-%E3%81%A6%E3%82%8B%E3%81%A1%E3%82%83%E3%82%93%E3%81%AE%E6%96%99%E9%87%91%E4%BD%93%E7%B3%BB%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84" target="_blank">料金体系</a>をご確認ください。<br />
          ※表示の料金は、すべて税込です。</div>
        </Box>
      </Wrap>
      <Footer openLinkInBlank={true} />
    </>
  );
}

export default Shipping;
