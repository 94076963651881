import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import styles from "./HelpMessageCard.module.sass";

function HelpMessageCard({ title, linkText, className, onClick }) {
  return (
    <div className={cx(styles.wrap, className)} onClick={onClick}>
      <div className={styles.titleWrap}>
        <div className={styles.title}>{title}</div>
        <div className={styles.linkText}>{linkText}</div>
      </div>
      <img src="/assets/images/icon-help.svg" alt="ヘルプアイコン" />
    </div>
  );
}

HelpMessageCard.propTypes = {
  title: PropTypes.string,
  linkText: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default HelpMessageCard;
