import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import { A, navigate } from 'hookrouter';
import styles from './Footer.module.sass';

const scrollTop = () =>{
  window.scrollTo({top: 0, behavior: 'smooth'});
};

function Footer({ children, openLinkInBlank }){
  return (
    <>
      <div onClick={scrollTop} className={styles.backtoTop}>
        <div className={styles.backButton}>
          <div className={styles.backButtonIcon} />
          <div className={styles.backButtonText}>上部へ移動</div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.links}>
          {openLinkInBlank &&
          <a href="/terms" target="_blank" className={styles.link}>利用規約</a>
          }
          {!openLinkInBlank &&
          <A href="/terms" className={styles.link}>利用規約</A>
          }
          <a href="https://www.kddi-webcommunications.co.jp/privacy/" target="_blank" className={styles.link}>プライバシーポリシー</a>
          {openLinkInBlank &&
          <a href="/law" target="_blank" className={styles.link}>特別商取引法</a>
          }
          {!openLinkInBlank &&
          <A href="/law" className={styles.link}>特別商取引法</A>
          }
        </div>
        <div className={styles.copyright}>©︎ 2021 KDDI Web Communications inc.</div>
      </div>
    </>
  )
}

Footer.propTypes = {
  children: PropTypes.node,
}

export default Footer
