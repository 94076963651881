import React, { useEffect, useState } from 'react';
import { A, navigate } from 'hookrouter';
import Button from "../../partials/Button";
import Wrap from "../../partials/Wrap";
import styles from "./Finish.module.sass";
export const useDidMount = (func) => useEffect(() => { func() }, []);

function UnsubscribeFinish(props) {
  const [user, setUser] = useState({});
  const [alertToList, setAlertToList] = useState([]);
  const [alertToId, setAlertToId] = useState('');
  const [alertTo, setAlertTo] = useState(null);

  const onload = async () => {
  };

  useDidMount(async () => {
    onload();
  });

  return (
    
    <Wrap>
    <A href="/">
      <div className={styles.headerLogo}><img src="/assets/images/word_logo.svg" alt="logo" width="79"/></div>
    </A>
    <div className={styles.body}>
      <img src="/assets/images/thanks.svg" alt="てるちゃん完了" />
      <div className={styles.text}>
        これまでのご利用<br/>ありがとうございました
      </div>
      <div className={styles.note}>後ほど、確認メールをお送り致します</div>
      <div className={styles.buttonArea}>
        <Button fit primary onClick={() => navigate("/")}>
          トップページへ
        </Button>
      </div>
    </div>
  </Wrap>
  )
}

export default UnsubscribeFinish;
