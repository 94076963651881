import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Stepper.module.sass';

function Stepper({
  current,
  steps
}) {

  const renderSteps = (steps) => {
    let rows = [];
    steps.map((step, index) => {
      rows.push(
        <div
          className={cx(
            styles.numberStyle,
            step <= current ? styles.current : null
          )}
          key={index}
        >
          {step}
        </div>
      )
    })
    return rows;
  }

  return (
    <div className={styles.wrap}>
      {renderSteps(steps)}
    </div>
  )
}

Stepper.propTypes = {
  current: PropTypes.number,
  steps: PropTypes.array,
};

export default Stepper