import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Switch.module.sass';

const Switch = ({
  checked,
  ...props
}) => (
  <label className={styles.wrap}>
    <input
      type="checkbox"
      className={styles.checkbox}
      checked={checked}
      onChange={(e)=>{
        props.onChange ? props.onChange(e) : (()=>{})();
      }}
    />
    <span className={styles.slider} />
  </label>
)

Switch.propTypes = {
  checked: PropTypes.bool
};

Switch.defaultProps = {
  checked: false,
};


export default Switch
