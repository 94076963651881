import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import styles from "./ProgressIndicator.module.sass";

function ProgressIndicator(props) {
  return (
    <div
      className={cx(
        styles.progressIndicator,
        props.gutter ? styles.gutter : null,
        props.fit ? styles.fit : null
      )}
    >
      <div className={styles.loader}>
        <svg
          className={cx(
            styles.spinner,
            props.type === "Small" ? styles.small : null,
            props.type === "xSmall" ? styles.xSmall : null,
            props.type === "xxSmall" ? styles.xxSmall : null
          )}
          style={{ stroke: props.color }}
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className={styles.circle}
            fill="none"
            strokeWidth="3.5"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    </div>
  );
}

ProgressIndicator.propTypes = {
  gutter: PropTypes.bool,
  fit: PropTypes.bool,
  type: PropTypes.oneOf(["Small", "xSmall", "xxSmall"]),
  color: PropTypes.string,
};

export default ProgressIndicator;
