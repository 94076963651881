import React from 'react';
import { A, navigate } from 'hookrouter';
import styles from './UnauthorizedHeader.module.sass';

function UnauthorizedHeader(props) {
  return (
    <div className={styles.wrap}>
      <A href="/">
        <img className="Header-logo" src="/assets/images/word_logo.svg" alt="てるちゃんロゴ" />
      </A>
    </div>
  );
}

export default UnauthorizedHeader
