import React, { useState } from "react";
import DeviceIcon from "./DeviceIcon";
import styles from "./UpdateDevicesNetworkStatus.module.sass";

function UpdateDevicesNetworkStatus() {
  const [devicesStatusCheckedTimeFirst, setDevicesStatusCheckedTimeFirst] =
    useState(false);
  const [devicesStatusCheckedTimeSecond, setDevicesStatusCheckedTimeSecond] =
    useState(false);

  //  TODO:暫定として一定時間で次のステップへ進む
  // setTimeout(() => {
  //   setDevicesStatusCheckedTimeFirst(true);
  // }, 60000);
  //  TODO:実装後に切り替え
  // setTimeout(() => {
  //   setDevicesStatusCheckedTimeSecond(true);
  // }, 60000);

  return (
    <div className={styles.component}>
      <img
        src="/assets/images/icon-network.png"
        alt="ネットワーク"
        className={styles.networkImage}
      />
      <div className={styles.statusBarWrap}>
        <div className={styles.title}>LTE</div>
        <div className={styles.statusBar}>
          {/* TODO:LTEが確認中の場合に表示します。 */}
          {devicesStatusCheckedTimeFirst === false && (
            <div className={styles.dotIconWrap}>
              <div className={styles.dotIcon} />
              <div className={styles.dotIcon} />
              <div className={styles.dotIcon} />
              <div className={styles.dotIcon} />
            </div>
          )}
          {/* TODO:LTEの受信が完了した場合に表示します。 */}
          {devicesStatusCheckedTimeFirst === true && (
            <div className={styles.status} />
          )}
        </div>
        <div className={styles.statusDetail}>
          {/* TODO:LTEが確認中の場合に表示します。 */}
          {devicesStatusCheckedTimeFirst === false && (
            <div className={styles.text}>確認中</div>
          )}
          {/* TODO:LTEの受信が完了した場合に表示します。 */}
          {devicesStatusCheckedTimeFirst === true && (
            <img
              src="/assets/images/icon-complete-white.svg"
              alt="完了"
              className={styles.iconCheck}
            />
          )}
        </div>
      </div>
      <img
        src="/assets/images/icon-router-blue.png"
        alt="親機ルーター"
        className={styles.routerImage}
      />
      <div className={styles.statusBarWrap}>
        <div className={styles.title}>BLE</div>
        <div className={styles.statusBar}>
          {/* TODO:BLEが確認中・受信中の場合に表示します。 */}
          {devicesStatusCheckedTimeSecond === false && (
            <div className={styles.dotIconWrap}>
              <div className={styles.dotIcon} />
              <div className={styles.dotIcon} />
              <div className={styles.dotIcon} />
              <div className={styles.dotIcon} />
            </div>
          )}
          {/* TODO:BLEの受信が完了した場合に表示します。 */}
          {devicesStatusCheckedTimeSecond === true && (
            <div className={styles.status} />
          )}
        </div>
        <div className={styles.statusDetail}>
          {/* TODO:BLEが確認中の場合に表示します。 */}
          {devicesStatusCheckedTimeFirst === false && (
            <div className={styles.text}>確認中</div>
          )}
          {/* TODO:BLEが受信中の場合に表示します。 */}
          {devicesStatusCheckedTimeFirst === true &&
            devicesStatusCheckedTimeSecond === false && (
              <div className={styles.text}>受信中</div>
            )}
          {/* TODO:BLEの受信が完了した場合に表示します。 */}
          {devicesStatusCheckedTimeSecond === true && (
            <img
              src="/assets/images/icon-complete-white.svg"
              alt="完了"
              className={styles.iconCheck}
            />
          )}
        </div>
      </div>
      <DeviceIcon type="sensor" size={52} />
    </div>
  );
}

UpdateDevicesNetworkStatus.propTypes = {};

export default UpdateDevicesNetworkStatus;
