import React, { useEffect, useState } from 'react';
import {useRoutes} from 'hookrouter';
import { Telchan } from '../../Telchan';

import Create from './Create';
//import ConfirmEmail from './ConfirmEmail';
import Profile from './Profile';
import NotFoundPage from '../NotFoundPage/';

function SignUp(props) {
  const [canEnter, setCanEnter] = useState(false);
  useEffect(() => {
    if (Telchan.env != 'development') {
      if (window.location.href.match(/^http:\/\/localhost:[\d]*\//)) {
        setCanEnter(true);
      }
    }
  });

  const routes = {
    '/': () => <Create {...props} />,
    '/profile': () => <Profile {...props} />
  };
  const routeResult = useRoutes(routes);
  return routeResult || <NotFoundPage />;
}

export default SignUp;
