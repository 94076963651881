import React, { useEffect, useState } from 'react';
import { A, navigate, useTitle } from 'hookrouter';
import AuthorizedHeader from '../partials/AuthorizedHeader';
import Footer from '../partials/Footer';
import PageTitle from '../partials/PageTitle';
import Button from '../partials/Button';
import Text from '../partials/Text';
import Wrap from '../partials/Wrap';
import styles from './EmailResetFinish.module.sass';
import { CheckUser } from '../partials/checkUser';
export const useDidMount = (func) => useEffect(() => { func() }, []);

function EmailResetFinish(props) {
  const [user, setUser] = useState({});

  const onload = async () => {
    let currentUser = await CheckUser(props.Auth);
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate('/', false);
    }
  };

  useDidMount(async () => {
    onload();
  });

  if (!user.attributes) {
    return (<div />);
  }

  return (
    <>
      <AuthorizedHeader current="menu" user={user} simple />
      <PageTitle title="Eメールアドレスを変更" />
      <Wrap fixedMinimumHeight>
        <Text variant="h4">
          本人確認のためメールを送信しました。
        </Text>
        <Text gutterBottom>
          メール本文にある「認証」をクリックしてください。
        </Text>
      </Wrap>
      <Footer />
    </>
  );
}

export {EmailResetFinish};
