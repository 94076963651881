import React, { useState } from "react";
import Button from "./Button";
import Modal from "./Modal";
import UpdatePhoneNumber from "./UpdatePhoneNumber";
import styles from "./RegistrationGuidePhoneNumberSignIn.module.sass";

function RegistrationGuidePhoneNumberSignIn() {
  const [modalState, setModalState] = useState({
    updatePhoneNumber: false,
  });
  return (
    <>
      <div className={styles.phoneNumberSignin}>
        <div className={styles.signinTitle}>かんたん電話ログイン</div>
        <div className={styles.signinText}>
          設定すると、次回からパスワードを入力せず、電話をかけるだけでログインできます。
        </div>
        <div className={styles.signinButtonWrap}>
          <Button
            fit
            onClick={() =>
              setModalState({ ...modalState, updatePhoneNumber: true })
            }
          >
            <div className={styles.signinButton}>
              <img
                src="/assets/images/notification/phone.svg"
                alt="電話"
                className={styles.buttonIcon}
              />
              <div className={styles.buttonText}>電話番号を登録</div>
            </div>
          </Button>
        </div>
      </div>
      <Modal
        show={modalState.updatePhoneNumber}
        type="Dialog"
        title="電話番号の変更"
      >
        <UpdatePhoneNumber
          onClose={() =>
            setModalState({ ...modalState, updatePhoneNumber: false })
          }
        />
      </Modal>
    </>
  );
}

export default RegistrationGuidePhoneNumberSignIn;
