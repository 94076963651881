import cx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Alert from "../../partials/Alert";
import BatteryStatus from "../../partials/BatteryStatus";
import Button from "../../partials/Button";
import ButtonClose from "../../partials/ButtonClose";
import DefinitionList from "../../partials/DefinitionList";
import DeviceIcon from "../../partials/DeviceIcon";
import DevicesNetworkStatus from "../../partials/DevicesNetworkStatus";
import HelpMessageCard from "../../partials/HelpMessageCard";
import Input from "../../partials/Input";
import Modal from "../../partials/Modal";
import ProgressIndicator from "../../partials/ProgressIndicator";
import RssiStatus from "../../partials/RssiStatus";
import SensorValues from "../../partials/SensorValues";
import Text from "../../partials/Text";
import UplinkNoteModal from "../../partials/UplinkNoteModal";
import Wrap from "../../partials/Wrap";
import styles from "./sensorSettings.module.sass";

function SensorSettings({ device, routers, dataNotAvailable, ...props }) {
  const [originalName] = useState(device.name);
  const [router, setRouter] = useState({});
  const [isEditing, setEditing] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [name, setName] = useState(device.name);
  const [routerId] = useState(device.router_id || "");
  const [isShowingAwaitingResultModal, setIsShowingAwaitingResultModal] =
    useState(false);
  const [isShowingUplinkNoteModal, setIsShowingUplinkNoteModal] =
    useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [sensorValuesList, setSensorValuesList] = useState([]);
  const [showProgress, setShowProgress] = useState(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  // reactではthis['setName']のようにインスタンスにアクセスできない…
  const handlers = {
    setName: setName,
  };

  const onload = async () => {
    routers.map((r) => {
      if (r.router_id == device.router_id) {
        setRouter(r);
      }
    });
    await fetchSensorValues();
  };

  useEffect(() => {
    onload();
  }, []);

  const fetchSensorValues = async () => {
    const session = await props.Auth.currentSession();
    const queryString = lastEvaluatedKey !== null ? "?lastEvaluatedKey=" + JSON.stringify(lastEvaluatedKey) : "";
    const sensorResponse = await props.API.get(
      "SensorValues",
      "/devices/" + device.device_id + "/sensorValues" + queryString,
      {
        headers: { Authorization: session.idToken.jwtToken },
      }
    );
    setShowProgress(false);
    //console.log(sensorResponse);
    let filteredSensorResponse = sensorResponse.filter((res) => {
      return res.display;
    });
    if (sensorValuesList.length) {
      filteredSensorResponse = sensorValuesList.concat(filteredSensorResponse);
    }
    setSensorValuesList(filteredSensorResponse);
    if (sensorResponse[sensorResponse.length - 1]._LastEvaluatedKey) {
      setLastEvaluatedKey(sensorResponse[sensorResponse.length - 1]._LastEvaluatedKey);
    } else {
      setLastEvaluatedKey(null);
    }
  };

  const handleChange = (name) => {
    const handlerName = `set${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    return (e) => {
      if (handlerName == "setName") {
        //console.log(e.target.value);
      }
      handlers[handlerName](e.target.value);
    };
  };

  const saveSetting = async () => {
    setShowAlert(false);
    let saveDeviceData = { router_id: routerId, device_id: device.device_id };
    //let saveSettingData = {alert_to_id: device.alertSettings[0].alert_to_id, user_id: device.alertSettings[0].user_id, setting_id: device.alertSettings[0].setting_id};
    let errors = [];
    setIsShowingAwaitingResultModal(true);

    // router
    if (routerId == "----") {
      delete saveDeviceData["router_id"];
    }

    // empty check
    if (name == "") {
      errors.push("名前を入力してください");
    } else if (name.length > 20) {
      errors.push("子機の名前は20文字以内です");
    } else {
      saveDeviceData["name"] = name;
    }

    // error handler
    if (errors.length > 0) {
      setErrorMessages(errors);
      setIsShowingAwaitingResultModal(false);
    } else {
      let session = await props.Auth.currentSession();
      // 名前を保存
      try {
        const deviceResponse = await props.API.post(
          "UpdateDevice",
          "/update_device",
          {
            headers: { Authorization: session.idToken.jwtToken },
            body: { device: saveDeviceData },
          }
        );
      } catch (e) {
        setShowAlert(true);
        setIsShowingAwaitingResultModal(false);
        return;
      }
      //window.location.reload();
      window.location.href = "/dashboard";
    }
  };

  const startEditing = () => {
    setEditing(true);
  };

  const endEditing = (e) => {
    //変更をキャンセル（キャンセル時以外は呼び出さない）
    setName(originalName);
    setErrorMessages([]);
    setEditing(false);
  };
  //console.log("currentDevice", device);

  return (
    <>
      <div className={styles.viewWrap}>
        <div className={styles.viewHeader}>
          <ButtonClose
            onClick={(e) => {
              props.closeSensorSettingModal(e);
            }}
          />
        </div>
        <div className={styles.viewBody}>
          <div className={styles.header}>
            <div className={styles.sensorImage}>
              <DeviceIcon type="sensor" size={130} />
            </div>
            <div className={styles.name}>
              {!device.suspended ? device.name : device.device_id}
            </div>
            <div className={styles.serialNumber}>
              製造番号：{device.device_id}
            </div>
            {!device.suspended && (
              <div className={styles.button}>
                <Button size="small" onClick={startEditing}>
                  名前の変更
                </Button>
              </div>
            )}
          </div>

          <Wrap>
            {/* デバイスの情報 */}
            {!device.suspended && (
              <div
                className={cx(
                  styles.deviceInformation,
                  dataNotAvailable ? styles.warning : null
                )}
              >
                {/* 障害発生時のテンプレート */}
                {dataNotAvailable && (
                  <div className={styles.alert}>
                    <div className={styles.alertTitle}>データがありません</div>
                    <div className={styles.alertDescription}>
                      電池残量・電波状況を確認してください
                    </div>
                  </div>
                )}

                {/* センサーの値 */}
                {!device.suspended && (
                  <div className={styles.deviceDetails}>
                    <div className={styles.values}>
                      <SensorValues {...props.sensorValues} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Wrap>
          {/* センサーの値の履歴 */}
          <div className={styles.deviceHistoryWrap}>
            <Text variant="h2" gutterBottom>
              センサー作動（Uplink）履歴
            </Text>
            <HelpMessageCard
              title="監視設定の時間帯以外でも、Uplinkがある場合があります。"
              linkText="詳しい説明を見る"
              className={styles.helpMessage}
              onClick={() => {
                setIsShowingUplinkNoteModal(true);
              }}
            />
            {showProgress && <ProgressIndicator />}
            {sensorValuesList.length > 0 &&
              sensorValuesList.map((s) => (
                <DefinitionList
                  title={moment(s.date).format("YYYY年MM月DD日 HH:mm")}
                  gutterBottom
                  borderBottom
                  key={s.uplink_id}
                >
                  {/* 各デバイスのネットワーク状況 */}
                  <DevicesNetworkStatus
                    rssiBLE={
                      s.device_rssi
                        ? s.device_rssi
                        : s.device
                        ? s.device.rssi
                        : null
                    }
                    rssiLTE={
                      s.router_rssi != null
                        ? s.router_rssi
                        : s.router
                        ? s.router.rssi
                        : null
                    }
                  />
                  <div className={styles.deviceName}>▽ 子機センサー</div>
                  <div className={styles.deviceHistory}>
                    <div className={styles.historyLabel}>電波BLE</div>
                    <div className={styles.historyValue}>
                      ：
                      {RssiStatus({
                        rssi: s.device_rssi
                          ? s.device_rssi
                          : s.device
                          ? s.device.rssi
                          : 0,
                        labelOnly: true,
                        type: "device",
                      })}
                      （
                      {s.device_rssi
                        ? s.device_rssi
                        : s.device
                        ? s.device.rssi
                        : 0}
                      dBm）
                    </div>
                  </div>
                  <div className={styles.deviceHistory}>
                    <div className={styles.historyLabel}>電池</div>
                    <div className={styles.historyValue}>
                      ：
                      {BatteryStatus({
                        capacity:
                          s.battery != null ? s.battery : s.device_battery,
                        labelOnly: true,
                      })}
                      （
                      {parseInt(
                        s.battery != null ? s.battery : s.device_battery
                      )}
                      %）
                    </div>
                  </div>
                  <div className={styles.deviceHistory}>
                    <div className={styles.historyLabel}>温度</div>
                    <div className={styles.historyValue}>
                      ：{parseInt(s.data.temperature)}℃
                    </div>
                  </div>
                  <div className={styles.deviceHistory}>
                    <div className={styles.historyLabel}>湿度</div>
                    <div className={styles.historyValue}>
                      ：{parseInt(s.data.humidity)}%
                    </div>
                  </div>
                  <div className={styles.deviceHistory}>
                    <div className={styles.historyLabel}>照度</div>
                    <div className={styles.historyValue}>
                      ：{parseInt(s.data.lux)}lx
                    </div>
                  </div>
                  <br />
                  <div>▽ 接続先親機ルーター</div>
                  <div className={styles.deviceHistory}>
                    <div className={styles.historyLabel}>名前</div>
                    <div className={styles.historyValue}>
                      ：{s.routerData.name}
                    </div>
                  </div>
                  <div className={styles.deviceHistory}>
                    <div className={styles.historyLabel}>電波LTE</div>
                    <div className={styles.historyValue}>
                      ：
                      {RssiStatus({
                        rssi:
                          s.router_rssi != null
                            ? s.router_rssi
                            : s.router
                            ? s.router.rssi
                            : null,
                        labelOnly: true,
                        type: "router",
                      })}
                      （
                      {s.router_rssi != null
                        ? s.router_rssi
                        : s.router
                        ? s.router.rssi
                        : null}
                      dBm）
                    </div>
                  </div>
                </DefinitionList>
              ))}
              {lastEvaluatedKey && (
              <div className={cx(styles.showMoreArea)}>
                <button
                  onClick={() => fetchSensorValues()}
                  className={cx(styles.showMoreButton, styles.currentButton)}
                >
                  <div className={styles.showMoreText}>さらに履歴を表示する</div>
                </button>
              </div>
              )}
          </div>
        </div>
      </div>

      {/* 名前の変更モーダル */}
      <Modal show={isEditing} type="Dialog" title="名前の変更">
        {showAlert && (
          <Alert>
            センサーの更新に失敗しました。もう一度、「変更を適用」をタップしてください。
          </Alert>
        )}
        <Input
          type="text"
          value={name}
          onChange={handleChange("name")}
          errorMessages={errorMessages}
        />
        <div className={styles.actions}>
          <Button onClick={saveSetting} fit positive>
            変更する
          </Button>
          <Button onClick={endEditing} fit>
            キャンセル
          </Button>
        </div>
      </Modal>

      {/* ローディングモーダル */}
      <Modal show={isShowingAwaitingResultModal} type="AwaitingResult" />

      {/* 監視設定の時間帯以外のUplinkについての説明 */}
      <UplinkNoteModal
        open={isShowingUplinkNoteModal}
        onClose={() => setIsShowingUplinkNoteModal(false)}
      />
    </>
  );
}

SensorSettings.propTypes = {
  device: PropTypes.object,
  dataNotAvailable: PropTypes.bool,
};

export default SensorSettings;
