import { navigate } from 'hookrouter';
import React, { useState } from "react";
import Button from "../partials/Button";
import Modal from "../partials/Modal";
import Text from "../partials/Text";
import styles from './PurchaseHeader.module.sass';

function PurchaseHeader(){
  const [isShowingConfirmCloseModal, setIsShowingConfirmCloseModal] = useState(
    false
  );

  const openConfirmCloseModal = () => {
    setIsShowingConfirmCloseModal(true);
  };

  const closeConfirmCloseModal = () => {
    setIsShowingConfirmCloseModal(false);
  };

  return (
    <>
      <div className={styles.headerWrap}>
        <div className={styles.headerCloseButton}>
          <div onClick={() => openConfirmCloseModal()} className={styles.linkArea}>
            <img src="/assets/images/close-button.png" alt="閉じる" />
          </div>
        </div>
        <div className={styles.headerLogo}>
          機器の購入
        </div>
      </div>
      <div className={styles.offset} />

      {/* 保存しなくていいかの確認モーダル */}
      <Modal show={isShowingConfirmCloseModal} type="Dialog">
        <Text variant="h2" center gutterBottom>
          購入は完了していません
        </Text>
        <div>
          <Button onClick={() => navigate("/")} fit negative>
            購入せずに終了
          </Button>
          <Button onClick={() => closeConfirmCloseModal()} fit>
            キャンセル
          </Button>
        </div>
      </Modal>

    </>
  )
}

export default PurchaseHeader