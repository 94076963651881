
const EncryptPassword = {
  encryptPassword: async (props, password) => {
    const encryptData = {
      password: password
    };
    try {
      const session = await props.Auth.currentSession();
      await props.API.post('EncryptPassword', '/encrypt_password', {
        headers: {Authorization: session.idToken.jwtToken}, body: encryptData});
    } catch (e) {
    }
  },
}

export{EncryptPassword};
