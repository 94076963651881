import React from 'react';
import { A } from "hookrouter";
import Button from '../../partials/Button';
import Wrap from '../../partials/Wrap';
import styles from "./index.module.sass";

function NotFoundPage(props) {
  let url;
  if (window.location.href.match(/\/\/$/)) {
    url = window.location.href.replace(/\/$/, '')
  } else {
    const urlBase = new URL(window.location.href);
    url = `${urlBase.protocol}//${urlBase.host}/`;
  }
  return (
    <div className={styles.background}>
      <Wrap>
        <div><img src="/assets/images/tel-chan-bad.png" width="110" /></div>
        <div className={styles.title}>ページが表示<br />できませんでした</div>
        <div className={styles.description}>URLが間違っている可能性があります</div>
        <div className={styles.actions}>
          <a href={url}>
            <Button primary>トップページへ</Button>
          </a>
        </div>
      </Wrap>
    </div>
  );
}

export default NotFoundPage;