import PropTypes from "prop-types";
import React from "react";
import Button from "./Button";
import styles from "./InvoicePaymentAuthorizationModal.module.sass";
import Modal from "./Modal";
import ProgressIndicator from "./ProgressIndicator";
import Text from "./Text";

InvoicePaymentAuthorizationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  status: PropTypes.oneOf(["Progress", "NG"]),
};

function InvoicePaymentAuthorizationModal({ open, onClose, status }) {
  return (
    <Modal show={open} showHeader onClose={onClose} type="Dialog">
      {status === "Progress" && (
        <div className={styles.progress}>
          <div className={styles.progressIndicator}>
            <ProgressIndicator />
          </div>
          <Text variant="h3" center>
            手続き中です
          </Text>
          <Text center>
            自動的に画面が切り替わります。そのまま、しばらくお待ちください。
          </Text>
        </div>
      )}
      {status === "NG" && (
        <>
          <div className={styles.errorIconWrap}>
            <img
              src="/assets/images/icon-error.png"
              alt="エラー"
              className={styles.errorIcon}
            />
          </div>
          <Text variant="h3" center>
            請求書払いは利用できません
          </Text>
          <Text center>
            このお支払い方法は現在ご利用いただけません。お手数ですが、別のお支払い方法をご利用ください。
          </Text>
          <div className={styles.action}>
            <Button fit onClick={onClose}>
              OK
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default InvoicePaymentAuthorizationModal;
