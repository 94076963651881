import React, { useEffect, useState } from 'react';
import { A, navigate } from 'hookrouter';
import { Alert } from 'react-bootstrap';
import { Backdrop } from '../../partials/backdrop';
import Button from "../../partials/Button";
import Input from "../../partials/Input";
import styles from './PasswordReset.module.sass';
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";

function PasswordReset(props) {
  const [email, setEmail] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [label, setLabel] = useState('送信中');
  const [progress, setProgress] = useState(10);
  const [isExpired, setIsExpired] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('expired')) {
      setIsExpired(true);
    }
  }, []);

  const resetPassword = async () => {
    setShowAlert(false);
    setProgress(10);
    setShowBackdrop(true);
    if (!email) {
      setShowAlert(true);
      setErrorMessage('メールアドレスを入力してください');
      setProgress(100);
      setShowBackdrop(false);
    } else {
      setProgress(60);
      //const data = await ValidateUser.checkIfUserExists({email:email});
      //if (data > 0) {
        setProgress(70);
        try {
          setProgress(80);
          await props.Auth.forgotPassword(email);
          setProgress(100);
          setTimeout(() => {
            setSent(true);
            //navigate('/password_reset/sent', false);
          }, 300);
        } catch(e) {
          if (e.code == "LimitExceededException") {
            setErrorMessage('もう少し時間を置いてからお試しください');
          } else {
            setErrorMessage('通信エラーが発生しました');
          }
          setProgress(100);
          setShowBackdrop(false);
          setShowAlert(true);
        }
      //} else {
      //  navigate('/password_reset/sent', false);
      //  setShowBackdrop(false);
      //}
    }
  };

  if (sent) {
    return (
      <>
        <Wrap>
          <A href="/">
            <div className={styles.headerLogo}><img src="/assets/images/word_logo.svg" alt="logo" width="79"/></div>
          </A>
          <div className={styles.passwordResetSentTitle}>
            <Text variant="h1" center>
              メールをご確認ください
            </Text>
          </div>
          <div className={styles.passwordResetSentImage}>
            <img src="/assets/images/icon-confirm-mail.png" alt="logo" width="150"/>
          </div>
          <div className={styles.passwordResetSentText} data-private><strong>{email}</strong>へメールを送信しました。メール内のURLをクリックしてください。</div>
        </Wrap>
      </>
    );
  } else {
    return (
      <>
        <Wrap>
          <A href="/">
            <div className={styles.headerLogo}><img src="/assets/images/word_logo.svg" alt="logo" width="79"/></div>
          </A>
          {/* パスワード再発行URLが期限切れの場合 */}
          {isExpired &&
            <div className={styles.errorText}>このURLは有効期限切れです。改めて、パスワード再発行手続きをお願いします。</div>
          }
          <div className={styles.passwordResetTitle}>
            <Text variant="h1" center>
              メールアドレスを
              <br />
              入力してください
            </Text>
          </div>
          {showBackdrop &&
            <Backdrop progress={progress} label={label} show={showBackdrop} />
          }
          <form id="form" onSubmit={(e) => {
            e.preventDefault();
            resetPassword();
          }}>
            <Input
              onChange={(e) => {setEmail(e.target.value)}} value={email}
              type="email"
              placeholder="taro@telchan.com"
              size="large"
            />
            {showAlert &&
              <Alert variant="danger" style={{borderRadius:0}}>{errorMessage}</Alert>
            }
            <div className={styles.action}>
              <Button fit primary>
                送信する
              </Button>
            </div>
            <div className={styles.passwordResetText}>登録済みのメールアドレスにパスワード再発行のURLを送信します。</div>
            <div className={styles.backPageButton}>
              <A href="/"><span className={styles.backPageButtonColor}>前の画面に戻る</span></A>
            </div>
          </form>
        </Wrap>
      </>
    );
  }
}

export default PasswordReset;

