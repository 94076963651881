import PropTypes from "prop-types";
import React from "react";
import Button from "./Button";
import Modal from "./Modal";
import styles from "./RouterSuspendingProcessResultModal.module.sass";
import Text from "./Text";

RouterSuspendingProcessResultModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  status: PropTypes.oneOf(["Success", "Failure"]),
  routerName: PropTypes.string,
};

function RouterSuspendingProcessResultModal({ open, onClose, status, routerName }) {
  return (
    <Modal show={open} onClose={onClose} type="Dialog">
      {status === "Success" && (
        <>
          <div className={styles.imageWrap}>
            <img
              src="/assets/images/icon-router-suspended-success.png"
              alt="成功"
              className={styles.successImage}
            />
          </div>
          <Text variant="h3" center className={styles.title}>
            {routerName}は<br />
            停止しました
          </Text>
          <Button fit primary onClick={onClose} className={styles.button}>
            機器の管理画面へ
          </Button>
        </>
      )}
      {status === "Failure" && (
        <>
          <div className={styles.imageWrap}>
            <img
              src="/assets/images/icon-error.png"
              alt="失敗"
              className={styles.errorIcon}
            />
          </div>
          <Text variant="h3" center className={styles.title}>
            {routerName}は<br />
            停止できませんでした
          </Text>
          <Text center className={styles.detailText}>もう一度、停止を行ってください</Text>
          <Button fit primary onClick={onClose} className={styles.button}>
            機器の管理画面へ
          </Button>
        </>
      )}
    </Modal>
  );
}

export default RouterSuspendingProcessResultModal;
