import React from 'react';
import PropTypes from 'prop-types';
import styles from './ItemCard.module.sass';
import { Delimiter } from '../delimiter';

function ItemCard({
  imageUrl,
  name,
  description,
  description2,
  price,
  historyPrice,
  monthlyFee,
  quantity,
  children
}) {
  return (
    <div className={styles.wrap}>
      <div className={styles.cardContents}>
        <div className={styles.cardContentLeft}>
          {imageUrl && (
            <img src={imageUrl} width={72} alt="機器の情報" />
          )}
        </div>
        <div className={styles.cardContentRight}>
          {name && (
            <div className={styles.cardTitle}>{name}</div>
          )}
          {description && (
            <div className={styles.cardDesctiption}>{description}</div>
          )}
          {description2 && (
            <div className={styles.cardDesctiption}>{description2}</div>
          )}
          {price != null && (
            <div className={styles.cardPrice}>本体：<span className={styles.cardPriceDevice}>¥{Delimiter(price)}</span></div>
          )}
          {historyPrice != null && (
            <div className={styles.cardPrice}>本体：<span >¥{Delimiter(historyPrice)}</span></div>
          )}
          {monthlyFee != null && (
            <div className={styles.cardPrice}>月額利用料：¥{Delimiter(monthlyFee)}</div>
          )}
          {quantity != null && (
            <div className={styles.cardQuantity}>数量：{quantity}個</div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

ItemCard.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  price: PropTypes.number,
  historyPrice: PropTypes.number,
  monthlyFee: PropTypes.number,
  quantity: PropTypes.number,
  children: PropTypes.node,
};

export default ItemCard
