import React, { useEffect, useState } from "react";
import axios from 'axios';
import { A, navigate } from "hookrouter";
import Button from "../../partials/Button";
import Input from "../../partials/Input";
import Modal from "../../partials/Modal";
import PhoneNumberAuthorization from "../../partials/PhoneNumberAuthorization";
import styles from "./Telephone.module.sass";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import { ValidateUser } from "../../partials/validateUser";
import { Config } from '../../config';

export const useDidMount = (func) => useEffect(() => { func() }, []);

function SignInTelephone(props) {
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [authPhoneNumber, setAuthPhoneNumber] = useState('');
  const [modalState, setModalState] = useState({
    phoneNumberAuthorization: false,
    phoneNumberAuthorizationHelper: false,
  });
  const [transaction, setTranscation] = useState({
    id: null,
    waitingUntil: null,
  });
  const [errorMessages, setErrorMessages] = useState({});

  const onload = async () => {
    const session = window.location.search;
    if (session) {
      const json = atob(session.replace('?session=', ''));
      const data = JSON.parse(json);
      if (data.sessionData) {
        signInBySessionData(data.sessionData);
      }
    }
  };

  useDidMount(onload);

  function signInBySessionData(sessionData) {
    Object.keys(sessionData).forEach(function (key) {
      localStorage.setItem(key, sessionData[key]);
    });
    navigate('/');
  }

  async function sleep(ms) {
    return new Promise(r => setTimeout(r, ms));
  }

  async function getSessionData() {
    if (!transaction.id || !transaction.waitingUntil) {
      return;
    }
    if (Date.now() > transaction.waitingUntil) {
      navigate('/signin/telephone/error');
      return;
    }

    const postData = {
      phone_number: userPhoneNumber,
      transaction_id: transaction.id
    };
    try {
      const url = Config.api_url_base2 + '/get_session_data';
      const res = await axios.post(url, JSON.stringify(postData));
      if (res.status == 200 && 'sessionData' in res.data) {
        signInBySessionData(res.data.sessionData); // セッショントークン取得
      }
    } catch (e) {
      // 認証エラー（電話番号が登録されていない、暗号化したパスワードが保存されていない等）
      if (e.response && e.response.status && 400 <= e.response.status && e.response.status < 500) {
        navigate('/signin/telephone/error');
      }
    }
  }

  const startPhoneNumberAuthorization = async (callback) => {
    const formattedUserPhoneNumber = ValidateUser.formatPhoneNumber(userPhoneNumber);
    const postData = {
      phone_number: formattedUserPhoneNumber
    };
    try {
      const res = await props.API.post('StartPhoneNumberAuthorization', '/start_auth', {body: postData});
      callback(res);
    } catch (e) {
      // setErrorMessages({name: [e]});
    }
  };

  const encodeHTMLForm = (data) => {
    const params = [];
    for (const [key, val] of Object.entries(data)) {
      params.push(encodeURIComponent(key) + '=' + encodeURIComponent(val));
    }
    return params.join('&').replace(/%20/g, '+');
  };

  const startAspPhoneNumberAuthorization = async (userPhoneNumber) => {
    // Create Transaction ID
    let transaction_id = null;
    try {
      const res = await axios.post(`${Config.api_url_base2}/start_ostiaries_asp`, {
        redirect: window.location.href,
        phone_number: userPhoneNumber
      }).then(res => {
        return res;
      }).catch(e => {
        throw e;
      });
      if (res.status == 200) {
        transaction_id = res.data.transaction_id;
      } else {
        navigate('/signin/telephone/error');
        return;
      }
    } catch (e) {
      navigate('/signin/telephone/error');
      return;
    }

    // jQuery Ajax Emulate
    window.$ = {
      ajax: (params) => {
        // API Request with XMLHttpRequest
        const xhr= new XMLHttpRequest();
        xhr.open('POST', params.url, false); // 同期モードで通信する
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(encodeHTMLForm(params.data));
        // Return Response
        return {
          done: (callback) => {
            if (xhr.status === 200) {
              callback(xhr.responseText, xhr.status, xhr);
            }
            return {
              fail: (callback) => {
                if (xhr.status !== 200) {
                  callback(xhr, xhr.status, {});
                }
              }
            }
          }
        };
      }
    };
    // ASP Settings
    const id = Config.ostiariesServiceId;
    const ok_url = encodeURI(`${Config.api_url_base2}/callback_ostiaries_asp?transaction_id=${transaction_id}`);
    const back_url = encodeURI(`${window.location.href}/error`);
    const asp_url = `https://asp.ostiaries.jp/api/2.0/s-callauth?id=${id}&ok_url=${ok_url}&back_url=${back_url}`;
    // Load ASP Script
    const exists = document.getElementById('ostiarie-asp-script');
    if (exists) {
      exists.remove();
    }
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.id = 'ostiarie-asp-script';
    script.type = 'text/javascript';
    script.src = 'src';
    script.src = asp_url;
    const onload = () => {
      if (!window.call_auth_run) {
        setTimeout(onload, 1000);
      } else {
        window.call_auth_run(userPhoneNumber);
      }
    };
    script.onload = onload;
    head.appendChild(script);
  };

  const submit = async () => {
    setErrorMessages({});
    let errors = {};

    errors.userPhoneNumber = ValidateUser.validatePhoneNumber(userPhoneNumber);
    let hasError = false;
    for (let key in errors) {
      if (errors[key].length > 0) {
        hasError = true;
      }
    }
    if (hasError) {
      setErrorMessages(errors);
      return;
    }

    if (Config.ostiariesMode === 'asp') {
      // ASP Mode
      await startAspPhoneNumberAuthorization(userPhoneNumber);
    } else if (Config.ostiariesMode === 'api') {
      // API Mode
      await startPhoneNumberAuthorization((res) => {
        setAuthPhoneNumber(res.result.authentic_number);
        setModalState({ ...modalState, phoneNumberAuthorization: true });
        // セッション切れ時刻の計算
        const waitingUntil = new Date();
        waitingUntil.setSeconds(waitingUntil.getSeconds() + res.result.waiting_seconds);
        setTranscation({id: res.result.transaction_id, waitingUntil: waitingUntil})
      });
    }
  };

  const closePhoneNumberAuthorizationModal = () => {
    setTranscation({id: null, waitingUntil: null})
    setModalState({ ...modalState, phoneNumberAuthorization: false });
  };

  useEffect(() => {
    const timer = setInterval(getSessionData, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [transaction]);

  return (
    <>
      <Wrap>
        <A href="/">
          <div className={styles.headerLogo}>
            <img src="/assets/images/word_logo.svg" alt="logo" width="79" />
          </div>
        </A>
        <div className={styles.inputPhoneNumberTitle}>
          <Text variant="h1">
            電話番号を
            <br />
            入力してください
          </Text>
          {Config.ostiariesMode === 'asp' &&
          <div  className={styles.detailText}>
            （外部サービスを利用します）<br />
            <a href="https://support.tel-chan.com/hc/ja/articles/28319109629849" target="_blank">
              ※詳しい情報
            </a>
          </div>
          }
        </div>
        <div>
          <Input
            type="tel"
            placeholder="09012345678"
            size="large"
            onChange={(e) => {setUserPhoneNumber(e.target.value);}}
            value={userPhoneNumber}
            errorMessages={errorMessages.userPhoneNumber}
          />
        </div>
        <div className={styles.buttonWrap}>
          <Button
            fit
            primary
            onClick={submit}
          >
            認証へ進む
          </Button>
          {Config.ostiariesMode === 'asp' &&
          <div className={styles.noticeText}>
            <p>（外部サイトへ遷移します）</p>
          </div>
          }
        </div>
        <div className={styles.backPageButton}>
          <A href="/" className={styles.backPageButtonColor}>
            前の画面に戻る
          </A>
        </div>
      </Wrap>

      {/* 電話番号の書式が正しく電話番号がデータベースに存在する時 */}
      <Modal
        show={modalState.phoneNumberAuthorization}
        type="Dialog"
        title="下記より電話してください"
      >
        <PhoneNumberAuthorization
          onClose={closePhoneNumberAuthorizationModal}
          onCall={()=> null}
          userPhoneNumber={userPhoneNumber}
          authPhoneNumber={authPhoneNumber}
        />
      </Modal>

      {/* 電話番号の書式が正しく電話番号がデータベースに存在しない時 */}
      <Modal
        show={modalState.phoneNumberAuthorizationHelper}
        type="Dialog"
        title="電話番号が未登録です"
      >
        <div className={styles.helperTitle}>
          ログインまたは新規登録後に電話番号をご登録ください
        </div>
        <div className={styles.helperImages}>
          <div className={styles.helperImage}>
            <img
              src="/assets/images/help-flow-first.png"
              alt="電話番号登録の操作"
              className={styles.helperFlowImage}
            />
            <div className={styles.helperFlow}>
              <div className={styles.flowNumber}>1</div>
              <div className={styles.flowText}>その他のお客様情報をタップ</div>
            </div>
          </div>
          <div className={styles.helperImage}>
            <img
              src="/assets/images/help-flow-secound.png"
              alt="電話番号登録の操作"
              className={styles.helperFlowImage}
            />
            <div className={styles.helperFlow}>
              <div className={styles.flowNumber}>2</div>
              <div className={styles.flowText}>変更をタップし入力する</div>
            </div>
          </div>
        </div>
        <div
          className={styles.backTextButton}
          onClick={() =>
            setModalState({
              ...modalState,
              phoneNumberAuthorizationHelper: false,
            })
          }
        >
          電話番号をもう一度入力する
        </div>
      </Modal>
    </>
  );
}

export default SignInTelephone;
