import React from "react";
import PropTypes from 'prop-types';
import { A } from "hookrouter";
import Button from "../partials/Button";
import styles from "./PhoneNumberAuthorization.module.sass";

function PhoneNumberAuthorization({onClose, onCall, authPhoneNumber, userPhoneNumber}) {
  return (
    <>
      <div className={styles.phoneNumberConfirmationTitle}>
        入力された番号が
        <br />
        ご本人様のものか確認します
      </div>
      <div className={styles.phoneNumberWrap}>
        <div className={styles.buttonWrap}>
          <a href={"tel:+81-" + authPhoneNumber.slice(1)}>
            <Button fit positive>
              電話する
            </Button>
          </a>
        </div>
        <div className={styles.phoneNumberAlertText}>
          120秒以内に電話してください
        </div>
      </div>
      <div className={styles.phoneNumberConfirmationText} data-private>
        <span>{userPhoneNumber}</span>
        の番号であることを確認します。通話料はお客様負担となります。
      </div>
      <div onClick={onClose} className={styles.closeTextButton}>
        電話番号をもう一度入力する
      </div>
    </>
  );
}

PhoneNumberAuthorization.propTypes = {
  onClose: PropTypes.func,
  onCall: PropTypes.func,
  authPhoneNumber: PropTypes.string,
  userPhoneNumber: PropTypes.string,
};

export default PhoneNumberAuthorization;
