import { CountUplink } from './CountUplink';
const CalcPrice = require('./calcPrice').CalcPrice;
const moment = require('moment');

const UPLINIK_FEE = CalcPrice.uplinkFee;

const BasePrice = {
  device_fee: (interval) => {
    return interval == 60 ? CalcPrice.deviceFee10 : CalcPrice.deviceFee60;
  },
};
export{BasePrice};

const CalcMonthlyFee = async (args) => {
  let uplinkCount;
  if (args.monitoringMethod == null || args.monitoringMethod == 'range') {
    uplinkCount = await CountUplink(args.start_at, args.end_at, args.interval);//, null, null, null, null, true);//, '2021-01-01', '2021-01-01', null, null, true);
  } else {
    uplinkCount = 0;
    await Promise.all(args.monitoringTimes.map(async (t) => {
      const baseTime = moment(Date.parse(`2021/01/01 ${t}:00`));
      const uC = await CountUplink(t, baseTime.add(5, 'minutes').format('HH:mm'), args.interval);//, null, null, null, null, true);//, '2021-01-01', '2021-01-01', null, null, true);
      uplinkCount = uplinkCount + parseInt(uC);
    }));
  }
  const per_device_fee = args.interval == 60 ? CalcPrice.deviceFee10 : CalcPrice.deviceFee60;
  let tmp_device_fee = 0;
  let real_device_fee = 0;
  await Promise.all(args.list.map((d) => {
    real_device_fee += (d.interval == 60 ? CalcPrice.deviceFee10 : CalcPrice.deviceFee60);
    tmp_device_fee += per_device_fee;
  }));
  const result = {
    charged_time: 0,//parseInt(charged_time),
    device_count: parseInt(args.list.length),
    current_price: parseInt(UPLINIK_FEE) * parseInt(uplinkCount) * parseInt(args.list.length),//parseInt(tmp_device_fee) * parseInt(charged_time),
    real_current_price: parseInt(UPLINIK_FEE) * parseInt(uplinkCount),//parseInt(real_device_fee) * parseInt(charged_time),
    monthly_fee: parseInt(per_device_fee),
    charged_hours: 0,//(3 * parseInt(charged_time))
    uplink_count: uplinkCount,
  };
  return result;
};

//function test() {
//  CalcMonthlyFee({
//    interval: 60,
//    devices: [1, 2, 3],
//    start_at: '02:00',
//    end_at: '03:00'
//  });
//  CalcMonthlyFee({
//    interval: 60,
//    devices: [],
//    start_at: '02:00',
//    end_at: '03:00'
//  });
//  CalcMonthlyFee({
//    interval: 60,
//    devices: [1, 2, 3],
//    start_at: '02:00',
//    end_at: '02:10'
//  });
//  CalcMonthlyFee({
//    interval: 60,
//    devices: [1, 2, 3],
//    start_at: '02:00',
//    end_at: '12:10'
//  });
//  CalcMonthlyFee({
//    interval: 60,
//    devices: [1, 2, 3],
//    start_at: '20:00',
//    end_at: '02:00'
//  });
//  CalcMonthlyFee({
//    interval: 60,
//    devices: [1, 2, 3],
//    start_at: '20:09',
//    end_at: '02:10'
//  });
//}
export{CalcMonthlyFee};
