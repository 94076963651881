import { mdiHelpCircle } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Delimiter } from '../delimiter';
import styles from './CheckoutSummary.module.sass';
import { CalcPrice } from "./calcPrice";

function CheckoutSummary({
  title,
  items,
  paymentMethod,
}) {

  const renderItems = (items) => {
    let rows = [];
    items.map((item, index) => {
      if (item.name === '手数料' && paymentMethod !== 'GMO掛け払い') {
        return;
      }
      rows.push(
        <div key={index} className={styles.checkoutArea}>
          <div className={styles.shippingFeeArea}>
            <div>{item.name}</div>
            <div>
              {item.name == "送料" &&
                <a href="https://tel-chan.zendesk.com/hc/ja/articles/900005186843-%E8%B3%AA%E5%95%8F-%E6%A9%9F%E5%99%A8%E3%81%AE%E9%80%81%E6%96%99%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84" target="_blank">
                  <div className={styles.shippingFeeDetailArea}>
                    <div className={styles.shippingFeeIcon}>
                      <Icon
                        size={1}
                        path={mdiHelpCircle}
                      />
                    </div>
                    <div className={styles.shippingFeeText}>送料について</div>
                  </div>
                </a>
              }
            </div>
          </div>
          <div>¥{Delimiter(item.price)}</div>
        </div>
      )
    })
    return rows;
  }

  const getTotalPrice = (items) => {
    let total = 0
    items.map((item) => {
      if (item.name === '手数料' && paymentMethod !== 'GMO掛け払い') {
        return;
      }
      total = total + item.price
    })
    return total;
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.checkoutDetail}>
        <div className={styles.checkoutTotalTitle}>{title}</div>
        <div className={styles.checkoutTotalPrice}>
          ¥{Delimiter(getTotalPrice(items))}<br />
        </div>
      </div>
      <div className={styles.checkoutTotalTax}>
        内消費税{CalcPrice.showTaxRate()}
        <span className={styles.checkoutTotalTaxDetail}>
          ¥{Delimiter(CalcPrice.taxFromTaxIncluded(getTotalPrice(items)))}
        </span>
      </div>
      {renderItems(items)}
    </div>
  )
}

CheckoutSummary.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

export default CheckoutSummary
