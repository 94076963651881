const LineConfirmUser = async (args) => {
  try {
    const session = await args.Auth.currentSession();
    const response = await args.API.post('ConfirmLineUser', '/line_confirm', {
      headers: {Authorization: session.idToken.jwtToken}, 
      body: {token: args.token},
    });
  } catch(e) {
    // do nothing (should be fixed on the server-side)
  }
};
export{LineConfirmUser};
