import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Field from "./Field";
import Input from "./Input";
import PhoneNumberAuthorization from "./PhoneNumberAuthorization";
import styles from "./UpdatePhoneNumber.module.sass";

function UpdatePhoneNumber({ onClose }) {
  const [page, setPage] = useState("inputPhoneNumber");
  return (
    <>
      {page === "inputPhoneNumber" && (
        <div>
          <Field label="電話番号">
            <Input type="tel" maxLength="20" onChange={() => null} />
          </Field>
          <div className={styles.noticeMessage}>通知先も変更になります。</div>
          <div className={styles.buttons}>
            <Button fit primary onClick={() => setPage("confirmPhoneNumber")}>
              次へ
            </Button>
            <Button fit onClick={onClose}>
              閉じる
            </Button>
          </div>
        </div>
      )}
      {page === "confirmPhoneNumber" && (
        <PhoneNumberAuthorization
          onCall={() => null}
          onClose={() => setPage("inputPhoneNumber")}
          authPhoneNumber="090-1234-5678"
          userPhoneNumber="09012345678"
        />
      )}
    </>
  );
}

UpdatePhoneNumber.propTypes = {
  onClose: PropTypes.func,
};

export default UpdatePhoneNumber;
