import PropTypes from "prop-types";
import React from "react";
import Button from "./Button";
import Modal from "./Modal";
import Text from "./Text";
import styles from "./UplinkNoteModal.module.sass";

UplinkNoteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function UplinkNoteModal({ open, onClose }) {
  return (
    <Modal
      show={open}
      type="Dialog"
      title="監視設定の時間帯以外でも、Uplinkがある場合があります。"
    >
      <Text>
        以下のような場合
        <br />
        ・手動更新
        <br />
        ・故障の有無の定期チェック
        <br />
        ・監視設定の予備動作
      </Text>
      <Text className={styles.freePriceTextWrap}>
        <span className={styles.freePriceText}>
          監視設定時間外のUplinkに関しては、料金は発生しません
        </span>
        のでご安心ください。監視料金は監視設定に応じて課金されます。
      </Text>
      <Button fit onClick={onClose} className={styles.button}>
        閉じる
      </Button>
    </Modal>
  );
}

export default UplinkNoteModal;
