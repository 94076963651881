const CheckUser = async (Auth, refresh=false) => {
  try {
    let user;
    if (refresh) {
      user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    } else {
      user = await Auth.currentUserInfo();
    }
    if (Object.keys(user).length <= 0) {
      return null;
    } else {
      return user;
    }
  } catch(e) {
    return null;
  }
};

export{CheckUser};
