import { mdiAlarm, mdiBellPlus } from "@mdi/js";
import Icon from "@mdi/react";
import cx from "clsx";
import { navigate } from "hookrouter";
import React, { useEffect, useState } from "react";
import AuthorizedHeader from "../../partials/AuthorizedHeader";
import Blank from "../../partials/Blank";
import Button from "../../partials/Button";
import { CheckUser } from "../../partials/checkUser";
import DeviceIcon from "../../partials/DeviceIcon";
import Footer from "../../partials/Footer";
import Modal from "../../partials/Modal";
import PageTitle from "../../partials/PageTitle";
import ProgressIndicator from "../../partials/ProgressIndicator";
import Wrap from "../../partials/Wrap";
import styles from "./index.module.sass";
import NotificationSettingsDetail from "./NotificationSettingsDetail";

function NotificationSettings(props) {
  const [user, setUser] = useState({});
  const [list, setList] = useState([]);
  const [settings, setSettings] = useState(null);
  const [settingsList, setSettingsList] = useState([]);
  const [postfix, setPostfix] = useState("℃");
  const [loading, setLoading] = useState(true);
  const [routers, setRouters] = useState([]);

  useEffect(() => {
    onload();
    return () => {};
  }, []);

  useEffect(() => {
    if (settingsList.length > 0) {
      setLoading(false);
    }
  }, [settingsList]);

  useEffect(() => {
    if (settings) {
      openNotificationDetailModal();
    } else {
      closeNotificationDetailModal();
    }
  }, [settings]);

  const getPostfix = (name) => {
    if (!name) {
      return "℃";
    }
    switch (name) {
      case "温度":
      case "temperature":
        return "℃";
        break;
      case "照度":
      case "illuminance":
        return "lx";
        break;
      case "湿度":
      case "humidity":
      case "moisture":
        return "%";
      case "水位":
      case "watermark":
        return "";
        break;
    }
  };

  const getOperatorName = (name) => {
    return name == "more" ? "以上" : "以下";
  };

  const getSensorTypeName = (type) => {
    switch (type) {
      case "temperature":
        return "温度";
      case "moisture":
      case "humidity":
        return "湿度";
      case "illuminance":
        return "照度";
      case "waterlevel":
        return "水位";
    }
  };

  const NotificationIcon = ({ type, notification_type }) => {
    if (notification_type == "immediate") {
      switch (type) {
        case "temperature":
          return (
            <img
              src="/assets/images/icon-values-temperature-immediate.svg"
              height="26"
              alt="温度アイコン"
            />
          );
        case "moisture":
        case "humidity":
          return (
            <img
              src="/assets/images/icon-values-moisture-immediate.svg"
              height="26"
              alt="湿度アイコン"
            />
          );
        case "illuminance":
          return (
            <img
              src="/assets/images/icon-values-illuminance-immediate.svg"
              height="26"
              alt="照度アイコン"
            />
          );
        case "waterlevel":
          return (
            <img
              src="/assets/images/icon-values-waterlevel-immediate.svg"
              height="26"
              alt="水位"
            />
          );
      }
    }
    if (notification_type == "delayed") {
      switch (type) {
        case "temperature":
          return (
            <img
              src="/assets/images/icon-values-temperature-specified-time.svg"
              height="26"
              alt="温度アイコン"
            />
          );
        case "moisture":
        case "humidity":
          return (
            <img
              src="/assets/images/icon-values-moisture-specified-time.svg"
              height="26"
              alt="湿度アイコン"
            />
          );
        case "illuminance":
          return (
            <img
              src="/assets/images/icon-values-illuminance-specified-time.svg"
              height="26"
              alt="照度アイコン"
            />
          );
        case "waterlevel":
          return (
            <img
              src="/assets/images/icon-values-waterlevel-specified-time.svg"
              height="26"
              alt="水位"
            />
          );
      }
    }
    return null;
  };

  const fetchNotifications = async () => {
    setLoading(true);
    const session = await props.Auth.currentSession();
    const settings = await props.API.get(
      "NotificationSettings",
      "/notification_settings",
      {
        headers: { Authorization: session.idToken.jwtToken },
      }
    );
    const list = settings
      .filter((s) => {
        return s.notification_status != 0 && s.settings;
      })
      .sort((a, b) => {
        if (a.notification_status > b.notification_status) {
          return -1;
        }
        if (a.notification_status < b.notification_status) {
          return 1;
        }
        if (a.notification_type > b.notification_type) {
          return -1;
        }
        if (a.notification_type < b.notification_type) {
          return 1;
        }
        if (a.start_at > b.start_at) {
          return 1;
        }
        if (a.start_at < b.start_at) {
          return -1;
        }
      });
    setSettingsList(list);
    if (list.length <= 0) {
      setLoading(false);
    }
  };

  const onload = async () => {
    let user = await CheckUser(props.Auth, true);
    if (user) {
      setUser(user);
      await fetchRouterList();
      await fetchNotifications();
    } else {
      navigate("/");
    }
  };
  const initSettings = () => {
    return {};
  };

  const [
    isShowingNotificationDetailModal,
    setIsShowingNotificationDetailModal,
  ] = useState(false);

  const openNotificationDetailModal = async () => {
    let user = await CheckUser(props.Auth, true);
    setUser(user);
    document.body.classList.add("showing-modal");
    setIsShowingNotificationDetailModal(true);
  };

  const closeNotificationDetailModal = () => {
    document.body.classList.remove("showing-modal");
    setIsShowingNotificationDetailModal(false);
  };

  const getStyle = (type, status) => {
    if (status == 0) {
      return styles.grayScale;
    }
    if (type == "immediate") {
      return styles.immediate;
    } else if (type == "delayed") {
      return styles.specifiedTime;
    }
  };

  const fetchRouterList = async () => {
    const session = await props.Auth.currentSession();
    const devices = await props.API.get("DeviceList", "/devices", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    if (devices && devices.routers) {
      const activeRouters = devices.routers.filter((r) => {
        return r.active;
      });
      setRouters(activeRouters);
    }
  };

  return (
    <div className={styles.background}>
      <AuthorizedHeader current="notification_settings" />
      <Wrap>
        {loading && <ProgressIndicator gutter />}

        {/* リストがない場合 */}
        {!loading && settingsList.length == 0 && (
          <Blank
            images={
              <img
                src="/assets/images/fig-blank-notification-settings.png"
                width="250"
              />
            }
            actions={
              <Button
                primary
                onClick={() => {
                  setSettings(initSettings());
                }}
              >
                通知を設定する
              </Button>
            }
          >
            センサーに異常があった時
            <br />
            電話などでお知らせします
          </Blank>
        )}

        {/* リストがある場合 */}
        {!loading && settingsList.length > 0 && (
          <>
            <PageTitle title="通知" />
            {settingsList.map((settings) => {
              const s = settings.settings;
              const targets = s.targets;
              const conditions = s.conditions || [
                {
                  sensor_type: s.sensor_type,
                  sensor_value: s.sensor_value,
                  operator: s.operator,
                },
              ];
              let str = [
                { name: "メール", status: targets.email.status },
                { name: "電話", status: targets.phone.status },
                { name: "SMS", status: targets.sms.status },
              ]
                .filter((n) => {
                  return n.status;
                })
                .map((s) => {
                  return s.name;
                })
                .join("/");
              if (str) {
                str = `${str}で`;
              }
              return (
                <div
                  key={settings.notification_setting_id}
                  onClick={() => {
                    setSettings(settings);
                  }}
                  className={cx(
                    styles.notification,
                    getStyle(
                      settings.notification_type,
                      settings.notification_status
                    )
                  )}
                >
                  {settings.notification_type == "delayed" && (
                    <div className={styles.notificationTag}>
                      <Icon size={0.9} path={mdiAlarm} />
                      <div className={styles.text}>
                        毎日{settings.send_at}頃に通知
                      </div>
                    </div>
                  )}
                  <div className={styles.notificationText}>
                    {(settings.monitoringMethod == null ||
                      settings.monitoringMethod == "range") && (
                      <span>
                        {settings.start_at} 〜 {settings.end_at} の間は
                        {s.alert_interval}分ごとに確認
                      </span>
                    )}
                    {settings.monitoringMethod == "fixed_times" && (
                      <span>
                        {settings.monitoringTimes
                          .join("分、")
                          .replace(/:/g, "時")}
                        分に確認
                      </span>
                    )}
                    {settings.notification_type == "immediate" && (
                      <>し異常があれば、すぐに{`${str}`}通知する</>
                    )}
                    {settings.notification_type == "delayed" && (
                      <>し異常があれば、あとで{`${str}`}通知する</>
                    )}
                    {conditions.map((cond, i) => {
                      return (
                        <div
                          key={`sensor-${i}`}
                          className={styles.notificationIconWrap}
                        >
                          <NotificationIcon
                            type={cond.sensor_type}
                            notification_type={settings.notification_type}
                          />
                          <div key={`cond_${i}`} className={styles.value}>
                            {getSensorTypeName(cond.sensor_type)}が
                            {cond.sensor_value}
                            {getPostfix(cond.sensor_type)}
                            {getOperatorName(cond.operator)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {settings.notification_type == "immediate" && settings.settings.alert_persist === 0 && (
                    <div className={styles.notificationSubText}>
                      もし同じ異常が続く場合は、毎回通知する。
                    </div>
                  )}
                  {settings.notification_type == "immediate" && (settings.settings.alert_persist === undefined || settings.settings.alert_persist !== 0) && (
                    <div className={styles.notificationSubText}>
                      もし同じ異常が続く場合は、1時間通知しない。
                    </div>
                  )}
                  <div className={styles.notificationInformation}>
                    <div className={styles.deviceIcon}>
                      <DeviceIcon type="sensor" size={42} />
                    </div>
                    <div className={styles.deviceCount}>
                      {settings.devices.length}個の子機センサー
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              onClick={() => {
                setSettings(initSettings());
              }}
              className={styles.addNotificationButton}
            >
              <div className={styles.icon}>
                <Icon size={1.5} path={mdiBellPlus} />
              </div>
              <div className={styles.text}>新しい通知を作成</div>
            </div>
          </>
        )}
      </Wrap>

      <Footer />

      <Modal show={isShowingNotificationDetailModal}>
        <NotificationSettingsDetail
          closeNotificationDetailModal={async () => {
            setSettings(null);
            await fetchNotifications();
          }}
          settings={settings}
          {...props}
          list={list}
          settingsList={settingsList}
          routers={routers}
        />
      </Modal>
    </div>
  );
}

export default NotificationSettings;
