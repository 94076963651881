import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Box.module.sass';

function Box({ children, basic, notPadding, gutterBottom }){
  return (
    <div className={cx(
      styles.wrap,
      basic ? styles.basic : '',
      notPadding ? styles.notPadding : '',
      gutterBottom ? styles.gutterBottom : ''
    )}>
      {children}
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node,
  basic: PropTypes.bool,
  notPadding: PropTypes.bool,
  gutterBottom: PropTypes.bool
}

export default Box