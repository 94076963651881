import axios from 'axios';
import { Config } from '../config';

const baseUrl = Config.api_url_base + '/counter';

const AtomicCounter = {
  get: async () => {
    const response = await axios.get(baseUrl + '/v1-orders');
    return response.data.Attributes.atomic_counter;
  },
};

export{AtomicCounter};
