import React, { useEffect } from 'react'
import { A, navigate } from 'hookrouter'
import { CheckUser } from '../partials/checkUser'

function ConfirmMail(props) {
  useEffect(() => {
    onload();
  }, []);

  const onload = async () => {
    const url = new URL(window.location.href);
    const user = await CheckUser(props.Auth);
    const query = new URLSearchParams(url.searchParams);
    const pathName = url.pathname;
    const token = pathName.split('/')[2];
    if (user) {
      //tokenをAPIサーバに問い合わせる
      setTimeout(() => {
        window.location.href = `${url.protocol}//${url.host}/dashboard?mail=${token}`;
      }, 300);
    } else {
      //tokenを持ってログイン画面に
      setTimeout(() => {
        window.location.href = `${url.protocol}//${url.host}/?mail=${token}`;
      }, 300);
    }
  };

  return <div/>
}

export{ConfirmMail};
