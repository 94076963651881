import PropTypes from "prop-types";
import React from "react";
import Modal from "./Modal";
import Text from "./Text";
import Wrap from "./Wrap";

InvoicePaymentGuideModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function InvoicePaymentGuideModal({ open, onClose }) {
  return (
    <Modal
      show={open}
      title="ご利用可能なお振込先"
      showHeader
      onClose={onClose}
    >
      <Wrap>
        <Text variant="h2" gutterBottom>
          ご利用可能なお振込先
        </Text>
        <Text>
          <strong>銀行振込</strong>
          <br />
          銀行ATM ※手数料はお客様負担です
          <br />
          <br />
          <strong>スマホ決済</strong>
          <br />
          ゆうちょPay、LINE Pay、PayB、こいPay、YOKA!Pay
          <br />
          <br />
          <strong>コンビニエンスストアなど</strong>
          <br />
          ファミリーマート、ローソン、セイコーマート、デイリーヤマザキ、ニューヤマザキデイリーストア、ヤマザキデイリーストア、ヤマザキスペシャルパートナーシップ、生活彩家、ポプラ、くらしハウス、スリーエイト、セブン-イレブン、ミニストップ、ハマナスクラブ、MHK設置店、ウエルシア（収納窓口サービス取り扱店のみ）
        </Text>
      </Wrap>
    </Modal>
  );
}

export default InvoicePaymentGuideModal;
