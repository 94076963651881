import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import styles from "./RssiStatus.module.sass";

function RssiStatus({ rssi, type, showLabel, negative, labelOnly, className }) {
  const RssiIconPath = () => {
    if (type === "device") {
      if (rssi === null) {
        return {
          src: "/assets/images/icon-ble/icon-ble-warning.svg",
          negativeSrc: "/assets/images/icon-ble/icon-ble-warning-white.svg",
          label: "なし",
        };
      } else if (rssi > -78) {
        return {
          src: "/assets/images/icon-ble/icon-ble-lv4.svg",
          negativeSrc: "/assets/images/icon-ble/icon-ble-lv4-white.svg",
          label: "安定",
        };
      } else if (rssi > -84) {
        return {
          src: "/assets/images/icon-ble/icon-ble-lv3.svg",
          negativeSrc: "/assets/images/icon-ble/icon-ble-lv3-white.svg",
          label: "安定",
        };
      } else if (rssi > -90) {
        return {
          src: "/assets/images/icon-ble/icon-ble-lv2.svg",
          negativeSrc: "/assets/images/icon-ble/icon-ble-lv2-white.svg",
          label: "弱い",
        };
      } else if (rssi <= -90) {
        return {
          src: "/assets/images/icon-ble/icon-ble-lv1.svg",
          negativeSrc: "/assets/images/icon-ble/icon-ble-lv1-white.svg",
          label: "不安定",
        };
      } else {
        return {
          src: "/assets/images/icon-ble/icon-ble-warning.svg",
          negativeSrc: "/assets/images/icon-ble/icon-ble-warning-white.svg",
          label: "なし",
        };
      }
    } else {
      //router
      if (rssi === null) {
        return {
          src: "/assets/images/icon-lte/icon-lte-warning.svg",
          label: "なし",
        };
      } else if (rssi > -70) {
        return {
          src: "/assets/images/icon-lte/icon-lte-lv4.svg",
          label: "安定",
        };
      } else if (rssi > -80) {
        return {
          src: "/assets/images/icon-lte/icon-lte-lv3.svg",
          label: "安定",
        };
      } else if (rssi > -90) {
        return {
          src: "/assets/images/icon-lte/icon-lte-lv2.svg",
          label: "弱い",
        };
      } else if (rssi <= -90) {
        return {
          src: "/assets/images/icon-lte/icon-lte-lv1.svg",
          label: "不安定",
        };
      } else {
        return {
          src: "/assets/images/icon-lte/icon-lte-warning.svg",
          label: "",
        };
      }
    }
  };

  if (labelOnly) {
    return RssiIconPath().label;
  }

  return (
    <div className={cx(styles.icon, className)}>
      <img src={negative ? RssiIconPath().negativeSrc : RssiIconPath().src} />
      {showLabel && (
        <div className={styles.label}>電波：{RssiIconPath().label}</div>
      )}
    </div>
  );
}

RssiStatus.propTypes = {
  showLabel: PropTypes.bool,
  rssi: PropTypes.number,
  type: PropTypes.string,
  negative: PropTypes.bool,
  className: PropTypes.string,
};

RssiStatus.defaultProps = {
  showLabel: false,
  type: "device",
  negative: false,
};

export default RssiStatus;
