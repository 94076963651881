import React, { useEffect } from 'react';
import { navigate } from "hookrouter";
import ProgressIndicator from '../../partials/ProgressIndicator'; 

function Ad(props) {

  useEffect(() => {
    onload();
  }, []);

  const onload = async () => {
    const url = new URL(window.location.href);
    // FIXME パラメータにより遷移先を変更する場合は以下のコードを
    // 参照して変更すること
    const source = url.searchParams.get('utm_source') || '';
    const medium = url.searchParams.get('utm_medium') || '';
    const campaign = url.searchParams.get('utm_campaign') || '';

    const redirectTo = '/signup';

    setTimeout(() => {
      navigate(redirectTo);
    }, 300);
  };

  return (<>
    <div style={{textAlign:'center'}}>
      <div style={{position:'absolute',top:'50%',bottom:'50%',left:'50%',right:'50%'}}>
        <ProgressIndicator />
      </div>
    </div>
  </>)
}

export default Ad;
