import React from "react";
import { A } from "hookrouter";
import Button from "../../partials/Button";
import Text from "../../partials/Text";
import Wrap from '../../partials/Wrap';
import styles from "./TelephoneError.module.sass";

function SignInTelephoneError() {

  return (
    <>
    <Wrap>
      <A href="/">
        <div className={styles.headerLogo}><img src="/assets/images/word_logo.svg" alt="logo" width="79"/></div>
      </A>
      <div className={styles.errorIconWrap}>
        <img src="/assets/images/icon-error.png" alt="エラー" className={styles.errorIcon} />
      </div>
      <Text variant="h2" center gutterTop>
        ご本人様確認ができません
      </Text>
      {/* 120秒を超えて電話した時 */}
      {false &&(
        <div className={styles.errorText}>
          指定の時間内に着信が確認できませんでした。再度やりなおしてください。
        </div>
      )}
      {/* 電話した番号が入力した電話番号と異なる場合 */}
      {true &&(
        <div className={styles.errorText}>
          入力した電話番号と一致しません。電話番号を再度入力してください。
        </div>
      )}
      <div className={styles.buttonWrap}>
        <A href="/signin/telephone">
          <Button fit primary>電話番号の入力に戻る</Button>
        </A>
      </div>
    </Wrap>
    </>
  );
}

export default SignInTelephoneError;
