import cx from "clsx";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import ButtonClose from "./ButtonClose";
import styles from "./Modal.module.sass";

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = props.onClick != null ? props.onClick : () => {};
  }
  portalElement = null;

  componentDidMount() {
    const doc = document;
    this.portalElement = doc.createElement("div");
    this.portalElement.setAttribute("class", styles.wrap);
    doc.body.appendChild(this.portalElement);
    this.forceUpdate();
  }

  componentWillUnmount() {
    this.portalElement.parentNode.removeChild(this.portalElement);
  }

  render() {
    const { show, showHeader, type, onClose } = this.props;
    let { children, title } = this.props;

    if (!this.portalElement) {
      return null;
    }

    return ReactDOM.createPortal(
      <CSSTransition
        in={show}
        timeout={600}
        classNames={{
          enter: styles.modalEnter,
          enterActive: styles.modalEnterActive,
          exit: styles.modalExit,
          exitActive: styles.modalExitActive,
        }}
        unmountOnExit
      >
        <div
          onClick={this.onClick}
          className={cx(
            styles.body,
            show ? styles.show : "",
            type === "AwaitingResult" ? styles.center : "",
            type === "Dialog" ? styles.center : ""
          )}
        >
          <div className={styles.background} />

          {/* 通常のモーダル */}
          {type === "Modal" && (
            <div className={styles.window}>
              {showHeader ? (
                <>
                  <div className={styles.header}>
                    {onClose && (
                      <div className={styles.headerCloseButton}>
                        <ButtonClose onClick={onClose} />
                      </div>
                    )}
                  </div>

                  <div className={styles.bodyWithHeader}>{children}</div>
                </>
              ) : (
                <div className={styles.contents}>{children}</div>
              )}
            </div>
          )}

          {/* ダイアログ */}
          {type === "Dialog" && (
            <div className={styles.dialogWindow}>
              {title && <div className={styles.dialogTitle}>{title}</div>}
              {children}
            </div>
          )}

          {/* 結果表示待ち */}
          {type === "AwaitingResult" && (
            <div className={styles.alertWindow}>
              <div className={styles.alertImage}>
                <div className={styles.progressIndicator}>
                  <div className={styles.loader}>
                    <svg
                      className={styles.spinner}
                      width="65px"
                      height="65px"
                      viewBox="0 0 66 66"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className={styles.circle}
                        fill="none"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                        cx="33"
                        cy="33"
                        r="30"
                      ></circle>
                    </svg>
                  </div>
                </div>
              </div>
              <div className={styles.alertTitle}>設定を反映中です</div>
              <div className={styles.alertDescription}>
                自動的に画面が切り替わります。
                <br />
                しばらくお待ちください。
              </div>
            </div>
          )}

          {/* フルスクリーン */}
          {type === "FullScreen" && (
            <div className={styles.fullScreenWindow}>{children}</div>
          )}
        </div>
      </CSSTransition>,
      this.portalElement
    );
  }
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  type: PropTypes.oneOf(["Modal", "AwaitingResult", "Dialog", "FullScreen"]),
};

Modal.defaultProps = {
  showHeader: false,
  type: "Modal",
};

export default Modal;
