import cx from "clsx";
import { navigate } from 'hookrouter';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Config } from "../../config";
import { Delimiter } from "../../delimiter";
import AuthorizedHeader from "../../partials/AuthorizedHeader";
import Box from "../../partials/Box";
import Footer from "../../partials/Footer";
import ItemCard from "../../partials/ItemCard";
import PageTitle from "../../partials/PageTitle";
import Wrap from "../../partials/Wrap";
import { CalcPrice } from "../../partials/calcPrice";
import { CheckUser } from '../../partials/checkUser';
import styles from "./details.module.sass";
export const useDidMount = (func) => useEffect(() => { func() }, []);

function OrderHistoryDetails(props) {
  const [user, setUser] = useState({});
  const [showTracking, setShowTracking] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const date = moment(props.history.timestamp).format("YYYY年 MM月 DD日");
  const phone = props.history.phoneNumber ? parsePhoneNumberFromString(props.history.phoneNumber, "JP") : parsePhoneNumberFromString(props.history.billingAddressPhoneNumber, "JP");
  const billingPhone = props.history.billingAddressPhoneNumber
    ? parsePhoneNumberFromString(props.history.billingAddressPhoneNumber, "JP")
    : phone;

  const onload = async () => {
    console.log(props);
    let user = await CheckUser(props.Auth);
    console.log(user);
    if (user) {
      setUser(user);
    } else {
      navigate('/', false);
    }
  }

  useEffect(() => {
    if (props.history.paymentMethod) {
      if (props.history.paymentMethod == 'GMO掛け払い') {
        setPaymentMethod(props.history.paymentMethod);
      } else if (props.history.paymentMethod == 'credit') {
        setPaymentMethod('クレジットカード');
      } else {
        setPaymentMethod(`キャリア決済 ${props.history.carrier}`);
      }
    }
    if (props.history.sent_at) {
      if (moment().isBefore(moment(props.history.sent_at).add(2, "weeks"))) {
        setShowTracking(true);
      }
    }
  }, []);

  useDidMount(async () => {
    onload();
  });

  if (!user.attributes) {
    return <div />;
  }

  return (
    <>
      <AuthorizedHeader
        type="Close"
        backURL="/order_history"
        onClose={() => {
          props.setHistory(null);
        }}
      />
      <Wrap>
        <PageTitle title="ご注文内容" />
        <div className={styles.orderWrap}>
          <div className={styles.orderLabel}>注文日</div>
          <div className={styles.orderValue}>{date}</div>
        </div>
        <div className={styles.orderWrap}>
          <div className={styles.orderLabel}>注文番号</div>
          <div className={styles.orderValue}>
            {props.history.historyId || props.history.order_id}
          </div>
        </div>

        {/* 配送済みの場合 */}
        {props.history.delivered && (
          <div className={styles.orderStatus}>
            {moment(props.history.sent_at).format("YYYY年 MM月 DD日")} 発送済み
            {showTracking && (
              <>
                <br />
                配送業者：
                {props.history.distributor
                  ? props.history.distributor
                  : "佐川急便"}
                <br />
                配送状況：
                <a
                  href={
                    props.history.distributor == "佐川急便" ||
                    props.history.distributor == null
                      ? `http://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=${props.history.tracking_id}`
                      : `https://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=${props.history.tracking_id}`
                  }
                  target="_blank"
                >
                  {props.history.tracking_id}
                </a>
              </>
            )}
          </div>
        )}

        {/* 配送前の場合 */}
        {!props.history.delivered && (
          <div className={cx(styles.orderStatus, styles.ordered)}>
            準備中
            <br />
            お届けまで、ご注文日から2週間前後かかります
          </div>
        )}

        <div className={styles.paymentWrap}>
          <Box basic gutterBottom>
            <div className={styles.reviewDeviceArea}>
              <div className={styles.orderItemsTitle}>ご購入製品</div>
            </div>
            <div className={styles.orderItem}>
              <ItemCard
                imageUrl="/assets/images/icon-router-blue.png"
                name="親機ルーター"
                description="Braveridge BraveROUTE"
                historyPrice={props.history.routerPrice || 0}
                quantity={props.history.routerCount || 0}
              />
            </div>
            <div className={styles.orderItem}>
              <ItemCard
                imageUrl="/assets/images/icon-sensor.png"
                name="子機センサー（温度・湿度・照度）"
                description="Braveridge TETRA 防水"
                historyPrice={props.history.devicePrice || 0}
                quantity={props.history.deviceCount || 0}
              />
            </div>
            <div className={styles.divider} />
            <div className={styles.priceWrap}>
              <div className={styles.priceLabel}>親機ルーター端末代金</div>
              <div className={styles.priceValue}>
                ¥{Delimiter(props.history.routerPrice || 0)}
              </div>
            </div>
            <div className={styles.priceWrap}>
              <div className={styles.priceLabel}>子機センサー端末代金 ×2</div>
              <div className={styles.priceValue}>
                ¥{Delimiter(props.history.devicePrice || 0)}
              </div>
            </div>
            <div className={styles.priceWrap}>
              <div className={styles.priceLabel}>配送料</div>
              <div className={styles.priceValue}>
                ¥{Delimiter(props.history.shipmentFee)}
              </div>
            </div>
            {props.history.paymentMethod == 'GMO掛け払い' &&
            <div className={styles.priceWrap}>
              <div className={styles.priceLabel}>手数料</div>
              <div className={styles.priceValue}>
                ¥{Delimiter(props.history.gmoChargeWithTax || CalcPrice.gmoChargeWithTax)}
              </div>
            </div>
            }
            <div className={styles.priceWrap}>
              <div className={styles.totalPriceLabel}>合計</div>
              <div className={styles.totalPriceValue}>
                ¥{Delimiter(props.history.amount)}
              </div>
            </div>
            <div className={styles.totalPriceTax}>内消費税{CalcPrice.showTaxRate()}
              <span className={styles.totalPriceTaxDetail}>¥{Delimiter(CalcPrice.taxFromTaxIncluded(props.history.amount))}</span>
            </div>
          </Box>
          <Box basic gutterBottom>
            <div className={styles.customerDetailsTitle}>お支払い方法</div>
            <div className={styles.customerDetailsValue}>
              {paymentMethod}
            </div>
          </Box>
          <Box basic gutterBottom>
            <div className={styles.customerDetailsTitle}>ご請求先</div>
            <div className={styles.customerDetailsWrap} data-private>
              <div className={styles.customerDetailsValue}>
                {props.history.billingName} 様
              </div>
              <div className={styles.customerDetailsValue}>
                〒{props.history.billingZipcode}
              </div>
              <div className={styles.customerDetailsValue}>
                {props.history.billingAddress1} {props.history.billingAddress2}
                {props.history.billingAddress3}
              </div>
              <div className={styles.customerDetailsValue}>
                {billingPhone.formatNational()}
              </div>
            </div>
          </Box>
          <Box basic gutterBottom>
            <div className={styles.customerDetailsTitle}>ご請求元</div>
            <div className={styles.customerDetailsWrap} data-private>
              <div className={styles.customerDetailsValue}>
                {Config.companyName}
              </div>
              <div className={styles.customerDetailsValue}>
                登録番号 {Config.companyNumber}
              </div>
            </div>
          </Box>
          <Box basic gutterBottom>
            <div className={styles.customerDetailsTitle}>お届け先</div>
            <div className={styles.customerDetailsWrap} data-private>
              <div className={styles.customerDetailsValue}>
                {props.history.shippingName || props.history.name} 様
              </div>
              <div className={styles.customerDetailsValue}>
                〒{props.history.shippingZipcode || props.history.zipcode}
              </div>
              <div className={styles.customerDetailsValue}>
                {props.history.shippingAddress1 || props.history.address1}{" "}
                {props.history.shippingAddress2 || props.history.address2}
                {props.history.shippingAddress3 || props.history.address3}
              </div>
              <div className={styles.customerDetailsValue}>
                {phone.formatNational()}
              </div>
            </div>
          </Box>
          <div className={styles.customerDetailsText}>
            ご注文からお届けまで2週間ほどお時間がかかります。商品が届かない場合は、
            <a className={styles.contactLink} target="_blank" href="/contact">
              お問い合わせ
            </a>
            よりご連絡ください。
          </div>
        </div>
      </Wrap>
      <Footer />
    </>
  );
}

export default OrderHistoryDetails;
