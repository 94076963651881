import React from "react";
import PropTypes from "prop-types";
import styles from "./DashboardSettings.module.sass";
import Switch from "./Switch";

function DashboardSettings({ humidityDeficitChecked, dewPointChecked, onHumidityDeficitClick, onDewPointClick }) {
  return (
    <div className={styles.component}>
      <div className={styles.settings}>
        <div className={styles.setting} onClick={onHumidityDeficitClick}>
          <div className={styles.label}>飽差を表示</div>
          <div className={styles.control}>
            <Switch checked={humidityDeficitChecked} />
          </div>
        </div>
        <div className={styles.setting} onClick={onDewPointClick}>
          <div className={styles.label}>露点を表示</div>
          <div className={styles.control}>
            <Switch checked={dewPointChecked} />
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardSettings.propTypes = {
  humidityDeficitChecked: PropTypes.bool,
  dewPointChecked: PropTypes.bool,
  onHumidityDeficitClick: PropTypes.func,
  onDewPointClick: PropTypes.func,
};

DashboardSettings.defaultProps = {
  onHumidityDeficitClick: () => null,
  onDewPointClick: () => null,
};

export default DashboardSettings;
