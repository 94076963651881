import PropTypes from "prop-types";
import React from "react";
import Button from "./Button";
import Modal from "./Modal";
import styles from "./SensorNotUpdatingModal.module.sass";
import Text from "./Text";

SensorNotUpdatingModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function SensorNotUpdatingModal({ open, onClose }) {
  return (
    <Modal
      show={open}
      type="Dialog"
      title="ただいま個別の更新はできません。しばらくお待ちください"
    >
      <Text center>
        更新中のセンサーが1つでもある場合は更新できないため、時間をおいて再度お試しください
      </Text>
      <Button fit onClick={onClose} className={styles.button}>
        閉じる
      </Button>
    </Modal>
  );
}

export default SensorNotUpdatingModal;
