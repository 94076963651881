import cx from "clsx";
import { navigate } from "hookrouter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import AuthorizedHeader from "../../partials/AuthorizedHeader";
import Box from "../../partials/Box";
import Button from "../../partials/Button";
import { CalcPrice } from "../../partials/calcPrice";
import { CheckUser } from "../../partials/checkUser";
import Footer from "../../partials/Footer";
import Modal from "../../partials/Modal";
import PageTitle from "../../partials/PageTitle";
import RouterSuspendingProcessModal from "../../partials/RouterSuspendingProcessModal";
import RouterSuspendingProcessResultModal from "../../partials/RouterSuspendingProcessResultModal";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import styles from "./index.module.sass";
export const useDidMount = (func) =>
  useEffect(() => {
    func();
  }, []);

let waitingForResult = false;
let myDevice = null;

function Devices(props) {
  const [user, setUser] = useState({});
  const [activeDeviceList, setActiveDeviceList] = useState([]);
  const [suspendedDeviceList, setSuspendedDeviceList] = useState([]);
  const [alertToList, setAlertToList] = useState([]);
  const [alertToId, setAlertToId] = useState("");
  const [alertTo, setAlertTo] = useState(null);
  const [isEditingDevice, setEditingDevice] = useState(false);
  const [
    isShowingRouterSuspendingProcessResultModal,
    setShowingRouterSuspendingProcessResultModal,
  ] = useState(false);
  const [
    isShowingRouterSuspendingProcessModal,
    setShowingRouterSuspendingProcessModal,
  ] = useState(false);
  const [
    routerSuspensionResult,
    setRouterSuspensionResult,
  ] = useState('Success');
  const [currentDevice, setCurrentDevice] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState(false);
  const [resultRouterName, setResultRouterName] = useState("");
  const [initialized, setInitialized] = useState(false);
  //const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (currentDevice) {
      if (!currentDevice.reloaded) {
        setEditingDevice(true);
      }
      myDevice = currentDevice;
    } else {
      setEditingDevice(false);
      setButtonDisabled(false);
    }
  }, [currentDevice]);

  useEffect(() => {
    if (user) {
      fetchDeviceList();
    }
  }, [user]);

  const onload = async () => {
    let me = await CheckUser(props.Auth);
    if (me) {
      setUser(me);
      fetchDeviceStatus();
    } else {
      navigate("/", false);
    }
  };

  const clearCurrentDevice = async () => {
    setCurrentDevice(null);
  };

  const fetchDeviceStatus = async () => {
    const res1 = await _fetchDeviceStatus("device");
    const res2 = await _fetchDeviceStatus("router");
    if (res1.ongoing || res2.ongoing) {
      setWaiting(true);
      waitingForResult = true;
      if (res1.ongoingDevice) {
        setCurrentDevice(res1.ongoingDevice);
        setResultRouterName(res1.ongoingDevice.device.name);
      }
      if (res2.ongoingDevice) {
        setCurrentDevice(res2.ongoingDevice);
        setResultRouterName(res2.ongoingDevice.device.name);
      }
    } else {
      try {
        if (waiting || waitingForResult) {
          setWaiting(false);
          waitingForResult = false;
          setShowingRouterSuspendingProcessModal(false);
          let any = false;
          res2.errorDevices.forEach((r) => {
            if (r.router_id == myDevice.device.router_id) {
              any = true;
            }
          });
          // 親機を停止する場合のみ
          if (myDevice.type == 'router' && myDevice.device.active) {
            if (any) {
              setRouterSuspensionResult('Failure');
            } else {
              setRouterSuspensionResult('Success');
            }
            if (!res2.ongoing) {
              setShowingRouterSuspendingProcessResultModal(true);
            }
          }
          fetchDeviceList();
          setCurrentDevice(null);
        }
      } catch (e) {
        fetchDeviceList();
        setCurrentDevice(null);
      }
    }
    setInitialized(true);
  };

  const _fetchDeviceStatus = async (type) => {
    const session = await props.Auth.currentSession();
    const res = await props.API.post("CheckDeviceStatus", "/check_status", {
      headers: { Authorization: session.idToken.jwtToken },
      body: { type: type },
    });
    let ongoingDevice = null;
    if (!currentDevice && res.ongoingDevice) {
      if (waiting || waitingForResult) {
        res.ongoingDevice.active = !res.ongoingDevice.active;
      }
      ongoingDevice = {
        type: res.ongoingDevice.device_id ? 'device' : 'router',
        device: res.ongoingDevice,
        reloaded: true,
      }
    }
    return {ongoing: res.ongoing, errorDevices: res.errorDevices, ongoingDevice: ongoingDevice};
  };

  const fetchDeviceList = async () => {
    const session = await props.Auth.currentSession();
    const devices = await props.API.get("DeviceList", "/devices", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    parseList(devices);
  };

  const parseList = (list) => {
    let newActiveDevices = [];
    let newSuspendedDevices = [];
    list.devices.map((d) => {
      if (d.suspended) {
        newSuspendedDevices.push({ type: "device", device: d });
      } else {
        newActiveDevices.push({ type: "device", device: d });
      }
    });
    list.routers.map((r) => {
      r.devices = [];
      list.devices.map((d) => {
        if (r.router_id == d.router_id) {
          r.devices.push(d);
        }
      });
      if (r.active) {
        newActiveDevices.push({ type: "router", device: r });
      } else {
        newSuspendedDevices.push({ type: "router", device: r });
      }
    });
    setActiveDeviceList(newActiveDevices);
    setSuspendedDeviceList(newSuspendedDevices);
  };

  const startEditingDevice = (device, isActive) => {
    return () => {
      device.suspended = isActive;
      setCurrentDevice(device);
    };
  };

  const endEditingDevice = () => {
    setCurrentDevice(null);
  };

  const updateStatus = async () => {
    try {
      setButtonDisabled(true);
      const url =
        currentDevice.type == "device"
          ? "/update_device_filter"
          : "/update_router";
      let body = {};
      let newStatus = false;
      setResultRouterName(currentDevice.device.name);
      if (currentDevice.type == "device") {
        body.device_id = currentDevice.device.device_id;
        body.suspended = currentDevice.device.suspended ? false : true;
        newStatus = !body.suspended;
      } else {
        setShowingRouterSuspendingProcessModal(true);
        body.router_id = currentDevice.device.router_id;
        body.active = currentDevice.device.active ? false : true;
        newStatus = body.active;
      }

      const session = await props.Auth.currentSession();
      await props.API.post("UpdateDeviceStatus", url, {
        headers: { Authorization: session.idToken.jwtToken },
        body: body,
      });
      setWaiting(true);
      waitingForResult = true;
      fetchDeviceList();
      setCurrentDevice(null);
      setDeviceStatus(newStatus);
    } catch (e) {
      setWaiting(false);
      setCurrentDevice(null);
      waitingForResult = false;
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    onload();
    const timer = setInterval(() => {
      fetchDeviceStatus();
    }, 5000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  if (!user.attributes) {
    return <div />;
  }

  if (!initialized) {
    return <div />;
  }

  return (
    <>
      <AuthorizedHeader current="menu" user={user} simple />
      <Wrap dividerBottom>
        <PageTitle title="機器の管理" backURL="/menu" />
        <Text gutterBottom>
          使用しなくなった機器は停止をすることで、月額利用料を抑えることができます。
        </Text>
        <div className={styles.caution}>※作業を確実に行うために、親機ルーターが電源に接続されている状態で、子機センサーは親機ルーターのすぐ近くに置いて操作してください。</div>
        <Text variant="h3" gutterBottom>
          稼働中の機器（{activeDeviceList.length}台）
        </Text>
        <Box gutterBottom basic notPadding>
          {activeDeviceList.map((device, idx) => {
            let image =
              device.type == "router"
                ? "icon-router-blue.png"
                : "icon-sensor.png";
            let id =
              device.type == "router"
                ? device.device.router_id
                : device.device.device_id;
            let fee =
              device.type == "router"
                ? CalcPrice.routerDataFee
                : CalcPrice.deviceDataFee;
            let started_at =
                device.device.started_at || device.device.last_updated_at || new Date().getTime();
            return (
              <div
                key={`${device.device.name}-${idx}`}
                className={styles.device}
              >
                <div className={styles.image}>
                  <img src={`/assets/images/${image}`} />
                </div>

                {/* 通常時 */}
                {!waiting && (
                  <div className={styles.contents}>
                    <div className={styles.name}>{device.device.name}</div>
                    {!waiting &&
                    <>
                    {device.type == "router" && (
                      <div className={styles.id}>
                        {device.device.devices.length}個の子機が接続中
                      </div>
                    )}
                    <div className={styles.id}>ID {id}</div>
                    <div className={styles.fee}>月額 ¥{fee}</div>
                    <div className={styles.status}>
                      <span className={styles.statusIcon} />
                      {moment(started_at).format("YYYY年MM月DD日")}
                      から稼働中
                    </div>
                    <div className={styles.action}>
                      <Button
                        negativeTextOnly
                        size="small"
                        onClick={startEditingDevice(device, false)}
                      >
                        月額利用を停止
                      </Button>
                    </div>
                    </>
                    }
                  </div>
                )}

                {/* 停止の処理中 */}
                {waiting && (
                  <div className={styles.contents}>
                    <div className={styles.name}>{device.device.name}</div>
                    <div className={styles.message}>
                      <div className={styles.messageTitle}>
                        <div className={styles.Icon}>
                          {currentDevice === device &&
                          <Spinner
                            animation="border"
                            size="sm"
                            style={{ color: "#27AE60" }}
                          />
                          }
                        </div>
                        {currentDevice === device &&
                        <div className={styles.title}>
                          {deviceStatus
                            ? "機器の利用を再開しています"
                            : "機器を停止しています"}
                          ...
                        </div>
                        }
                      </div>
                      {currentDevice === device &&
                      <div className={styles.messageBody}>
                        {deviceStatus ? "再開" : "停止"}
                        するまで1時間ほど、かかる可能性があります。
                      </div>
                      }
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {activeDeviceList.length === 0 && (
            <div className={styles.blankMessage}>稼働中の機器はありません</div>
          )}
        </Box>
      </Wrap>
      <Wrap>
        <Text variant="h3" gutterBottom>
          停止中の機器（{suspendedDeviceList.length}台）
        </Text>
        <Box gutterBottom basic notPadding>
          {suspendedDeviceList.map((device, idx) => {
            const device_key = 
              device.type == "router"
                ? device.device.router_id
                : device.device.device_id;
            const current_device_key = 
              myDevice 
                ? (myDevice.type == "router"
                  ? myDevice.device.router_id
                  : myDevice.device.device_id)
                : '';
            let image =
              device.type == "router"
                ? "icon-router-blue.png"
                : "icon-sensor.png";
            let id =
              device.type == "router"
                ? device.device.router_id
                : device.device.device_id;
            let fee =
              device.type == "router"
                ? CalcPrice.routerInactiveFee
                : CalcPrice.deviceInactiveFee;
            let stopped_at = 
                  device.device.stopped_at || device.device.last_updated_at || new Date().getTime();
            return (
              <div
                key={`${device.device.name}-${idx}`}
                className={cx(styles.device, styles.deactivated)}
              >
                <div className={styles.image}>
                  <img src={`/assets/images/${image}`} />
                </div>
                {waiting && (
                  <div className={styles.contents}>
                    <div className={styles.name}>{device.device.name}</div>
                    {(current_device_key === device_key) && (
                      <>
                    <div className={styles.message}>
                      <div className={styles.messageTitle}>
                        <div className={styles.Icon}>
                          <Spinner
                            animation="border"
                            size="sm"
                            style={{ color: "#27AE60" }}
                          />
                        </div>
                        <div className={styles.title}>
                          {deviceStatus
                            ? "機器の利用を再開しています"
                            : "機器を停止しています"}
                          ...
                        </div>
                      </div>
                      <div className={styles.messageBody}>
                        {deviceStatus ? "再開" : "停止"}
                        するまで1時間ほど、かかる可能性があります。
                      </div>
                    </div>
                  </>
                )}
                  </div>
                )}
                {!waiting && (
                  <div className={styles.contents}>
                    <div className={styles.name}>{device.device.name}</div>
                    <div className={styles.id}>ID {id}</div>
                    <div className={styles.status}>
                      <span className={styles.statusIcon} />
                      {moment(stopped_at).format("YYYY年MM月DD日")}
                      から停止中
                    </div>
                    <div className={styles.action}>
                      <Button
                        className={styles.action}
                        size="small"
                        onClick={startEditingDevice(device, true)}
                      >
                        月額利用を再開
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {suspendedDeviceList.length === 0 && (
            <div className={styles.blankMessage}>停止中の機器はありません</div>
          )}
        </Box>
      </Wrap>

      <Modal show={isEditingDevice} type="Dialog">
        {/* 利用中のデバイス（停止するためのモーダル） */}
        {currentDevice && !currentDevice.suspended && (
          <>
            <Text variant="h4" center gutterBottom>
              {currentDevice.device.name}を停止しますか？
            </Text>
            {currentDevice.type == "device" && (
              <div className={styles.warningMessage}>
                【注意】
                <br />
                このセンサーの監視設定が解除されます。
              </div>
            )}
            {currentDevice.type == "router" && (
              <div className={styles.warningMessage}>
                【注意】
                <br />
                接続している子機は通信ができなくなります。
              </div>
            )}
            <Text gutterBottom center>
              利用料金は、停止日にかかわらず今月まで発生いたします。
            </Text>
            <div>
              <Button
                disabled={buttonDisabled}
                onClick={updateStatus}
                negative
                fit
              >
                停止する
              </Button>
              <Button disabled={buttonDisabled} onClick={endEditingDevice} fit>
                閉じる
              </Button>
            </div>
          </>
        )}

        {/* 停止中のデバイス（再開するためのモーダル） */}
        {currentDevice && currentDevice.suspended && (
          <>
            <Text variant="h4" gutterBottom>
              {currentDevice.device.name}を再開しますか？
            </Text>
            <Text gutterBottom>
              子機センサーは、親機ルーターのすぐ近くに置いてください。
              アカウントに紐付くすべての親機ルーターが再起動します。
              利用料金は、起動日にかかわらず今月から発生いたします。
            </Text>
            <div>
              <Button
                disabled={buttonDisabled}
                onClick={updateStatus}
                positive
                fit
              >
                再開する
              </Button>
              <Button disabled={buttonDisabled} onClick={endEditingDevice} fit>
                閉じる
              </Button>
            </div>
          </>
        )}
      </Modal>

      {/* 停止処理中のモーダル */}
      <RouterSuspendingProcessModal
        open={isShowingRouterSuspendingProcessModal}
        onClose={() => setShowingRouterSuspendingProcessModal(false)}
      />

      {/* 停止処理完了のモーダル */}
      {/* 成功は「Success、失敗は「Failure」で内容を切り替えます */}
      <RouterSuspendingProcessResultModal
        open={isShowingRouterSuspendingProcessResultModal}
        status={routerSuspensionResult}
        routerName={resultRouterName}
        onClose={() => setShowingRouterSuspendingProcessResultModal(false)}
      />
      <Footer />
    </>
  );
}

export default Devices;
