const GMO_CHARGE = 300;
const GMO_CHARGE_TAX = 30;
const GMO_CHARGE_WITH_TAX = 330;
const TAX_RATE = 0.1;
const ROUTER_BASE_PRICE = 19000;
const SIM_PRICE = 1000;
// issue 510
//const ROUTER_PRICE = ROUTER_BASE_PRICE + SIM_PRICE;
const ROUTER_PRICE = 22000;
const DEVICE_PRICE = 5500;
const ROUTER_COST  = 15120;
const DEVICE_COST  = 3970;
const DEVICE_MONTHLY_FEE = 0;
const ROUTER_MONTHLY_FEE = 0;

const MONTHLY_BASE_FEE = 0;
const ROUTER_DATA_TRANSFER_FEE = 880;
const DEVICE_DATA_TRANSFER_FEE = 110;
const ROUTER_INACTIVE_FEE = 330;
const DEVICE_INACTIVE_FEE = 0;
const MAIL_TRANSFER_FEE = 0;
const LINE_TRANSFER_FEE = 5;
//https://github.com/tel-chan/tel-chan/issues/492#issuecomment-776414947
//const SMS_TRANSFER_FEE = 18;
const SMS_TRANSFER_FEE = 20;
const PHONE_FEE = 22;
const INCOMING_PHONE_FEE = 3;
const UPLINK_FEE = 2;
const DEVICE_FEE_10 = 330;
const DEVICE_FEE_60 = 110;

const CalcPrice = {
  router: (args) => {
    return parseInt(args.quantity) * ROUTER_PRICE;
  },
  device: (args) => {
    return parseInt(args.quantity) * DEVICE_PRICE;
  },
  salesTax: (args) => {
    throw new Error('Called obsolete function CalcPrice.salesTax');
    return Math.round(args.subtotal * 10 / 100);
  },
  priceWithTax: (args) => {
    throw new Error('Called obsolete function CalcPrice.priceWithTax');
    return parseInt(CalcPrice.salesTax({subtotal:args.subtotal})) + parseInt(args.subtotal);
  },
  easyPrice: () => {
    throw new Error('Called obsolete function CalcPrice.easyPrice');
    return DEVICE_PRICE + ROUTER_PRICE;
  },
  shipmentFee: (pref, routerCount, deviceCount) => {

    const calc = (r, d, low, high) => {
      switch(parseInt(r)) {
      case 0:
        if (parseInt(d) <= 8) {
          return low;
        } else if (parseInt(d) <= 64) {
          return high;
        }
        break;
      case 1:
        if (parseInt(d) <= 4) {
          return low;
        } else if (parseInt(d) <= 60) {
          return high;
        }
        break;
      case 2:
        if (parseInt(d) == 0) {
          return  low;
        } else if (parseInt(d) <= 56) {
          return high;
        }
        break;
      default:
        if (r <= 16) {
          const a = 16 - parseInt(r);
          const limit = 4 * a;
          if (parseInt(d) <= limit) {
            return high;
          }
        }
        break;
      }
      return 'お問い合わせください';
    };

    let num;
    if (routerCount != null) {
      num = parseInt(routerCount) + parseInt(deviceCount);
    }
    if (!pref && num == null) {
      return 2200;
    } else if (!pref && num != null) {
      return calc(parseInt(routerCount), parseInt(deviceCount), 1900, 2400);
    } else {
      let base1, base2;
      switch(pref) {
      case '沖縄県':
        base1 = 2200;
        base2 = 2970;
        break;
      case '宮城県':
      case '山形県':
      case '福島県':
      case '青森県':
      case '岩手県':
      case '秋田県':
        base1 = 1540;
        base2 = 2310;
        break;
      case '栃木県':
      case '群馬県':
      case '茨城県':
      case '埼玉県':
      case '千葉県':
      case '神奈川県':
      case '東京都':
      case '山梨県':
        base1 = 1320;
        base2 = 2200;
        break;
      case '新潟県':
      case '長野県':
        base1 = 1375;
        base2 = 2420;
        break;
      case '静岡県':
      case '愛知県':
      case '岐阜県':
      case '三重県':
        base1 = 1375;
        base2 = 2530;
        break;
      case '福岡県':
      case '佐賀県':
      case '長崎県':
      case '大分県':
      case '熊本県':
      case '宮崎県':
      case '鹿児島県':
        base1 = 990;
        base2 = 1540;
        break;
      case '北海道':
        base1 = 1760;
        base2 = 2860;
        break;
      default:
        base1 = 1375;
        base2 = 2310;
        break;
      }
      return calc(parseInt(routerCount), parseInt(deviceCount), base1, base2);
    }
  },
  /*
   * 税込金額から税額を算出する
   * @param {number} taxIncluded 税込金額
   * @param {number} tax 税率
   * @return {number} 税額
   */
  taxFromTaxIncluded: (taxIncluded, tax=null) => {
    tax = tax === null ? TAX_RATE : tax;
    return Math.floor(taxIncluded * 10 / Math.floor((tax + 1) * 100));
  },
  /*
   * 消費税表記（nn%）を返す
   * @return {string} 消費税表記（nn%
   */
  showTaxRate: () => {
    return (TAX_RATE * 100) + '%';
  },
  devicePrice: DEVICE_PRICE,
  routerPrice: ROUTER_PRICE,
  mailFee: MAIL_TRANSFER_FEE,
  smsFee: SMS_TRANSFER_FEE,
  phoneFee: PHONE_FEE,
  incomingPhoneFee: INCOMING_PHONE_FEE,
  lineFee: LINE_TRANSFER_FEE,
  deviceDataFee: DEVICE_DATA_TRANSFER_FEE,
  routerDataFee: ROUTER_DATA_TRANSFER_FEE,
  deviceInactiveFee: DEVICE_INACTIVE_FEE,
  routerInactiveFee: ROUTER_INACTIVE_FEE,
  deviceBaseFee: MONTHLY_BASE_FEE,
  routerBaseFee: MONTHLY_BASE_FEE,
  deviceCost: DEVICE_COST,
  routerCost: ROUTER_COST,
  uplinkFee: UPLINK_FEE,
  deviceFee10: DEVICE_FEE_10,
  deviceFee60: DEVICE_FEE_60,
  gmoCharge: GMO_CHARGE,
  gmoChargeWithTax: GMO_CHARGE_WITH_TAX,
  gmoChargeTax: GMO_CHARGE_TAX,
  taxRate: TAX_RATE,
};

export{CalcPrice};
