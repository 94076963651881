import React, { useEffect, useState } from "react";
import styles from "./deviceList.module.sass";
import { RouterArea } from "./routerArea";
import SensorArea from "./sensorArea";

export const useDidMount = (func) =>
  useEffect(() => {
    func();
  }, []);
function DeviceList(props) {
  const defaultSortOrder = [
    { name: "temperature", status: "" },
    { name: "moisture", status: "" },
    { name: "illuminance", status: "" },
  ];
  const [sortOrder] = useState("default");
  const [deviceList, setDeviceList] = useState([]);
  const [deviceDisableList, setDeviceDisableList] = useState([]);
  const [routerList, setRouterList] = useState([]);
  const [routerDisableList, setRouterDisableList] = useState([]);
  const [orderList] = useState(defaultSortOrder);

  const sortByName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
  };

  const reload = async () => {
    await props.reload();
  };

  const generateList = async () => {
    if (!props.list) {
      props.setShowBackdrop(false);
      return;
    }
    if (!props.alertTo) {
      props.setShowBackdrop(false);
      return;
    }
    if (props.list.routers.length <= 0) {
      props.setShowBackdrop(false);
      return;
    }
    props.setProgress(20);
    let devicesInService = [];
    let devicesOutOfService = [];
    let allRouters = [];
    let routersInService = [];
    let routersOutOfService = [];
    props.list.routers.map((r) => {
      r.devices = [];
      allRouters.push(r);
    });
    props.list.devices.forEach((device) => {
      if (device.suspended) {
        devicesOutOfService.push(device);
      } else {
        devicesInService.push(device);
      }
      props.list.routers.forEach((r) => {
        if (r.router_id === device.router_id) {
          r.devices.push(device);
          if (device.sensorValue && device.sensorValue.router) {
            r.rssi = device.sensorValue.router.rssi;
          }
        }
      });
    });
    allRouters.forEach((r) => {
      if (r.active) {
        routersInService.push(r);
      } else {
        routersOutOfService.push(r);
      }
    });
    devicesInService.sort(sortByName);
    devicesOutOfService.sort(sortByName);
    routersInService.sort(sortByName);
    routersOutOfService.sort(sortByName);

    setDeviceList(devicesInService);
    setRouterList(routersInService);
    setDeviceDisableList(devicesOutOfService);
    setRouterDisableList(routersOutOfService);

    props.setProgress(90);
    props.setLabel("処理中");
    props.setProgress(95);
    props.setShowBackdrop(false);
  };

  useDidMount(async () => {
    window.scrollTo(0, 0);
    props.setShowBackdrop(true);
  });

  const updateList = async () => {
    await generateList();
  };

  useEffect(() => {
    updateList();
  }, [sortOrder]);

  useEffect(() => {
    updateList();
  }, [props.list]);

  return (
    <>
      <div className={styles.dividerTitle}>稼働中の子機センサー</div>
      <div className={styles.list}>
        {deviceList.map((device) => {
          return (
            <SensorArea
              {...props}
              onChange={reload}
              routers={props.list.routers}
              key={device.device_id}
              device={device}
              alertTo={props.alertTo}
              type={orderList[0].name}
            />
          );
        })}
      </div>
      {/* 稼働中の親機ルーター */}
      <>
        <div className={styles.divider} />
        <div className={styles.dividerTitle}>稼働中の親機ルーター</div>
        {routerList.map((router) => {
          const statusChecked = props.routerStatusChecked;
          if (props.routerList && props.routerList.length > 0) {
            for(const r of props.routerList){
              if (router.router_id == r.router_id) {
                router.isAlive = r.isAlive;
              }
            }
          }
          return (
            <RouterArea
              statusChecked={statusChecked}
              key={router.router_id}
              {...props}
              router={router}
              hasSpace={router.devices && router.devices.length <= 0}
            />
          );
        })}
      </>

      {/* 停止中の子機センサー */}
      {deviceDisableList.length > 0 && (
        <>
          <div className={styles.divider} />
          <div className={styles.dividerTitle}>停止中の子機センサー</div>
          <div className={styles.list}>
            {deviceDisableList.map((device) => {
              return (
                <SensorArea
                  {...props}
                  onChange={reload}
                  routers={props.list.routers}
                  key={device.device_id}
                  device={device}
                  alertTo={props.alertTo}
                  sensorValue={device.sensorValue || {}}
                  type={orderList[0].name}
                />
              );
            })}
          </div>
        </>
      )}

      {/* 停止中の親機ルーター */}
      {routerDisableList.length > 0 && (
        <>
          <div className={styles.divider} />
          <div className={styles.dividerTitle}>停止中の親機ルーター</div>
          {routerDisableList.map((router) => {
            const statusChecked = props.routerStatusChecked;
            if (props.routerList && props.routerList.length > 0) {
              for(const r of props.routerList){
                if (router.router_id == r.router_id) {
                  router.isAlive = r.isAlive;
                }
              }
            }
            return (
              <RouterArea
                statusChecked={statusChecked}
                key={router.router_id}
                routerList={props.routerList}
                {...props}
                router={router}
                hasSpace={router.devices && router.devices.length <= 0}
              />
            );
          })}
        </>
      )}
    </>
  );
}

export { DeviceList };
