const ConfirmUserMail = async (args) => {
  try {
    const session = await args.Auth.currentSession();
    const response = await args.API.post('ConfirmCode', '/confirm_code', {
      headers: {Authorization: session.idToken.jwtToken}, 
      body: {code: args.token, action: 'profile-email'},
    });
  } catch(e) {
    // do nothing (should be fixed on the server-side)
  }
};
export{ConfirmUserMail};
