import { mdiMinus } from "@mdi/js";
import Icon from "@mdi/react";
import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import BatteryStatus from "../partials/BatteryStatus";
import RssiStatus from "../partials/RssiStatus";
import DewPointValue from "./DewPointValue";
import SensorHumidityDeficitValue from "./SensorHumidityDeficitValue";
import styles from "./SensorValues.module.sass";

function SensorValues({
  temperature,
  moisture,
  illuminance,
  waterlevel,
  acceleration,
  disabledTemperature,
  disabledMoisture,
  disabledIlluminance,
  disabledWaterlevel,
  disabledAcceleration,
  deviceUnavailable,
  textColor,
  battery,
  rssi,
  humidityDeficitChecked,
  dewPointChecked,
}) {
  const WaterLevelStatus = () => {
    if (waterlevel == null) {
      return {
        src: "/assets/images/icon-waterlv/icon-waterlv-lv0.svg",
        label: "不明",
      };
    } else if (waterlevel == 0) {
      return {
        src: "/assets/images/icon-waterlv/icon-waterlv-lv0.svg",
        label: "水位なし",
      };
    } else if (waterlevel <= 1) {
      return {
        src: "/assets/images/icon-waterlv/icon-waterlv-lv1.svg",
        label: "低水位",
      };
    } else if (waterlevel <= 3) {
      return {
        src: "/assets/images/icon-waterlv/icon-waterlv-lv2.svg",
        label: "中水位",
      };
    } else {
      return {
        src: "/assets/images/icon-waterlv/icon-waterlv-lv3.svg",
        label: "高水位",
      };
    }
  };

  // 飽和水蒸気圧(近似値)
  const SaturationWaterVaporPressure = () => {
      return 6.1078 * Math.pow(10, (7.5 * parseInt(temperature)) / (parseInt(temperature) + 237.3));
  };

  // 露点
  const DewPoint = () => {
    // 水蒸気圧
    let waterVaporPressure = SaturationWaterVaporPressure() * parseInt(moisture) / 100.0;
    return 237.3 * Math.log10(6.1078 / waterVaporPressure) / (Math.log10(waterVaporPressure / 6.1078) - 7.5);
  };

  const HumidityDeficitStatus = () => {
    // 飽和水蒸気量
    let saturatedWaterVapor = (217.0 * SaturationWaterVaporPressure()) / (parseInt(temperature) + 273.5);
    // 飽差
    let value = saturatedWaterVapor * (100.0 - parseInt(moisture)) / 100.0;

    if (value >= 10) {
      return {
        status: "very-dry",
        value: value
      };
    } else if (value >= 7) {
      return {
        status: "dry",
        value: value
      };
    } else if (value >= 3) {
      return {
        status: "normal",
        value: value
      };
    } else if (value >= 2) {
      return {
        status: "humid",
        value: value
      };
    } else {
      return {
        status: "very-humid",
        value: value
      };
    }
  };

  const parseIlluminance = (i) => {
    if (parseInt(i) >= 83000) {
      return (
        <>
          83
          <img
            className={styles.illuminanceUnitKOver}
            src="/assets/images/icon-unit-k-over.svg"
          />
        </>
      );
    } else if (parseInt(i) >= 1000) {
      return (
        <>
          {parseInt(parseInt(i) / 1000)}
          <img
            className={styles.illuminanceUnitK}
            src="/assets/images/icon-unit-k.svg"
          />
        </>
      );
    } else {
      return i;
    }
  };

  return (
    <div className={styles.wrap}>
      <div
        className={cx(
          styles.values,
          deviceUnavailable ? styles.unavailable : ""
        )}
      >
        {!disabledTemperature && (
          <div className={styles.valueWrap}>
            <div className={styles.value} style={{ color: textColor }}>
              <div className={styles.number}>
                {temperature != null ? (
                  parseInt(temperature, 10)
                ) : (
                  <Icon size={2} path={mdiMinus} />
                )}
              </div>
              <div className={styles.unit}>℃</div>
            </div>
          </div>
        )}
        {!disabledMoisture && (
          <div className={styles.valueWrap}>
            <div className={styles.value} style={{ color: textColor }}>
              <div className={styles.number}>
                {moisture != null ? (
                  parseInt(moisture)
                ) : (
                  <Icon size={2} path={mdiMinus} />
                )}
              </div>
              <div className={styles.unit}>%</div>
            </div>
          </div>
        )}
        {!disabledIlluminance && (
          <div className={styles.valueWrap}>
            <div className={styles.value} style={{ color: textColor }}>
              <div className={styles.number}>
                {illuminance != null ? (
                  parseIlluminance(parseInt(illuminance, 10))
                ) : (
                  <Icon size={2} path={mdiMinus} />
                )}
              </div>
              {/* 83k以上は */}
              <div className={styles.unit}>lx</div>
            </div>
          </div>
        )}
        {!disabledWaterlevel && (
          <div className={styles.valueWrap}>
            <div className={styles.signal}>
              <div className={styles.icon}>
                <img src={WaterLevelStatus().src} />
              </div>
              <div className={styles.label}>{WaterLevelStatus().label}</div>
            </div>
          </div>
        )}
        {!disabledAcceleration && (
          <div className={styles.valueWrap}>
            <div className={styles.signal}>
              <div className={styles.icon}>
                <img src="/assets/images/icon-acceleration/icon-acceleration-lv1.svg" />
              </div>
              <div className={styles.label}>
                <div>加速度</div>
                <div className={styles.acceleration}>
                  {acceleration != null ? acceleration + "m/s" : "不明"}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* ネットワーク、バッテリーのステータス */}
        <div className={styles.valueWrap}>
          <div className={styles.statusIconWrap}>
            <BatteryStatus
              capacity={battery}
              className={styles.statusIcon}
              negative
            />
            <RssiStatus rssi={rssi} className={styles.statusIcon} negative />
          </div>
        </div>
      </div>
      
      {/* 飽差 */}
      {/* very-humid, humid, normal, dry, very-dry */}
      {humidityDeficitChecked && temperature && moisture && <SensorHumidityDeficitValue value={HumidityDeficitStatus().value} status={HumidityDeficitStatus().status} />}

      {/* 露点温度 */}
      {dewPointChecked && temperature && moisture && (
        <DewPointValue value={DewPoint()} />)}
    </div>
  );
}

//すべての情報はNumberで取得していますが、デザイン上の仮の仕様なので、不都合があれば色々とご変更ください。
//disabled{Name}: 指定した項目を非表示
//deviceUnavailable: センサーの値が取得できなことを示すデザインを使用

SensorValues.propTypes = {
  temperature: PropTypes.number,
  temperatureHighest: PropTypes.number,
  temperatureLowest: PropTypes.number,
  moisture: PropTypes.number,
  moistureHighest: PropTypes.number,
  moistureLowest: PropTypes.number,
  illuminance: PropTypes.number,
  illuminanceHighest: PropTypes.number,
  illuminanceLowest: PropTypes.number,
  waterlevel: PropTypes.number,
  acceleration: PropTypes.number,
  disabledTemperature: PropTypes.bool,
  disabledMoisture: PropTypes.bool,
  disabledIlluminance: PropTypes.bool,
  disabledWaterlevel: PropTypes.bool,
  disabledAcceleration: PropTypes.bool,
  deviceUnavailable: PropTypes.bool,
  textColor: PropTypes.string,
  battery: PropTypes.number,
  rssi: PropTypes.number,
  humidityDeficitChecked: PropTypes.bool,
  dewPointChecked: PropTypes.bool,
};

SensorValues.defaultProps = {
  disabledTemperature: false,
  disabledMoisture: false,
  disabledIlluminance: false,
  disabledWaterlevel: false,
  disabledAcceleration: false,
  deviceUnavailable: false,
};

export default SensorValues;
