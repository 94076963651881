import PropTypes from "prop-types";
import React from "react";
import styles from "./DewPointValue.module.sass";

function DewPointValue({ value }) {
  return (
    <div className={styles.component}>
      <div className={styles.value}>
        <div className={styles.number}>{parseInt(Math.floor(value * 10) / 10, 10)}</div>
        <div className={styles.unitWrap}>
          <div className={styles.unitText}>露点</div>
          <div className={styles.unit}>℃</div>
        </div>
      </div>
    </div>
  );
}

DewPointValue.propTypes = {
  value: PropTypes.number,
};

export default DewPointValue;
