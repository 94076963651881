import { mdiMinus, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { navigate } from "hookrouter";
import React, { useEffect, useState } from "react";
import { CalcPrice } from "../../../../web/src/partials/calcPrice";
import { Delimiter } from "../../delimiter";
import AlertBox from "../../partials/AlertBox";
import Box from "../../partials/Box";
import Button from "../../partials/Button";
import CampaignCard from "../../partials/CampaignCard";
import CheckoutSummary from "../../partials/CheckoutSummary";
import Footer from "../../partials/Footer";
import ItemCard from "../../partials/ItemCard";
import PageTitle from "../../partials/PageTitle";
import PurchaseHeader from "../../partials/PurchaseHeader";
import Stepper from "../../partials/Stepper";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import Billing from "./Billing";
import Finish from "./Finish";
import styles from "./Items.module.sass";
import Review from "./Review";
import Shipping from "./Shipping";

function Items(props) {
  const [campaignChecked, setCampaignChecked] = useState(false);
  const [page, setPage] = useState("items");
  const [routerCount, setRouterCount] = useState(0);
  const [deviceCount, setDeviceCount] = useState(0);
  const [routerPrice, setRouterPrice] = useState(0);
  const [devicePrice, setDevicePrice] = useState(0);
  const [routerMonthlyFee, setRouterMonthlyFee] = useState(0);
  const [deviceMonthlyFee, setDeviceMonthlyFee] = useState(0);
  const [shipmentFee, setShipmentFee] = useState(0);
  const [savedProps, setSavedProps] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [billingErrorMessage, setBillingErrorMessage] =
    useState("キャンセルされました");
  const [showBillingErrorMessage, setShowBillingErrorMessage] = useState(false);

  const updateProps = async (key, val) => {
    setSavedProps((prevState) => ({ ...prevState, [key]: val }));
  };

  const maximumNumberOfPurchases = 64;

  const onload = async () => {
    const url = new URL(window.location.href);
    const query = new URLSearchParams(url.searchParams);
    const paths = url.pathname.split("/");
    if (paths[3] && paths[3] != "cancel") {
      navigate("/shop/items/cancel");
    } else if (paths[3]) {
      setBillingErrorMessage("キャンセルされました");
      setShowBillingErrorMessage(true);
    }
  };

  useEffect(() => {
    onload();
  }, []);

  useEffect(() => {
    updateProps('campaignChecked', campaignChecked);
  }, [campaignChecked]);

  useEffect(() => {
    setShowErrorMessage(false);
    setRouterPrice(routerCount * CalcPrice.routerPrice);
    setRouterMonthlyFee(routerCount * CalcPrice.routerDataFee);
    if (routerCount > 0) {
      const newShipmentFee = CalcPrice.shipmentFee(
        null,
        parseInt(routerCount), parseInt(deviceCount)
      );
      setShipmentFee(newShipmentFee);
    }
    updateProps("routerCount", routerCount);
    updateProps("routerPrice", routerCount * CalcPrice.routerPrice);
    updateProps("routerMonthlyFee", routerCount * CalcPrice.routerDataFee);
  }, [routerCount]);

  useEffect(() => {
    setShowErrorMessage(false);
    setDevicePrice(deviceCount * CalcPrice.devicePrice);
    setDeviceMonthlyFee(deviceCount * CalcPrice.deviceDataFee);
    if (deviceCount > 0) {
      setShipmentFee(
        CalcPrice.shipmentFee(
          null,
          parseInt(routerCount), parseInt(deviceCount)
        )
      );
    }
    updateProps("deviceCount", deviceCount);
    updateProps("devicePrice", deviceCount * CalcPrice.devicePrice);
    updateProps("deviceMonthlyFee", deviceCount * CalcPrice.deviceDataFee);
  }, [deviceCount]);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [page]);

  useEffect(() => {
    updateProps("shipmentFee", shipmentFee);
  }, [shipmentFee]);

  if (page == "shipping") {
    return (
      <Shipping
        {...props}
        setPage={setPage}
        {...savedProps}
        updateProps={updateProps}
        setShipmentFee={setShipmentFee}
      />
    );
  } else if (page == "billing") {
    return (
      <Billing
        {...props}
        {...savedProps}
        updateProps={updateProps}
        setPage={setPage}
      />
    );
  } else if (page == "review") {
    return (
      <Review
        {...props}
        setPage={setPage}
        {...savedProps}
        updateProps={updateProps}
      />
    );
  } else if (page == "finish") {
    return <Finish {...props} {...savedProps} />;
  } else if (page == "finish_credit") {
    return <Finish {...props} status={'審査中'} {...savedProps} />;
  }

  return (
    <>
      <PurchaseHeader />
      <Wrap>
        {showBillingErrorMessage && (
          <AlertBox gutterBottom text={billingErrorMessage} />
        )}
        <PageTitle title="ご購入製品の選択"></PageTitle>
        <Stepper steps={[1, 2, 3, 4]} current={1} />
        {showErrorMessage && (
          <AlertBox
            gutterBottom
            text={
              parseInt(deviceCount) + parseInt(routerCount) == 0
                ? "購入する製品の個数を入力してください。"
                : `購入できる製品の数は${maximumNumberOfPurchases}個です。大量購入につきましては、お手数ですがお問い合わせください。`
            }
          />
        )}

        <Box basic gutterBottom>
          <ItemCard
            imageUrl="/assets/images/icon-router-blue.png"
            name="親機ルーター"
            description="Braveridge BraveROUTE"
            description2="メーカー保証 1年"
            price={CalcPrice.routerPrice}
            monthlyFee={CalcPrice.routerDataFee}
          >
            <div className={styles.purchaseNumberArea}>
              <div
                className={styles.purchaseNumberInputIcon}
                onClick={() => {
                  setRouterCount(Math.max(0, routerCount - 1));
                }}
              >
                <Icon size={1} path={mdiMinus} />
              </div>
              <div className={styles.purchaseNumberInput}>
                {Delimiter(routerCount)}
                <span className={styles.purchaseNumberLabel}>個</span>
              </div>
              <div
                className={styles.purchaseNumberInputIcon}
                onClick={() => {
                  setRouterCount(routerCount + 1);
                }}
              >
                <Icon size={1} path={mdiPlus} />
              </div>
            </div>
          </ItemCard>
        </Box>
        <Box basic>
          <ItemCard
            imageUrl="/assets/images/icon-sensor.png"
            name="子機センサー（温度・湿度・照度）"
            description="Braveridge TETRA 防水"
            description2="メーカー保証 1年"
            price={CalcPrice.devicePrice}
            monthlyFee={CalcPrice.deviceDataFee}
          >
            <div className={styles.purchaseNumberArea}>
              <div
                className={styles.purchaseNumberInputIcon}
                onClick={() => {
                  setDeviceCount(Math.max(0, deviceCount - 1));
                }}
              >
                <Icon size={1} path={mdiMinus} />
              </div>
              <div className={styles.purchaseNumberInput}>
                {Delimiter(deviceCount)}
                <span className={styles.purchaseNumberLabel}>個</span>
              </div>
              <div
                className={styles.purchaseNumberInputIcon}
                onClick={() => {
                  setDeviceCount(deviceCount + 1);
                }}
              >
                <Icon size={1} path={mdiPlus} />
              </div>
            </div>
          </ItemCard>
          <div className={styles.deviceExplainArea}>
            <div className={styles.deviceExplainText}>
              子機センサーは、親機ルーターの{" "}
              <span className={styles.warningText}>半径200m以内</span>
              に配置してください。複数同時に接続できます。
            </div>
            <div className={styles.deviceExplanImage}>
              <img
                src="/assets/images/device-using-area.png"
                width="95"
                alt="デバイスの使用範囲"
              />
            </div>
          </div>
        </Box>

        {/* キャンペーン */}
        {false && (
          <CampaignCard
            checked={campaignChecked}
            handleClick={() => {setCampaignChecked(!campaignChecked);}}
            title="【先着50名様】電圧変換器＆接続ケーブルプレゼントキャペーン"
            description="電源がない圃場でもカーバッテリーから、親機ルーターへ電源を供給できる「電圧変換器＆接続ケーブル」を無料プレゼント。"
            detail={
              <>
                <Text variant="h2">
                  【先着50名様】電圧変換器＆接続ケーブルプレゼントキャペーン
                </Text>
                <Text variant="h4" gutterTop>
                  キャンペーン概要
                </Text>
                <Text>
                  キャンペーン期間中、農業IoT『てるちゃん』用
                  <br />
                  親機ルーター（Braveridge社製）を購入された方、先着50名様へ「電圧変換器＆接続ケーブル」をプレゼントいたします
                </Text>
                <Text variant="h4" gutterTop>
                  キャンペーン期間
                </Text>
                <Text>
                  2022年3月8日（火）〜2022年3月31日（木）23:59:59
                  ご購入手続き完了分まで
                </Text>
                <Text variant="h4" gutterTop>
                  対象となるお客様
                </Text>
                <Text>
                  農業IoT『てるちゃん』機器購入画面にて、「【先着50名様】電圧変換器＆接続ケーブルプレゼントキャペーン」のチェックを入れてご購入いただいたお客様のうち、先着50名様
                </Text>
                <Text variant="h4" gutterTop>
                  キャンペーン賞品
                </Text>
                <Text>
                  電圧変換器＆接続ケーブル：12V→5V(USB)
                  <br />
                  ・電源がない圃場でも、カーバッテリーを使って親機ルーターへ電源を供給するための「電圧変換器＆接続ケーブル」です（カーバッテリーは含みません、お客様にて別途ご用意ください）
                </Text>
                <Text variant="h4" gutterTop>
                  応募締切
                </Text>
                <Text>2022年3月31日（木）23:59:59 （日本時間）</Text>
                <Text variant="h4" gutterTop>
                  賞品の発送時期
                </Text>
                <Text>
                  2022年3月14日（月）以降、お申し込みいただいた方から順次発送いたします
                </Text>
                <Text variant="h4" gutterTop>
                  注意事項
                </Text>
                <Text>
                  ・賞品は、ご購入いただいた機器と同じ住所宛（日本国内）に発送いたします
                  <br />
                  ・交換・返金・換金等には応じかねますのでご了承ください
                  <br />
                  ・カーバッテリーはプレゼントに含みません
                  <br />
                  ・カーバッテリーの容量によって、親機ルーターへ電力を供給できる期間は異なります（参考：満充電された一般的な乗用車用のカーバッテリー（28Ah）にて、てるちゃん親機ルーター1台を最大約1ヶ月半稼働できます）
                  <br />
                  ・「電圧変換器＆接続ケーブル」のご使用あたっては、はじめに必ず参考動画をご覧ください
                  <br />
                  ・「電圧変換器＆接続ケーブル」および関連する装置は、お客様ご自身の管理下で、安全を確認の上でご使用ください
                  <br />
                  ・「電圧変換器＆接続ケーブル」は、お子様の手が届かない場所、ペットや野外生物が接触しない場所でご使用ください
                  <br />
                  ・「電圧変換器＆接続ケーブル」は、水濡れ／ショート／漏電などが発生しないように充分ご注意の上でご使用ください
                  <br />
                  ・「電圧変換器＆接続ケーブル」は、カーバッテリー電源をUSB電源へ降圧する目的以外での使用はおやめください
                  <br />
                  ・「電圧変換器＆接続ケーブル」に発熱などの異常が認められた場合には、直ちに使用を中止してください
                  <br />
                  ・「電圧変換器＆接続ケーブル」は動作確認の上で発送いたしますが、品質を保証するものではありません
                  <br />
                  利用イメージ：参考動画から抜粋（カーバッテリー、親機ルーターおよび付属のUSBケーブルは、プレゼント対象外です）
                </Text>
              </>
            }
          />
        )}

        {/* 【要実装】ルータを一台も持っていないユーザーが、ルータを買わない場合に表示 */}
        {parseInt(routerCount) === 0 && (
          <div className={styles.confirmTextArea}>
            <div className={styles.confirmRouterTitle}>
              親機ルーターは、お持ちですか？
            </div>
            <div className={styles.confirmRouterDetail}>
              子機センサー単体では動作しません。親機ルータをお持ちでない場合は、一緒にご購入ください。
            </div>
          </div>
        )}
        <div className={styles.divider} />
        {parseInt(routerCount) + parseInt(deviceCount) <=
          maximumNumberOfPurchases && (
          <>
            <CheckoutSummary
              title="初回のみのお支払い料金"
              items={[
                {
                  name:
                    routerCount > 1
                      ? `親機ルーター端末代金 ×${routerCount}`
                      : "親機ルーター端末代金",
                  price: routerPrice,
                },
                {
                  name:
                    deviceCount > 1
                      ? `子機センサー端末代金 ×${deviceCount}`
                      : "子機センサー端末代金",
                  price: devicePrice,
                },
              ]}
            />
            <div className={styles.divider} />
            <CheckoutSummary
              title="毎月のお支払い料金"
              items={[
                {
                  name:
                    routerCount > 1
                      ? `親機ルーター月額利用料 ×${routerCount}`
                      : "親機ルーター月額利用料",
                  price: routerMonthlyFee,
                },
                {
                  name:
                    deviceCount > 1
                      ? `子機センサー月額利用料 ×${deviceCount}`
                      : "子機センサー月額利用料",
                  price: deviceMonthlyFee,
                },
              ]}
            />
            <div className={styles.divider} />
            <div className={styles.checkoutNote}>
              ※機器の購入に際しては、別途送料がかかります。次の画面「お支払いを入力②」以降に表示される送料をご確認ください。
              <br />
              ※上記以外に、監視料金、通知料金、確認料金がそれぞれかかります。詳しくは、
              <a
                href="https://support.tel-chan.com/hc/ja/articles/900004699186-%E8%B3%AA%E5%95%8F-%E3%81%A6%E3%82%8B%E3%81%A1%E3%82%83%E3%82%93%E3%81%AE%E6%96%99%E9%87%91%E4%BD%93%E7%B3%BB%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
                target="_blank"
              >
                料金体系
              </a>
              をご確認ください。
              <br />
              ※表示の料金は、すべて税込です。
            </div>
            <div className={styles.action}>
              <Button
                onClick={() => {
                  setShowErrorMessage(false);
                  setBillingErrorMessage("");
                  setShowBillingErrorMessage(false);
                  const total = parseInt(routerCount) + parseInt(deviceCount);
                  if (total <= 0) {
                    window.scrollTo(0, 0);
                    setShowErrorMessage(true);
                  } else if (total > maximumNumberOfPurchases) {
                    window.scrollTo(0, 0);
                    setShowErrorMessage(true);
                  } else {
                    setPage("shipping");
                  }
                }}
                fit
                primary
              >
                お届け先の入力へすすむ
              </Button>
            </div>
          </>
        )}
        {parseInt(routerCount) + parseInt(deviceCount) >
          maximumNumberOfPurchases && (
          <AlertBox
            gutterBottom
            text={`購入できる製品の数は${maximumNumberOfPurchases}個です。大量購入につきましては、お手数ですがお問い合わせください。`}
          />
        )}
      </Wrap>
      <Footer openLinkInBlank={true} />
    </>
  );
}

export default Items;
