import React, { useEffect, useState } from 'react';
import AuthorizedHeader from '../../partials/AuthorizedHeader';
import Button from '../../partials/Button';
import Footer from '../../partials/Footer';
import Text from '../../partials/Text';
import Wrap from '../../partials/Wrap';
import { A, navigate } from 'hookrouter';
import { CheckUser } from '../../partials/checkUser';
import { UserList } from '../../partials/userList';
import styles from './ContactFinish.module.sass';
export const useDidMount = (func) => useEffect(() => { func() }, []);
function ContactFinish(props) {
  const [user, setUser] = useState({});
  const onload = async () => {
    const currentUser = await CheckUser(props.Auth);
    if (currentUser) {
      setUser(currentUser);
      //const session = await props.Auth.currentSession();
      //const alert_to = await props.API.get('AlertToList', '/alert_to', {
      //  headers: {Authorization: session.idToken.jwtToken}});
      //if (alert_to[0]) {
      //  setAlertToId(alert_to[0].alert_to_id);
      //  setAlertTo(alert_to[0]);
      //}
      //setAlertToList(alert_to);
    } else {
      navigate('/', false);
    }
  };

  useDidMount(async () => {
    onload();
  });

  return (
    <>
      <AuthorizedHeader current="menu" user={user} simple />
      <Wrap>
        <div className={styles.wrap}>
          <img className={styles.image} src="/assets/images/tel-done.svg" />
          <Text gutterBottom variant="h4">お問い合わせありがとうございます。</Text>
          <Text gutterBottom>
            ご質問へのご回答は近日中に<br/>
            tel-chan-support@kddi-web.com<br/>
            よりメールにてお送りいたします。
          </Text>
          <A href="/menu">
            <Button>メニューへもどる</Button>
          </A>
        </div>
      </Wrap>
      <Footer />
    </>
  );
}

export default ContactFinish;

