import React, { useEffect, useState } from 'react';
import { A, navigate } from 'hookrouter';
import AuthorizedHeader from '../../partials/AuthorizedHeader';
import Footer from '../../partials/Footer';
import { CheckUser } from '../../partials/checkUser';
import styles from './index.module.sass';
import { Telchan } from '../../Telchan';

function Menu(props) {
  const [user, setUser] = useState({});

  useEffect(() => {
    onload();
    return () => {};
  }, []);

  const onload = async () => {
    let user = await CheckUser(props.Auth);
    if (user) {
      setUser(user);
    } else {
      // user must be logged in, good bye
      navigate('/')
    }
  };

  if (!user.attributes) { return <div />;}

  return (
    <>
      <AuthorizedHeader current="menu" user={user} />
      <div className={styles.header} data-private>
        {user.attributes ? user.attributes.name : null}
      </div>
      <div className={styles.body}>
        <div className={styles.menus}>
          <A className={styles.menu} href="/account">
            <div className={styles.menuTitle}>お客様情報</div>
            <div className={styles.menuIcon}><img src="/assets/images/menu/icon-menu-profile.svg" alt="プロフィールアイコン" /></div>
          </A>
          <A className={styles.menu} href="/payment">
            <div className={styles.menuTitle}>お支払い</div>
            <div className={styles.menuIcon}><img src="/assets/images/menu/icon-menu-payment.svg" alt="お支払いアイコン" /></div>
          </A>
          <A className={styles.menu} href="/devices">
            <div className={styles.menuTitle}>機器の管理</div>
            <div className={styles.menuIcon}><img src="/assets/images/menu/icon-menu-history.svg" alt="機器の管理アイコン" /></div>
          </A>
          <A className={styles.menu} href="/shop/items">
            <div className={styles.menuTitle}>機器の購入</div>
            <div className={styles.menuIcon}><img src="/assets/images/menu/icon-menu-shop.svg" alt="機器の購入アイコン" /></div>
          </A>
        </div>
      </div>
      <div className={styles.subMenus}>
      <A className={styles.subMenu} href="/order_history">ご注文履歴 </A>
      <a className={styles.subMenu} target="_blank" href="https://tel-chan.zendesk.com/hc/ja/sections/900000536706-%E3%81%94%E8%B3%AA%E5%95%8F-FAQ-">よくある質問</a>
      <A className={styles.subMenu} href="/contact">お問い合わせ</A>
      <A className={styles.subMenu} href="/unsubscribe">解約申請 </A>
      <A className={styles.subMenu} href="/signout">ログアウト</A>
      </div>
      <Footer />
    </>
  )
}

export default Menu;
