import React from 'react';
import PropTypes from 'prop-types';
import styles from './Alert.module.sass';

function Alert({ children }){
  return (
    <div className={styles.wrap}>
      {children}
    </div>
  )
}

Alert.propTypes = {
  children: PropTypes.node,
}

export default Alert