import PropTypes from "prop-types";
import React from "react";
import styles from "./Blank.module.sass";

function Blank({ children, description, actions, images }) {
  return (
    <div className={styles.component}>
      {images && <div className={styles.image}>{images}</div>}
      <div className={styles.contents}>{children}</div>
      {description && <div className={styles.description}>{description}</div>}
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
}

Blank.propTypes = {
  children: PropTypes.node,
  description: PropTypes.node,
  actions: PropTypes.node,
  images: PropTypes.node,
};

export default Blank;
