import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './AlertBox.module.sass';

function AlertBox({ text, gutterBottom, children }){
  return (
    text && (
      <div className={cx(
        styles.alertMessageArea,
        gutterBottom ? styles.gutterBottom : ''
      )}>
        <div className={styles.alertImage}>
          <img src="/assets/images/icon-alert.png" alt="アラート"/>
        </div>
        <div className={styles.alertText}>{text}</div>
        {children}
      </div>
    )
  )
}

AlertBox.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  gutterBottom: PropTypes.bool
}

export default AlertBox