import React, { useEffect, useState } from 'react';
import { A, navigate } from 'hookrouter';
import { CheckUser } from "../../partials/checkUser";
import {useRoutes} from 'hookrouter';

import Items from './Items';
import Shipping from './Shipping';
import Billing from './Billing';
import Review from './Review';
import Finish from './Finish';

import NotFoundPage from '../NotFoundPage/';

function Shop(props) {
  const [user, setUser] = useState({});

  const onload = async () => {
    let user = await CheckUser(props.Auth);
    if (user) {
      setUser(user);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    onload();
  }, []);

  const routes = {
    '/items': () => <Items {...props} />,
    '/items/*': () => <Items {...props} />,
    '/shipping': () => <Shipping {...props} />,
    '/billing': () => <Billing {...props} />,
    '/review': () => <Review {...props} />,
    '/finish': () => <Finish {...props} />,
    '/finish/*': () => <Finish {...props} />
  };
  const routeResult = useRoutes(routes);
  return routeResult || <NotFoundPage />;
}

export default Shop;
