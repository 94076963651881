import React, { useEffect, useState } from 'react';
import moment from 'moment';
const FormHelper = {
  DispatchSelector: (args) => {
    let startAt = moment('2020-01-01 00:00:00');
    return (<select {...args}>
      {[...Array(48).keys()].map((n) => {
        let value = startAt.format('HH:mm');
        let ret;
        if (value == args.defaultValue) {
          ret  = <option key={n} selected="selected" value={value}>{value}</option>;
        } else {
          ret  = <option key={n} value={value}>{value}</option>;
        }
        startAt = startAt.add(30, 'minutes');
        return ret;
      })}
    </select>);
  },

  TimeSelector: (args) => {
    let startAt = moment('2020-01-01 00:00:00');
    let minStartAt = moment('2020-01-01 00:00:00');
    let values = String(args.values).split(':');
    const minNum = 60 / parseInt(args.period || 5);
    return (<>
      <select id={args.name + '_start'} {...args} value={values[0]}>
        {[...Array(24).keys()].map((n) => {
          let value = startAt.format('HH');
          let ret = <option key={n} value={value}>{value}</option>;
          startAt = startAt.add(60, 'minutes');
          return ret;
        })}
      </select><div>{args.separator || ''}</div>
      <select id={args.name + '_end'} {...args} value={values[1]}>
        {[...Array(minNum).keys()].map((n) => {
          let value = minStartAt.format('mm');
          let ret = <option key={n} value={value}>{value}</option>;
          minStartAt = minStartAt.add(args.period || 5, 'minutes');
          return ret;
        })}
      </select>{args.postfix || ''}
      </>
    );
  },
  FrequencySelector: (args) => {
    return (<select {...args}>
      {[{name: '5分', value: 5}, {name: '1時間', value: 60}].map((n) => {
        let ret = <option key={n.value} value={n.value}>{n.name}</option>;
        return ret;
      })}
    </select>);
  },
  SettingSelector: (type, args) => {
    let data = [], startAt, endAt, period, postfix;
    switch(type){
    case 'temperature':
      startAt = -10;
      postfix = '℃';
      endAt = 60;
      period = 1;
      break;
    case 'moisture':
    case 'humidity':
      startAt = 10;
      endAt = 100;
      period = 1;
      postfix = '%';
      break;
    case 'illuminance':
      startAt = 0;
      endAt = 83865;
      period = 10;
      postfix = 'lx';
      break;
    case 'waterlevel':
      startAt = 0;
      endAt = 3;
      period = 1;
      postfix = '';
      break;
    }
    for (startAt; startAt <= endAt; startAt += period) {
      data.push(startAt);
    }
    
    if (type === 'illuminance') { 
      return (<select {...args}>
        {[0, 1, 5, 10, 100, 1000, 10000, 50000, endAt].reverse().map((n) => {
          let ret = <option key={n} value={n}>{n}{postfix}</option>;
          return ret;
        })}
      </select>);
    } else if (type === 'waterlevel') {
      const label = { 0: '水位なし', 1: '低水位', 2: '中水位', 3: '高水位'}
      return (<select {...args}>
        {data.reverse().map((n) => {
          let ret = <option key={n} value={n}>{`${n}（${label[n]}）`}</option>;
          return ret;
        })}
      </select>);
    } else {
      return (<select {...args}>
        {data.reverse().map((n) => {
          let ret = <option key={n} value={n}>{n}{postfix}</option>;
          return ret;
        })}
      </select>);
    }
  },
  RouterSelector: (list, args) => {
    return (<select {...args}>
      <option>----</option>
      {list.map((router) => {
        let ret = <option key={router.router_id} value={router.router_id}>{router.name}</option>;
        return ret;
      })}
    </select>);
  },

  numberSelector: (args) => {
    return (<select {...args}>
      {[...Array(args.num).keys()].map((n) => {
        let ret = <option key={n} value={n}>{n}</option>;
        return ret;
      })}
    </select>);
  },
};

export{FormHelper};;
