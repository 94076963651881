import React, { useEffect, useState } from "react";
import axios from 'axios';
import { A, navigate } from "hookrouter";
import PasswordStrengthBar from 'react-password-strength-bar';
import { ValidateUser } from '../../partials/validateUser';
import Button from "../../partials/Button";
import Field from "../../partials/Field";
import Input from "../../partials/Input";
import styles from "./Profile.module.sass";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import { Config } from '../../config';

function Profile(props) {
  const [sid, setSid] = useState(null);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [debounce, setDebounce] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [mail, setMail] = useState('');

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('expired')) {
      setIsExpired(true);
    } else {
      setSid(url.searchParams.get('sid'));
    }
    if (url.searchParams.get('mail')) {
      setMail(url.searchParams.get('mail'));
    }
  }, []);

  const submit = async (e) => {
    if (debounce) { return; }
    setDebounce(true);
    setErrorMessages({});
    let errors = {};

    //ユーザー名のバリデーション
    errors.username = ValidateUser.validateUsername(username);

    //パスワードのバリデーション
    errors.password = ValidateUser.validatePassword(password);

    let hasError = false;
    for (let key in errors) {
      if (errors[key].length > 0) {
        hasError = true;
      }
    }

    if (hasError) {
      setErrorMessages(errors);
      setDebounce(false);
      return;
    } else {
      try {
        const res = await axios.post(`${Config.api_url_base2}/update_profile/${sid}`, {password: password, username: username})
        if (res.error && res.message == 'expired') {
          setIsExpired(true);
        } else {
          try {
            const user = await props.Auth.signIn(mail, password);
          } catch(e) {
          }
          navigate("/", false);
        }
      } catch(e) {
        setErrorMessages({username:['通信エラーが発生しました']});
        setDebounce(false);
      }
    }
  };

  return (
    <>
      <Wrap>
        <A href="/">
          <div className={styles.headerLogo}><img src="/assets/images/word_logo.svg" alt="logo" width="79"/></div>
        </A>
        <div className={styles.inputProfileTitle}>
          <Text variant="h1" center>
            お客様の情報を
            <br />
            入力してください
          </Text>
        </div>
        {!isExpired &&
          <>
            <Field label="メールアドレス">
            <Input
                autocomplete="username"
                type="email"
                value={mail}
                disabledStyle
              />
            </Field>
            <Field label="お名前" required>
            <Input
                type="text"
                autocomplete="name"
                placeholder="例）鈴木てる子"
                errorMessages={errorMessages.username}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                name="placeholder-name"
                value={username}
              />
            </Field>
            <Field label="パスワード" required>
              <Input
                type="password"
                maxLength="64"
                name="placeholder-password"
                autocomplete="new-password"
                errorMessages={errorMessages.password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
            </Field>
            <div className={styles.passwordDetailText}>数字、英字(小)、英字(大)を全て含む8文字以上</div>
            <PasswordStrengthBar
              style={{marginTop: "4px"}}
              password={password}
              scoreWords={['弱い - 推測されやすいパスワードです', '弱い - 推測されやすいパスワードです', '良い - 比較的推測されにくいパスワードです', '非常に良い - 推測されにくいパスワードです', '最高 - 推測ができず、とても堅牢なパスワードです']}
              scoreWordStyle={{color: "#2D2E31"}}
              shortScoreWord='数字、英字(小)、英字(大)を全て含む8文字以上'
              minLength={8}
            />
            <div className={styles.action}>
              <Button disabled={debounce} onClick={submit} fit  positive>
                登録する
              </Button>
            </div>
          </>
        }
        {/* 認証URLが期限切れの場合 */}
        {isExpired &&
          <>
            <div className={styles.errorText}>このURLは現在ご利用できません。再度、登録手続きを行ってください。</div>
            <div className={styles.errorButton}>
              <Button fit primary onClick={() => navigate("/")}>
                トップページへ
              </Button>
            </div>
          </>
        }
      </Wrap>
    </>
  );
}

export default Profile;
