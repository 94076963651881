import { mdiCreditCard } from "@mdi/js";
import Icon from "@mdi/react";
import { A, navigate } from "hookrouter";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "./Button";
import Modal from "./Modal";
import styles from "./PaymentMethodInformation.module.sass";
import Text from "./Text";

PaymentMethodInformation.propTypes = {
  type: PropTypes.string,
  cardNumber: PropTypes.string,
  carrier: PropTypes.string,
  billingAddressName: PropTypes.string,
  billingAddressZipcode: PropTypes.string,
  billingAddress: PropTypes.string,
  billingAddressPhoneNumber: PropTypes.string,
};

function PaymentMethodInformation({
  type,
  cardNumber,
  carrier,
  billingAddress,
  billingAddressName,
  billingAddressPhoneNumber,
  billingAddressZipcode,
}) {
  const [isEditingPayment, setEditingPayment] = useState(false);
  const startEditingPayment = () => {
    setEditingPayment(true);
  };

  const endEditingPayment = () => {
    setEditingPayment(false);
  };
  return (
    <>
      <div className={styles.component}>
        <div className={styles.paymentMethodWrap}>
          <div className={styles.paymentMethod}>
            <div className={styles.label}>
              {type === "credit" && "クレジットカード"}
              {type === "carrier" && "キャリア決済"}
              {type === "GMO掛け払い" && "請求書払い"}
            </div>
            <div className={styles.detail}>
              {type === "credit" && (
                <Icon
                  size={1}
                  path={mdiCreditCard}
                  style={{ marginRight: 5 }}
                  color="#777"
                />
              )}
              {type === "credit" && cardNumber}
              {type === "carrier" && carrier}
            </div>
          </div>
          <div>
            <Button size="small" onClick={startEditingPayment}>
              変更
            </Button>
          </div>
        </div>
        {type === "GMO掛け払い" && (
          <>
            <div className={styles.note}>
              当月の利用料の請求書を翌月にお送りします。お支払い期限日までにお支払いください。
            </div>
            <div className={styles.divider} />
            <Text>
              {billingAddressName}
              <br />
              {billingAddressZipcode}
              <br />
              {billingAddress}
              <br />
              {billingAddressPhoneNumber}
              <br />
            </Text>
            <Text variant="note">
              ※ご請求書送付先を変更したい場合は
              <A href="/contact" className={styles.link}>
                お問い合わせ
              </A>
              からご連絡をお願いします。
            </Text>
          </>
        )}
      </div>

      <Modal
        show={isEditingPayment}
        type="Dialog"
        title="お支払い方法を変更しますか？"
      >
        <Text gutterBottom>
          毎月のご請求が、新しいお支払い方法に変更されます。
        </Text>
        <div>
          <Button
            primary
            onClick={endEditingPayment}
            fit
            onClick={() => navigate("/payment/edit")}
          >
            変更へ進む
          </Button>
          <Button onClick={endEditingPayment} fit>
            キャンセル
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default PaymentMethodInformation;
