import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.sass';

const Checkbox = ({
  label,
  checked,
  ...props
}) => (
  <label className={styles.wrap}>
    <input
      {...props}
      className={styles.check}
      type="check"
    />
    <div className={cx(
      styles.text,
      checked ? styles.checked : null
    )}>
      {label}
      </div>
  </label>
)

Checkbox.propTypes = {
  style: PropTypes.shape({}),
  label: PropTypes.string,
  checked: PropTypes.bool
}

Checkbox.defaultProps = {
  style: undefined,
  checked: false,
}


export default Checkbox
