import PropTypes from "prop-types";
import React, { useState } from "react";
import Box from "./Box";
import ButtonClose from "./ButtonClose";
import styles from "./CampaignCard.module.sass";
import Checkbox from "./Checkbox";
import Modal from "./Modal";

function CampaignCard({ title, description, detail, checked, handleClick }) {
  const [isShowingCampaigne, setIsShowingCampaign] = useState(false);
  return (
    <div className={styles.wrap}>
      <Box basic>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{description}</div>
        <div
          className={styles.detailButton}
          onClick={() => setIsShowingCampaign(true)}
        >
          詳細を見る
        </div>
        <div className={styles.checkBox}>
          <Checkbox label="キャンペーンを適用する" checked={checked} onClick={handleClick} />
        </div>
      </Box>

      <Modal show={isShowingCampaigne} type="Modal">
        <div className={styles.modalWrap}>
          <div className={styles.modalHeader}>
            <div className={styles.headerCloseButton}>
              <ButtonClose onClick={() => setIsShowingCampaign(false)} />
            </div>
          </div>
          <div className={styles.modalBody}>{detail}</div>
        </div>
      </Modal>
    </div>
  );
}

Checkbox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  detail: PropTypes.node,
};

export default CampaignCard;
