import React, { useState, useEffect } from 'react';
import { A, navigate } from 'hookrouter';
import PasswordStrengthBar from 'react-password-strength-bar';
import AlertBox from '../../partials/AlertBox';
import { ValidateUser } from '../../partials/validateUser';
import { Session } from '../../partials/session';
import { Backdrop } from '../../partials/backdrop';
import { EncryptPassword } from "../../partials/encryptPassword";
import Button from "../../partials/Button";
import Field from "../../partials/Field";
import Input from "../../partials/Input";
import styles from "./PasswordForm.module.sass";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";

export const useDidMount = (func) => useEffect(() => { func() }, []);
function PasswordForm(props) {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [label, setLabel] = useState('送信中');
  const [progress, setProgress] = useState(10);
  const [errorMessages, setErrorMessages] = useState({});
  const [isExpired, setIsExpired] = useState(false);

  useDidMount(async () => {
    let url = new URL(window.location.href);
    let paths = url.pathname.split('/');
    const session_id = paths[2];
    const sessionData = await Session.read(session_id);
    if (!sessionData) {
      navigate('/password_reset', false);
    } else {
      setEmail(sessionData.email);
      setCode(sessionData.code);
    }
  });

  const resetPassword = async () => {
    setShowAlert(false);
    setProgress(10);
    setShowBackdrop(true);
    if (!password || !passwordConfirmation) {
      setShowAlert(true);
      setErrorMessage('パスワードを入力してください');
      setProgress(100);
      setShowBackdrop(false);
      return;
    } else {
      setProgress(60);
      let errors = {}, hasError = false, errorMessages = [];
      errors.password = ValidateUser.validatePassword(password);
      errors.passwordConfirmation = ValidateUser.validatePasswordConfirmation(password, passwordConfirmation);
      for (let key in errors) {
        if (errors[key].length > 0) {hasError = true;}
        Promise.all(errors[key].map((e) => {errorMessages.push(e);}));
      }
      if (hasError) {
        setErrorMessage(errorMessages.join(' / '));
        setProgress(100);
        setShowAlert(true);
        setShowBackdrop(false);
        return;
      } else {
        setProgress(80);
        try {
          await props.Auth.forgotPasswordSubmit(email, code, password);
          await props.Auth.signIn(email, password);
          await EncryptPassword.encryptPassword(props, password);
          setProgress(100);
          setShowBackdrop(false);
          navigate('/');
        } catch(er) {
          if (er.code === 'ExpiredCodeException') {
            // code が期限切れの場合
            // See https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pool-managing-errors.html
            setIsExpired(true);
          } else {
            setErrorMessage('通信エラーが発生しました');
            setShowAlert(true); // 無かったので追加
          }
          setProgress(100);
          setShowBackdrop(false);
        }
      }
    }
  };

  if (!email) {return <div />;}

  return (
    <>
      <Wrap>
        <A href="/">
          <div className={styles.headerLogo}><img src="/assets/images/word_logo.svg" alt="logo" width="79"/></div>
        </A>
        <div className={styles.inputProfileTitle}>
          <Text variant="h1" center gutterBottom>
            パスワードの変更
          </Text>
        </div>
        {showBackdrop &&
          <Backdrop progress={progress} label={label} show={showBackdrop} />
        }
        {!isExpired &&
          <form id="form" onSubmit={(e) => {
            e.preventDefault();
            resetPassword();
          }}>
            <div className={styles.passwordForm}>
              <Field label="新しいパスワード" >
                <Input
                  type="password"
                  maxLength="64"
                  name="placeholder-password"
                  autoComplete="new-password"
                  errorMessages={errorMessages.password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </Field>
              <div className={styles.passwordText}>数字、英字(小)、英字(大)を全て含む8文字以上</div>
              <PasswordStrengthBar
                style={{marginTop: "4px"}}
                password={password}
                scoreWords={['弱い - 推測されやすいパスワードです', '弱い - 推測されやすいパスワードです', '良い - 比較的推測されにくいパスワードです', '非常に良い - 推測されにくいパスワードです', '最高 - 推測ができず、とても堅牢なパスワードです']}
                scoreWordStyle={{color: "#2D2E31"}}
                shortScoreWord='数字、英字(小)、英字(大)を全て含む8文字以上'
                minLength={8}
              />
            </div>
            <div className={styles.passwordForm}>
              <Field label="新しいパスワード（確認）" >
                <Input
                  type="password"
                  maxLength="64"
                  name="placeholder-password"
                  autoComplete="new-password"
                  errorMessages={errorMessages.password}
                  onChange={(e) => {
                    setPasswordConfirmation(e.target.value);
                  }}
                    value={passwordConfirmation}
                />
              </Field>
              <div className={styles.passwordText}>確認のためもう一度ご入力ください</div>
            </div>
            <div className={styles.buttonArea}>
            {showAlert &&
              <AlertBox gutterBottom text={errorMessage} />
            }
              <div className={styles.action}>
                <Button fit primary type="submit" >
                  変更する
                </Button>
              </div>
            </div>
          </form>
        }
        {/* パスワード変更URLが期限切れの場合 */}
        {isExpired &&
          <>
            <div className={styles.errorText}>このURLは現在ご利用できません。再度パスワード変更を行ってください。</div>
            <div className={styles.errorButton}>
              <Button fit primary onClick={() => navigate("/password_reset")}>
                パスワードをお忘れの方
              </Button>
            </div>
          </>
        }

      </Wrap>
    </>
  );
}

export default PasswordForm;

