import { parsePhoneNumberFromString } from 'libphonenumber-js'
import axios from 'axios';
import { Config } from '../config';

const categories = ['マンゴー農園', '小菊農園', 'その他'];

const list = [
      '北海道',
      '青森県',
      '岩手県',
      '宮城県',
      '秋田県',
      '山形県',
      '福島県',
      '茨城県',
      '栃木県',
      '群馬県',
      '埼玉県',
      '千葉県',
      '東京都',
      '神奈川県',
      '新潟県',
      '富山県',
      '石川県',
      '福井県',
      '山梨県',
      '長野県',
      '岐阜県',
      '静岡県',
      '愛知県',
      '三重県',
      '滋賀県',
      '京都府',
      '大阪府',
      '兵庫県',
      '奈良県',
      '和歌山県',
      '鳥取県',
      '島根県',
      '岡山県',
      '広島県',
      '山口県',
      '徳島県',
      '香川県',
      '愛媛県',
      '高知県',
      '福岡県',
      '佐賀県',
      '長崎県',
      '熊本県',
      '大分県',
      '宮崎県',
      '鹿児島県',
      '沖縄県',
];
const zen = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];
const ValidateUser = {
  zenToHan: (strData) => {
    let formatted = strData.split('').map((str) => {
      let index = zen.indexOf(str);
      if (index >= 0) {
        return String(index);
      } else {
        return str;
      }
    }).join('');
    return formatted;
  },
  numberOnly: (str) => {
    return str.replace(/[^\d]/g, '');
  },
  validateUsername: (username) => {
    let errors = [];
    if (!username) {errors.push('お名前を入力してください');}
    if (username && username.length > 20) {errors.push('お名前は20文字以内で入力してください');}
    return errors;
  },
  formatZipcode: (zipcode) => {
    return ValidateUser.numberOnly(ValidateUser.zenToHan(String(zipcode)));
  },
  validateZipcode: (zipcode) => {
    let errors = [];
    if (!zipcode) {errors.push('郵便番号を入力してください');}
    if (zipcode && zipcode.length < 7) {errors.push('郵便番号が短すぎます');}
    if (zipcode && zipcode.length > 10) {errors.push('郵便番号が長すぎます');}
    return errors;
  },
  prefectures: () => {
    return list;
  },
  categories: () => {
    return categories;
  },
  validateAddress1: (address1) => {
    let errors = [];
    const list = ValidateUser.prefectures();
    if (!address1) {errors.push('ご住所（都道府県）を入力してください');}
    if (address1 && list.indexOf(address1) < 0) {errors.push('正しいご住所（都道府県）を入力してください');}
    return errors;
  },
  validateAddress2: (address2) => {
    let errors = [];
    if (!address2) {errors.push('ご住所（市区町村）を入力してください');}
    if (address2 && address2.length > 20) {errors.push('正しいご住所（市区町村）を入力してください');}
    return errors;
  },
  validateAddress3: (address3) => {
    let errors = [];
    if (!address3) {errors.push('ご住所を入力してください');}
    if (address3 && address3.length > 100) {errors.push('正しいご住所を入力してください');}
    return errors;
  },
  validateCompany: (company) => {
    let errors = [];
    if (company && company.length > 100) {errors.push('会社名が長すぎます');}
    return errors;
  },
  displayPhoneNumber: (phone_number) => {
    if (phone_number) {
      const phoneNumber = parsePhoneNumberFromString(phone_number, 'JP');
      return phoneNumber.formatNational();
    } else {
      return '';
    }
  },
  formatPhoneNumber: (phone_number) => {
    if (phone_number) {
      phone_number = ValidateUser.numberOnly(ValidateUser.zenToHan(phone_number));
      const phoneNumber = parsePhoneNumberFromString(phone_number, 'JP');
      return phoneNumber.number;
    } else {
      return null;
    }
  },
  validatePhoneNumber: (phone_number) => {
    let errors = [];
    if (phone_number) {
      try {
        const phoneNumber = parsePhoneNumberFromString(phone_number, 'JP');
        if (!phoneNumber.isValid()) {
          errors.push('電話番号の形式に誤りがあります');
        }
        if (phone_number.length > 13) {
          errors.push('電話番号が長すぎます');
        }
      } catch (e) {
        errors.push('電話番号の形式に誤りがあります');
      }
    } else {
      errors.push('電話番号を入力してください');
    }
    return errors;
  },
  validateEmail: (email) => {
    let errors = [];
    if (!email) {errors.push('メールアドレスを入力してください');}
    if (email && email.length > 50) {errors.push('メールアドレスが長すぎます');}
    if (!email.match(/.+@.+\..+/)) {errors.push('メールアドレスの書式に誤りがあります');}
    return errors;
  },
  validateEmailConfirmation: (email, email_confirmation) => {
    let errors = [];
    if (!email_confirmation) {errors.push('メールアドレスを入力してください');}
    if (email_confirmation && email && email_confirmation != email) {errors.push('メールアドレスが一致しません');}
    return errors;
  },
  validatePassword: (password) => {
    let errors = [];
    if (!password) {errors.push('パスワードを入力してください');}
    if (password && password.length > 64) {errors.push('パスワードが長すぎます');}
    if (password && password.length < 8) {errors.push('パスワードが短すぎます');}
    // https://github.com/tel-chan/tel-chan/issues/1268 記号は必須ではない
    //if (password && !password.match(/[+\^$*.\[\]\{\}\(\)\?\-\"\!@#%\&\/\\,><':;|_~`]/)) {errors.push('パスワードは半角英数字と記号で指定してください');}
    if (password && !password.match(/^[a-zA-Z0-9!-/:-@\[-`{-~]*$/)) {
      errors.push('パスワードは半角英数字と記号が利用可能です');
    }
    // 大文字小文字数字が必ず混在すること
    if (password && !password.match(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/)) {
      errors.push('数字、英字(小)、英字(大)を全て含む8文字以上にしてください');
    }
    return errors;
  },
  validatePasswordConfirmation: (password, password_confirmation) => {
    let errors = [];
    if (!password_confirmation) {
      errors.push('パスワードを入力してください');
    } else if(password != password_confirmation) {
      errors.push('パスワードが一致しません');
    }
    return errors;
  },
  validateCategory: (category) => {
    let errors = [];
    if (categories.indexOf(category) < 0) {
      errors.push('ご利用目的を選択してください');
    }
    return errors;
  },
  checkUserExists: async (params) => {
    const url = Config.api_url_base + '/check_user';
    let data = await axios.post(url, JSON.stringify(params), { headers: { 'Content-Type': 'application/json' } });
    return data;
  },
  checkIfUserExists: async (params) => {
    const url = Config.api_url_base + '/check_user';
    let data = await axios.post(url, JSON.stringify(params), { headers: { 'Content-Type': 'application/json' } });
    return data.data.Users.length;
  },
}

export{ValidateUser};
