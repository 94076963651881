import React, { useEffect, useState } from 'react';
import { A, navigate } from 'hookrouter';
import { CheckUser } from '../../partials/checkUser';
import AuthorizedHeader from '../../partials/AuthorizedHeader';
import Button from '../../partials/Button';
import Footer from '../../partials/Footer';
import Text from '../../partials/Text';
import Wrap from '../../partials/Wrap';
import styles from './index.module.sass';
import PageTitle from '../../partials/PageTitle';
export const useDidMount = (func) => useEffect(() => { func() }, []);

function Unsubscribe(props) {
  const [user, setUser] = useState({});
  const [alertToList, setAlertToList] = useState([]);
  const [alertToId, setAlertToId] = useState('');
  const [alertTo, setAlertTo] = useState(null);

  const onload = async () => {
    let user = await CheckUser(props.Auth);
    if (user) {
      setUser(user);
      const session = await props.Auth.currentSession();
    } else {
      navigate('/', false);
    }
  };

  useDidMount(async () => {
    onload();
  });

  return (
    <>
      <AuthorizedHeader current="menu" />
      <Wrap>
        <PageTitle title="ご解約" backURL="/menu" />
        <Text variant="h3">解約前にご確認ください</Text>
        <div className={styles.text}>解約するとすべてのデータが削除され、復旧はできません。</div>
        <div className={styles.warningMessageWrap}>
          <div className={styles.warningMessage}>
            <div className={styles.warningMessageIcon}>
              <img src="/assets/images/icon-warning.svg" alt="注意アイコン" />
            </div>
            <div className={styles.warningMessageContents}>
              <div className={styles.warningMessageTitle}>ご購入した全ての機器は使用できなくなります。</div>
              <div className={styles.warningMessageText}>ルーターおよびセンサーとの通信ができなくなり、センサー測定値を取得できなくなります。また、ご解約後の機器は、ご自身による再利用、他者への譲渡のいずれもできません。</div>
            </div>
          </div>
          <div className={styles.warningMessage}>
            <div className={styles.warningMessageIcon}>
              <img src="/assets/images/icon-warning.svg" alt="注意アイコン" />
            </div>
            <div className={styles.warningMessageContents}>
              <div className={styles.warningMessageTitle}>通知が届かなくなり、全ての設定が削除されます。</div>
              <div className={styles.warningMessageText}>通知やセンサーの設定など、すべての情報が削除されます。電話・SMS・メールなど全ての通知手段においての通知が停止します。</div>
            </div>
          </div>
          <div className={styles.warningMessage}>
            <div className={styles.warningMessageIcon}>
              <img src="/assets/images/icon-warning.svg" alt="注意アイコン" />
            </div>
            <div className={styles.warningMessageContents}>
              <div className={styles.warningMessageTitle}>月額料金は解約月まで発生します。</div>
              <div className={styles.warningMessageText}>解約は、解約日にかかわらず月末解約となり、満額発生します。また、月末日にお申し込みの場合、解約日が翌月末になる場合があります。</div>
            </div>
          </div>
          <div className={styles.warningMessage}>
            <div className={styles.warningMessageIcon}>
              <img src="/assets/images/icon-warning.svg" alt="注意アイコン" />
            </div>
            <div className={styles.warningMessageContents}>
              <div className={styles.warningMessageTitle}>解約月の翌々月まで請求がある場合があります</div>
              <div className={styles.warningMessageText}>SMS通知料、電話通知料金は、利用翌月の初旬に確定し、利用翌月の請求です。そのため請求日が解約日よりあとになる場合があります。</div>
            </div>
          </div>
        </div>
        <div className={styles.tips}>
          <div className={styles.tipsTitle}>一時的な利用停止ですか？</div>
          <div className={styles.tipsText}>
            機器の管理から、一時的な月額利用の停止が可能です。停止中の機器は月額費用を抑えることができます。
          </div>
          <div className={styles.deviceButton}>
            <Button onClick={() => {navigate('/devices', false);}}>機器の管理へ</Button>
          </div>
        </div>
        <div className={styles.action}>
          <div className={styles.buttonWrap}>
            <Button fit negative onClick={() => {navigate('/unsubscribe/confirm', false);}}>解約の手続きにすすむ</Button>
          </div>
        </div>
      </Wrap>
      <Footer />
    </>
  );
}

export default Unsubscribe;
