import { navigate } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import AuthorizedHeader from '../../partials/AuthorizedHeader';
import Box from '../../partials/Box';
import Button from '../../partials/Button';
import Field from '../../partials/Field';
import Footer from '../../partials/Footer';
import PageTitle from '../../partials/PageTitle';
import Select from '../../partials/Select';
import Text from '../../partials/Text';
import Textarea from '../../partials/Textarea';
import Wrap from '../../partials/Wrap';
import { Backdrop } from '../../partials/backdrop';
import { CheckUser } from '../../partials/checkUser';
import styles from './index.module.sass';
export const useDidMount = (func) => useEffect(() => { func() }, []);

function Contact(props) {
  const [user, setUser] = useState({});
  const [category, setCategory] = useState('端末について');
  const [text, setText] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showBd, setShowBd] = useState(false);
  const [label, setLabel] = useState('');
  const [progress, setProgress] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const categories = [
    '端末について',
    'ご契約について',
    '通知について',
    '申し込みについて',
    '料金・支払いについて',
    'その他'
  ];

  const onload = async () => {
    let currentUser = await CheckUser(props.Auth);
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate('/', false);
    }
  };

  useDidMount(async () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    if (params.has('message')) {
      setShowMessage(true);
    }
    onload();
  });

  const sendMail = async (data) => {
    setShowBd(true);
    setLabel('送信中');
    setProgress(20);
    const session = await props.Auth.currentSession();
    setProgress(30);
    await props.API.post('SendContactMail', '/contact', {
      headers: {Authorization: session.idToken.jwtToken}, body: data});
    setProgress(80);
    setLabel('送信完了');
    setShowBd(false);
    window.scrollTo(0, 0);
    navigate('/contact/sent', false);
  };

  return (
    <>
      <AuthorizedHeader current="menu" user={user} simple />
    {showBd &&
      <Backdrop 
        show={showBd}
        label={label}
        progress={progress}
        onHide={() => {}}
      />
    }

    {showConfirm &&
      <Wrap>
        <PageTitle title="お問い合わせ" subTitle="内容をご確認ください。" />

        <Text variant="h4">ご相談のカテゴリ</Text>
        <Text gutterBottom>{category}</Text>

        <Text variant="h4" gutterTop>メールアドレス</Text>
        <div data-private>
          <Text gutterBottom>{user.attributes.email}</Text>
        </div>

        <Text variant="h4" gutterTop>ご相談内容</Text>
        <Text gutterBottom className={styles.contactDetail}>{text}</Text>

        <div className={styles.action}>
          <Button
            positive
            fit 
            onClick={()=>{
              const data = {
                category: category,
                text: text,
              };
              sendMail(data);
            }}>送信する</Button>
          <Button
            fit 
            onClick={()=>{
            setShowConfirm(false);
            }}>前のページへ戻る</Button>
        </div>          
      </Wrap> 
    }
    {!showConfirm &&
      <Wrap>
        <PageTitle title="お問い合わせ" backURL="/menu" />
        {showMessage &&
          <Alert style={{borderRadius:0}} variant="info">メッセージを送信しました</Alert>
        }
        <Box basic>
          <div className={styles.description}>
            <Text variant="h4">お問い合わせの前に</Text>
            <Text>お客さまからの「よくある質問」をご用意しております。ぜひご活用ください。</Text>
            <a className={styles.descriptionLink} target="_blank" href="https://tel-chan.zendesk.com/hc/ja/sections/900000536706-%E3%81%94%E8%B3%AA%E5%95%8F-FAQ-">よくある質問</a>
          </div>
          <Field label="ご相談のカテゴリー">
            <Select size="260" value={category} onChange={(e) => {setCategory(e.target.value)}}>
              {categories.map((c) => {
                return <option key={c} value={c}>{c}</option>;
              })}
            </Select>
          </Field>
          <Field label="ご相談内容" errorMessage={errorMessage}>
            <Textarea error={showError} value={text} onChange={(e) => {setText(e.target.value)}} />
          </Field>
          <div className={styles.action}>
            <Button primary fit onClick={()=>{
              setShowError(false);
              if (category && text) {
                setShowConfirm(true);
              } else {
                setErrorMessage('お問い合わせ内容を記載してください');
                setShowError(true);
              }
            }}>送信内容を確認</Button>
          </div>
        </Box>
      </Wrap>
    }
    <Footer />
    </>);
}

export default Contact;
