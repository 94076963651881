import React, { useEffect, useState } from 'react';
import { A, navigate } from 'hookrouter';
import { CheckUser } from '../partials/checkUser';
import { UserList } from '../partials/userList';
export const useDidMount = (func) => useEffect(() => { func() }, []);

function SignOut(props) {
  const [user, setUser] = useState({});

  const onload = async () => {
    let currentUser = await CheckUser(props.Auth);
    if (currentUser) {
      const session = await props.Auth.currentSession();
      const signout = await props.API.post('SignOut', '/signout', {
        headers: {Authorization: session.idToken.jwtToken}, body: {
          refreshToken: session.refreshToken
        }});
      if (!signout.error) {
        props.Auth.signOut({global: true})
          .then(() => {navigate('/', false);})
          .catch((err) => {navigate('/', false);})
      } else {
        navigate('/', false); //FIXME
      }
    } else {
      navigate('/', false);
    }
  };

  useDidMount(async () => {
    onload();
  });

  return (<div className="Content-with-footerbar">
    </div>);
}

export {SignOut};
