import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import DeviceIcon from "./DeviceIcon";
import styles from "./DevicesNetworkStatus.module.sass";
import RssiStatus from "./RssiStatus";

function DevicesNetworkStatus({ rssiBLE, rssiLTE }) {
  return (
    <div className={styles.component}>
      <DeviceIcon type="sensor" size={52} />
      <div
        className={cx(
          styles.wrap,
          -84 >= rssiBLE && rssiBLE > -90 ? styles.weak : null,
          rssiBLE <= -90 ? styles.unstable : null
        )}
      >
        <div className={styles.title}>Bluetooth</div>
        <div className={styles.status} />
        <div className={styles.text}>
          {RssiStatus({
            rssi: rssiBLE,
            labelOnly: true,
            type: "device",
          })}
        </div>
      </div>
      <img
        src="/assets/images/icon-router-blue.png"
        alt="親機ルーター"
        className={styles.routerImage}
      />
      <div
        className={cx(
          styles.wrap,
          -80 >= rssiLTE && rssiLTE > -90 ? styles.weak : null,
          rssiLTE <= -90 ? styles.unstable : null
        )}
      >
        <div className={styles.title}>LTE</div>
        <div className={styles.status} />
        <div className={styles.text}>
          {RssiStatus({
            rssi: rssiLTE,
            labelOnly: true,
            type: "router",
          })}
        </div>
      </div>
      <img
        src="/assets/images/icon-network.png"
        alt="ネットワーク"
        className={styles.networkImage}
      />
    </div>
  );
}

DevicesNetworkStatus.propTypes = {
  rssiBLE: PropTypes.number,
  rssiLTE: PropTypes.number,
};

export default DevicesNetworkStatus;
