import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Wrap.module.sass';

function Wrap({ children, dividerBottom, fixedMinimumHeight }){
  return (
    <div className={cx(
      styles.wrap,
      dividerBottom ? styles.dividerBottom : null,
      fixedMinimumHeight ? styles.fixedMinimumHeight : null
    )}>
      {children}
    </div>
  )
}

Wrap.propTypes = {
  children: PropTypes.node,
  dividerBottom: PropTypes.bool,
  fixedMinimumHeight: PropTypes.bool,
}

export default Wrap