import axios from 'axios';
import { Config } from '../config';

const baseUrl = Config.api_url_base + '/session';

const Session = {
  save: async (data) => {
    for (let key in data) {
      if (data[key] === null || data[key] === '' || data[key] === undefined) {
        delete data[key];
      }
    }
    const response = await axios.post(baseUrl, JSON.stringify(data));
    return response.data.session_id;
  },
  read: async (id) => {
    const response = await axios.get(baseUrl + '/' + id);
    return response.data;
  },
  destroy: async (id) => {
    const response = await axios.post(baseUrl + '/' + id, JSON.stringify({}));
    return response.data.session_id;
  },
}

export{Session};
