import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './DeviceIcon.module.sass';

function DeviceIcon({ type, warning, error, inactive, size }){
  return (
    <div className={cx(
      styles.wrap,
      inactive ? styles.inactive : null
    )}>
      <div className={styles.icon}>
        { type === "sensor" && (
          <img src="/assets/images/icon-sensor.png" width={size} alt="子機センサー" />
        )}
        <div className={styles.statusIcon}>
        { warning && (
          <img src="/assets/images/icon-fault.svg" alt="失敗アイコン" width={size * 0.45} />
        )}
        { error && (
          <img src="/assets/images/icon-warning.svg" alt="警告アイコン" width={size * 0.45} />
        )}
        </div>
      </div>
    </div>
  )
}

DeviceIcon.propTypes = {
  type: PropTypes.string,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  inactive: PropTypes.bool,
  size: PropTypes.number
}

DeviceIcon.defaultProps = {
  size: 52
}

export default DeviceIcon