import React from 'react';
import PropTypes from 'prop-types';
import styles from './Field.module.sass';

function Field({ children, label, errorMessage, required }){
  return (
    <div className={styles.wrap}>
      {label &&
      <div className={styles.label}>
        <div>{label}</div>
        {required && (<div className={styles.required}>必須</div>)}
      </div>
      }
      <div className={styles.contents}>
        {children}
      </div>
      {errorMessage && (
        <div className={styles.error}>
          {errorMessage}
        </div>
      )}
    </div>
  )
}

Field.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default Field