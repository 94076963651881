import React from 'react';
import { useRoutes } from "hookrouter";
import { useEffect } from "react";
import Amplify, { Auth, API } from 'aws-amplify';
import { awsconfig } from './aws-exports';
import { EventEmitter } from 'eventemitter3'; 
import { Top } from './containers/Top';
import SignUp from './containers/SignUp';
import Dashboard from './containers/Dashboard/';
import NotFoundPage from './containers/NotFoundPage/';
import Law from './containers/Law/';
import Account from './containers/Account/';
import Contact from './containers/Contact/';
import ContactFinish from './containers/Contact/ContactFinish';
import Devices from './containers/Devices/';
import { EmailResetFinish } from './containers/EmailResetFinish';
import { LineConfirm } from './containers/LineConfirm';
import { ConfirmMail } from './containers/ConfirmMail';
import Ad from './containers/Ad';
import Menu from './containers/Menu/';
import NotificationSettings from './containers/NotificationSettings/';
import PasswordReset from './containers/Password/PasswordReset';
import PasswordForm from './containers/Password/PasswordForm';
import PasswordFormFinish from './containers/Password/PasswordFormFinish';
import Payment from './containers/Payment/';
import PaymentEdit from './containers/Payment/Edit';
import OrderHistory from './containers/OrderHistory';
import OrderHistoryDetail from './containers/OrderHistory/details';
import Shop from './containers/Shop';
import { SignOut } from './containers/Signout';
import SignInTelephone from './containers/SignIn/Telephone';
import SignInTelephoneError from './containers/SignIn/TelephoneError';
import Terms from './containers/Terms/';
import Unsubscribe from './containers/Unsubscribe/';
import UnsubscribeConfirm from './containers/Unsubscribe/Confirm';
import UnsubscribeFinish from './containers/Unsubscribe/Finish';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.sass';

Amplify.configure(awsconfig);

const EE = new EventEmitter();

const routes = {
  '/': () => <Top EE={EE} Amplify={Amplify} API={API} Auth={Auth} />,
  '/dashboard': () => <Dashboard EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/law': () => <Law />,
  '/signout': () => <SignOut EE={EE} Amplify={Amplify} API={API} Auth={Auth} />,
  '/signup*': () => <SignUp EE={EE} Amplify={Amplify} API={API} Auth={Auth} />,
  '/account': () => <Account EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/ad': () => <Ad EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/contact': () => <Contact EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/contact/sent': () => <ContactFinish EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/devices': () => <Devices EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/email_reset_finish': () => <EmailResetFinish EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/line_confirm/*': () => <LineConfirm EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/confirm_email/*': () => <ConfirmMail EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/menu': () => <Menu EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/notification_settings': () => <NotificationSettings EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/password_form/*': () => <PasswordForm EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/password_finish': () => <PasswordFormFinish EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/password_reset': () => <PasswordReset EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/payment': () => <Payment EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/payment/edit': () => <PaymentEdit EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/order_history': () => <OrderHistory EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/order_history/details': () => <OrderHistoryDetail EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/shop*': () => <Shop EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/signin/telephone': () => <SignInTelephone EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/signin/telephone/error': () => <SignInTelephoneError EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/terms': () => <Terms EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/unsubscribe': () => <Unsubscribe EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/unsubscribe/confirm': () => <UnsubscribeConfirm EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
  '/unsubscribe/finish': () => <UnsubscribeFinish EE={EE} Amplify={Amplify} Auth={Auth} API={API} />,
};

function App(props) {
  const routeResult = useRoutes(routes);
  useEffect(() => window.scrollTo(0, 0));
  return routeResult || <NotFoundPage />;
}

export default App;
