import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import cx from "clsx";
import { navigate } from "hookrouter";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Delimiter } from "../../delimiter";
import Button from "../../partials/Button";
import ButtonClose from "../../partials/ButtonClose";
import { CalcMonthlyFee } from "../../partials/CalcMonthlyFee";
import { CalcPrice } from "../../partials/calcPrice";
import { CheckUser } from "../../partials/checkUser";
import DeviceIcon from "../../partials/DeviceIcon";
import { FormHelper } from "../../partials/formHelper";
import Modal from "../../partials/Modal";
import Switch from "../../partials/Switch";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import { Telchan } from "../../Telchan";
import styles from "./NotificationSettingsDetail.module.sass";

function NotificationSettingsDetail(props) {
  const [debounce, setDebounce] = useState(false);
  const [user, setUser] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [list, setList] = useState([]);
  const [settingsList, setSettingsList] = useState(props.settingsList);
  const [settings, setSettings] = useState(() => {
    let obj = {};
    if (props.settings) {
      Object.keys(props.settings).map((key) => {
        obj[key] = props.settings[key];
      });
    }
    return obj;
  });
  const [tmpSwitches, setTmpSwitches] = useState([]);
  const [deviceCount, setDeviceCount] = useState(0);
  const [uplinkCount, setUplinkCount] = useState(0);
  const [currentPriceData, setCurrentPriceData] = useState({});
  const [totalFee, setTotalFee] = useState({});
  const [priceData, setPriceData] = useState({
    current_price:
      props.settings && props.settings.settings
        ? props.settings.settings.current_price
        : 0,
    monthly_fee: 0,
    charged_hours: 0,
  });

  const [conditions, setConditions] = useState([]);
  const [hasWatermark, setHasWatermark] = useState(false);

  //通知条件
  const [notificationType, setNotificationType] = useState("immediate");
  const [notificationTime, setNotificationTime] = useState("00:00");
  const [monitoringInterval, setMonitoringInterval] = useState(60);
  const [monitoringPersist, setMonitoringPersist] = useState(60);
  const [monitoringStartTime, setMonitoringStartTime] = useState("00:00");
  const [monitoringEndTime, setMonitoringEndTime] = useState("00:00");
  //const [monitoredSensorType, setMonitoredSensorType] = useState("temperature");
  const [monitoringMethod, setMonitoringMethod] = useState("range");
  const [monitoringTimes, setMonitoringTimes] = useState([]);
  //const [monitoredSensorValue, setMonitoredSensorValue] = useState(0);
  //const [monitoredSensorValueOption, setMonitoredSensorValueOption] = useState(
  //  "more"
  //);
  const [conditionSetting, setConditionSetting] = useState("all");

  //通知方法
  const [destinationEmailState, setDestinationEmailState] = useState(true);
  const [destinationLineState, setDestinationLineState] = useState(false);
  const [destinationSmsState, setDestinationSmsState] = useState(false);
  const [destinationTelState, setDestinationTelState] = useState(false);
  const [targets, setTargets] = useState([]);

  const setUserProfileToTarget = () => {
    if (settings && settings.targets) {
      settings.targets[0]["email"]["to"] = user.attributes.email;
      settings.targets[0]["phone"]["to"] = user.attributes.phone_number;
      settings.targets[0]["sms"]["to"] = user.attributes.phone_number;
      setTargets(settings.targets);
      // bugfix: api/notification_settingsとbackend/devicesでObject形式が違うバグを回避（API側での対応を推奨）
    } else if (settings && settings.settings && settings.settings.targets) {
      const target = Object.assign(settings.settings.targets, {});
      settings.targets = [target];
      settings.targets[0]["email"]["to"] = user.attributes.email;
      settings.targets[0]["phone"]["to"] = user.attributes.phone_number;
      settings.targets[0]["sms"]["to"] = user.attributes.phone_number;
      setTargets(settings.targets);
    } else {
      setTargets([
        {
          email: { status: true, to: user.attributes.email },
          phone: { status: false, to: user.attributes.phone_number },
          sms: { status: false, to: user.attributes.phone_number },
          line: { status: false },
        },
      ]);
    }
  };

  // 料金の詳細を表示
  const [isShowingUsageFeeDetail, setIsShowingUsageFeeDetail] = useState(false);

  // 通知頻度の選択
  const [
    isShowingMonitoringIntervalModal,
    setIsShowingMonitoringIntervalModal,
  ] = useState(false);

  const openMonitoringIntervalModal = () => {
    setIsShowingMonitoringIntervalModal(true);
  };

  const closeMonitoringIntervalModal = (type) => {
    setMonitoringInterval(type);
    // 60分間隔に変更した場合は分を00に変更
    if (type == 60) {
      const startTime = monitoringStartTime.replace(/:[\d]*/, ":00");
      const endTime = monitoringEndTime.replace(/:[\d]*/, ":00");
      setMonitoringStartTime(startTime);
      setMonitoringEndTime(endTime);
    }
    setIsShowingMonitoringIntervalModal(false);
  };

  // すぐに通知・あとで通知の選択モーダル
  const [isShowingNotificationTypeModal, setIsShowingNotificationTypeModal] =
    useState(false);

  const openNotificationTypeModal = () => {
    setIsShowingNotificationTypeModal(true);
  };

  const closeNotificationTypeModal = (type) => {
    setNotificationType(type);
    if (
      (notificationTime == "99:99" || notificationTime == "") &&
      type == "delayed"
    ) {
      // 即時通知から後で通知に更新した
      setNotificationTime("00:00");
    }
    setIsShowingNotificationTypeModal(false);
  };

  // 同じ異常が続く場合は？の選択モーダル
  const [isShowingMonitoringPersistModal, setIsShowingMonitoringPersistModal] =
    useState(false);

  const openMonitoringPersistModal = () => {
    setIsShowingMonitoringPersistModal(true);
  };

  const closeMonitoringPersistModal = (persist) => {
    setMonitoringPersist(persist);
    setIsShowingMonitoringPersistModal(false);
  };

  // 保存の確認モーダル
  const [isShowingConfirmCloseModal, setIsShowingConfirmCloseModal] =
    useState(false);

  const openConfirmCloseModal = () => {
    setIsShowingConfirmCloseModal(true);
  };

  const closeConfirmCloseModal = () => {
    setIsShowingConfirmCloseModal(false);
  };

  // 電話番号登録を促すモーダル
  const [
    isShowRegisteringPhoneNumberModal,
    setIsShowRegisteringPhoneNumberModal,
  ] = useState(false);

  const openRegisteringPhoneNumberModal = () => {
    setIsShowRegisteringPhoneNumberModal(true);
  };

  const closeRegisteringPhoneNumberModal = () => {
    setIsShowRegisteringPhoneNumberModal(false);
  };

  // 料金の確認モーダル
  const [isShowingConfirmFeeModal, setIsShowingConfirmFeeModal] =
    useState(false);

  // 削除の確認モーダル
  const [isShowDeletingModal, setIsShowDeletingModal] = useState(false);

  const openDeletingModal = () => {
    setIsShowDeletingModal(true);
  };

  const closeDeletingModal = () => {
    setIsShowDeletingModal(false);
  };

  const initCondition = async () => {
    return {
      sensor_type: "temperature",
      sensor_value: getDefaultValue("temperature"),
      operator: "more",
    };
  };

  const updateConditions = async (idx, key, val) => {
    const newConditions = await Promise.all(
      conditions.map(async (cond, i) => {
        if (i == idx) {
          cond[key] = val;
          if (key == "sensor_type") {
            const newVal = await getDefaultValue(val);
            cond["sensor_value"] = newVal;
          }
          return cond;
        } else {
          return cond;
        }
      })
    );
    setConditions(newConditions);
  };

  const calcTotalFee = () => {
    if (!props.settings) {
      return {};
    }
    let next_month_total = 0;
    let this_month_total = 0;
    // 基本料金
    let base_fee_current = parseInt(currentPriceData.base_fee_current);
    let base_fee_next = parseInt(currentPriceData.base_fee_next);
    // 設定ごとの料金
    let fee_current = 0;
    let fee_next = 0;

    // 編集の場合に使うこれまでの料金
    const old_fee =
      parseInt(currentPriceData.base_fee_current) +
      parseInt(currentPriceData.current_fee);
    let result = {};

    settingsList.map((s) => {
      if (
        props.settings.notification_setting_id &&
        s.notification_setting_id == settings.notification_setting_id
      ) {
        // 編集中の設定
      } else {
        // 新規とその他の設定
        fee_current += parseInt(s.settings.price_high_watermark);
        fee_next += parseInt(s.settings.current_price);
      }
    });
    if (props.settings.notification_setting_id) {
      result = {
        next_month_total: base_fee_next + fee_next + priceData["current_price"],
        this_month_total: Math.max(
          base_fee_current + fee_current + priceData["current_price"],
          old_fee
        ),
      };
    } else {
      result = {
        next_month_total: base_fee_next + fee_next + priceData["current_price"],
        this_month_total:
          base_fee_current + fee_current + priceData["current_price"],
      };
    }
    return result;
  };

  const calcPrice = async () => {
    const deviceList = await Promise.all(
      list
        .map((d) => {
          return {
            device_id: d.device_id,
            interval: d.interval,
            status: tmpSwitches[d.device_id],
          };
        })
        .filter((d) => {
          return d.status;
        })
    );
    const priceData = await CalcMonthlyFee({
      monitoringMethod: monitoringMethod,
      monitoringTimes: monitoringTimes || null,
      start_at: monitoringStartTime,
      end_at: monitoringEndTime,
      list: deviceList,
      interval: monitoringInterval,
    });
    return priceData;
  };

  useEffect(() => {
    if (monitoringMethod == "fixed_times") {
      setMonitoringInterval(10);
    }
    if (monitoringStartTime == null || monitoringEndTime == null) {
      return;
    }
    if (list.length <= 0) {
      return;
    }
    updatePrice();
  }, [
    monitoringStartTime,
    monitoringEndTime,
    list,
    monitoringInterval,
    deviceCount,
    monitoringMethod,
    monitoringTimes,
  ]);

  useEffect(() => {
    onload();
  }, []);

  const updatePrice = async () => {
    const newPrice = await calcPrice();
    await setPriceData(newPrice);
  };

  useEffect(() => {
    if (monitoringMethod == "fixed_times") {
      // 新規の場合
      if (!settings.notification_setting_id) {
        if (monitoringTimes.length <= 0) {
          setMonitoringTimes(["12:00"]);
        }
      } else {
        if (!settings.monitoringTimes || settings.monitoringTimes.length <= 0) {
          setMonitoringTimes(["12:00"]);
        }
      }
    } else {
      if (monitoringStartTime == "") {
        setMonitoringStartTime("00:00");
      }
      if (monitoringEndTime == "") {
        setMonitoringEndTime("00:00");
      }
    }
  }, [monitoringMethod]);

  useEffect(() => {
    const newTotalFee = calcTotalFee();
    setTotalFee(newTotalFee);
  }, [currentPriceData, priceData]);

  useEffect(() => {
    if (user) {
      setUserProfileToTarget();
    }
  }, [user]);

  useEffect(() => {
    if (targets.length > 0) {
      const target = targets[0];
      setDestinationEmailState(target["email"]["status"]);
      setDestinationLineState(target["line"]["status"]);
      setDestinationSmsState(target["sms"]["status"]);
      setDestinationTelState(target["phone"]["status"]);
    }
  }, [targets]);

  useEffect(() => {
    setDeviceCount(
      Object.keys(tmpSwitches).filter((d) => {
        return tmpSwitches[d];
      }).length
    );
  }, [tmpSwitches]);

  useEffect(() => {
    setWatermark();
  }, [conditions]);

  //useEffect(() => {
  //  if (monitoredSensorType == 'waterlevel') {
  //    setMonitoringInterval(60);
  //  }
  //}, [monitoredSensorType]);

  const parseList = async (devices) => {
    let oldSettings = {};
    if (settings && settings.devices) {
      settings.devices.map((d) => {
        oldSettings[d.device_id] = true;
      });
    }
    let newSwitches = {};
    if (!devices.devices || devices.devices.length <= 0) {
      setInitialized(true);
      setList([]);
    } else {
      const newList = await Promise.all(
        devices.devices
          .map((d) => {
            if (!d.suspended) {
              newSwitches[d.device_id] = oldSettings[d.device_id]
                ? true
                : false;
              return d;
            }
          })
          .filter((n) => {
            return n;
          })
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
          })
      );
      await setTmpSwitches(newSwitches);
      await setList(newList || []);
      await setInitialized(true);
    }
  };

  const fetchPriceData = async () => {
    const session = await props.Auth.currentSession();
    const data = await props.API.get("PriceData", "/current_fee", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    setCurrentPriceData(data);
  };

  const fetchDevices = async () => {
    const session = await props.Auth.currentSession();
    const devices = await props.API.get("DeviceList", "/devices", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    parseList(devices);
  };

  const onload = async () => {
    const currentUser = await CheckUser(props.Auth);
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate("/");
      return;
    }

    //if (props.settingsList.length <= 0) {
    //  // 念のためAPIから一覧を取得
    //  await fetchNotifications();
    //} else {
    //}

    setNotificationType(settings.notification_type || "immediate");
    if (settings.notification_setting_id) {
      const s = settings.settings;
      setNotificationType(settings.notification_type);
      setMonitoringInterval(s.alert_interval);
      setMonitoringPersist(
        s.alert_persist !== undefined ? s.alert_persist : 60
      );
      setNotificationTime(settings.send_at || "");
      setMonitoringStartTime(settings.start_at || "");
      setMonitoringEndTime(settings.end_at || "");
      setMonitoringMethod(settings.monitoringMethod || "range");
      if (settings.monitoringMethod == "fixed_times") {
        setMonitoringTimes(settings.monitoringTimes);
      }
      //setMonitoredSensorValue(s.sensor_value);
      //setMonitoredSensorType(s.sensor_type);
      //const option = (s.operator == '以上' || s.operator == 'more') ? 'more' : 'less';
      //setMonitoredSensorValueOption(option);
      setNotificationTime(settings.send_at || "");
      if (s.conditions == null) {
        s.conditions = [
          {
            sensor_type: s.sensor_type,
            sensor_value: s.sensor_value,
            operator: s.operator,
          },
        ];
      }
      setConditions(s.conditions);
      setConditionSetting(s.conditionSetting || "all");
    } else {
      const newCondition = [await initCondition()];
      setConditions(newCondition);
      //setDefaultValues('temperature');
      setInitialized(true);
    }
    await fetchDevices();
    await fetchPriceData();
  };

  const fetchNotifications = async () => {
    const session = await props.Auth.currentSession();
    const settings = await props.API.get(
      "NotificationSettings",
      "/notification_settings",
      {
        headers: { Authorization: session.idToken.jwtToken },
      }
    );
    const list = settings.filter((s) => {
      return s.notification_status != 0;
    });
    setSettingsList(list);
  };

  const getType = (name) => {
    switch (name) {
      case "温度":
        return "temperature";
        break;
      case "照度":
        return "illuminance";
        break;
      case "湿度":
        return "humidity";
      case "水位":
        return "watermark";
        break;
    }
  };

  const openConfirmFeeModal = () => {
    closeConfirmCloseModal();
    setIsShowingConfirmFeeModal(true);
  };

  const closeConfirmFeeModal = () => {
    setIsShowingConfirmFeeModal(false);
  };

  const close = () => {
    closeConfirmCloseModal();
    closeConfirmFeeModal();
    props.closeNotificationDetailModal();
  };

  //const setDefaultValues = (type) => {
  //  switch(type) {
  //  case 'temperature':
  //    setMonitoredSensorValue('30');
  //    break;
  //  case 'humidity':
  //    setMonitoredSensorValue('80');
  //    break;
  //  case 'illuminance':
  //    setMonitoredSensorValue('100');
  //    break;
  //  case 'waterlevel':
  //    setMonitoredSensorValue('2');
  //    break;
  //  }
  //};

  const getDefaultValue = (type) => {
    switch (type) {
      case "temperature":
        return "30";
        break;
      case "humidity":
      case "moisture":
        return "80";
        break;
      case "illuminance":
        return "100";
        break;
      case "waterlevel":
      case "watermark":
        return "2";
        break;
    }
  };

  const deleteNotification = (id) => {
    // TODO show dialog
  };

  const _deleteNotification = (id) => {
    // set notification_status = 0
  };

  const updateMonitoringTime = async (e, idx) => {
    const id = e.target.id;
    const cond = id.split("_")[1];
    const value = e.target.value;
    let times = [];
    await Promise.all(
      monitoringTimes.map((t, i) => {
        if (i == idx) {
          let values = t.split(":");
          if (cond == "start") {
            values[0] = value;
          } else {
            values[1] = value;
          }
          times.push(values.join(":"));
        } else {
          times.push(t);
        }
      })
    );
    setMonitoringTimes(times);
  };

  const deleteMonitoringTime = async (idx) => {
    const times = await Promise.all(
      monitoringTimes
        .map((t, i) => {
          if (i != idx) {
            return t;
          }
        })
        .filter((n) => {
          return n;
        })
    );
    setMonitoringTimes(times);
  };

  const appendMonitoringTime = () => {
    setMonitoringTimes(monitoringTimes.concat("12:00"));
  };

  const setWatermark = async () => {
    let result = false;
    await Promise.all(
      conditions.map(async (c) => {
        if (c.sensor_type == "watermark" || c.sensor_type == "waterlevel") {
          result = true;
        }
      })
    );
    setHasWatermark(result);
  };

  const save = async (destroy = false) => {
    if (debounce) {
      return;
    } else {
      setDebounce(true);
    }
    let saveData = {};
    if (!destroy) {
      saveData = {
        notification_setting_id: props.settings.notification_setting_id || null,
        notification_status: destroy ? 0 : 1,
        notification_type: notificationType,
        send_at: notificationTime,
        monitoringMethod: monitoringMethod,
      };
      if (monitoringMethod == "fixed_times") {
        saveData.monitoringTimes = monitoringTimes;
      } else {
        saveData.start_at = monitoringStartTime;
        saveData.end_at = monitoringEndTime;
      }
      const newTargets = {
        email: { status: destinationEmailState },
        line: {
          status:
            notificationType == "immediate" ? destinationLineState : false,
        },
        //sms: {status: notificationType == 'immediate' ? destinationSmsState : false},
        sms: { status: destinationSmsState },
        phone: {
          status: notificationType == "immediate" ? destinationTelState : false,
        },
      };
      let newSettings = {
        alert_interval: monitoringInterval,
        alert_persist: monitoringPersist,
        //sensor_type: monitoredSensorType,
        //sensor_value: monitoredSensorValue,
        //operator: monitoredSensorValueOption,
        targets: newTargets,
        conditions: conditions,
        conditionSetting: conditionSetting,
      };
      saveData.settings = newSettings;
      const deviceList = Object.keys(tmpSwitches)
        .map((device_id) => {
          if (tmpSwitches[device_id]) {
            return device_id;
          }
        })
        .filter((d) => {
          return d;
        });
      saveData.devices = deviceList;

      // 検索しやすいようにimmediateの場合は送信時刻を99:99に
      if (notificationType == "immediate") {
        saveData.send_at = "99:99";
      }
    } else {
      saveData = {
        notification_status: 0,
        notification_setting_id: props.settings.notification_setting_id,
      };
    }

    try {
      const session = await props.Auth.currentSession();
      await props.API.post("NotificationSettings", "/notification_settings", {
        headers: { Authorization: session.idToken.jwtToken },
        body: saveData,
      });
      closeConfirmCloseModal();
      closeConfirmFeeModal();
      props.closeNotificationDetailModal();
      setDebounce(false);
    } catch (e) {
      setDebounce(false);
    }
  };

  const selectAll = () => {
    let newSwitches = {};
    Object.keys(tmpSwitches).map((key) => {
      newSwitches[key] = true;
    });
    setTmpSwitches(newSwitches);
  };

  const deselectAll = () => {
    let newSwitches = {};
    Object.keys(tmpSwitches).map((key) => {
      newSwitches[key] = false;
    });
    setTmpSwitches(newSwitches);
  };

  // FIXME
  if (!initialized) {
    return <div />;
  }
  //if (!initialized) { return <ProgressIndicator fit /> }

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <ButtonClose onClick={() => openConfirmCloseModal()} />
        </div>
        <div className={styles.body}>
          <div
            className={cx(
              styles.monitoringConditions,
              notificationType === "immediate" ? styles.immediate : null,
              notificationType === "delayed" ? styles.specify : null
            )}
          >
            <div className={styles.monitoringForm}>
              <div className={styles.monitoringFormLabel}>監視する方法は？</div>
              <div className={styles.monitoringFormBody}>
                <select
                  className={styles.conditionSettingButton}
                  onChange={(e) => {
                    setMonitoringMethod(e.target.value);
                  }}
                  value={monitoringMethod}
                >
                  <option value="range">特定の時間帯</option>
                  <option value="fixed_times">指定した時刻</option>
                </select>
              </div>
            </div>
            {/* 指定した時刻の場合 */}
            {monitoringMethod === "fixed_times" && (
              <div className={styles.monitoringForm}>
                <div className={styles.monitoringFormLabel}>
                  監視する時刻は？
                </div>
                <div
                  className={cx(styles.monitoringFormBody, styles.specificTime)}
                >
                  {monitoringTimes.map((t, idx) => {
                    return (
                      <div
                        key={`time-${idx}`}
                        className={styles.monitoringTimeButton}
                      >
                        {FormHelper.TimeSelector({
                          onChange: (e) => {
                            updateMonitoringTime(e, idx);
                          },
                          values: t,
                          period: 10,
                          name: `time-${idx}`,
                          separator: "時",
                          postfix: "分",
                          className: styles.conditionSettingButton,
                        })}
                        {/* 設定項目が２つ以上の時に表示する */}
                        {monitoringTimes.length > 1 && (
                          <div
                            className={styles.deleteTimeButtonWrap}
                            onClick={() => {
                              deleteMonitoringTime(idx);
                            }}
                          >
                            <img
                              src="/assets/images/icon-delete-button.svg"
                              alt="削除"
                              width="26"
                              className={styles.monitoringFormDeleteButton}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div
                    className={cx(
                      styles.monitoringFormAddButton,
                      notificationType === "immediate"
                        ? styles.immediate
                        : null,
                      notificationType === "delayed" ? styles.specify : null
                    )}
                    onClick={appendMonitoringTime}
                  >
                    <div>時刻を追加</div>
                  </div>
                </div>
              </div>
            )}
            {/* 特定の時間帯の場合 */}
            {monitoringMethod === "range" && (
              <div className={styles.monitoringForm}>
                <div className={styles.monitoringFormLabel}>
                  監視する時間帯は？
                </div>

                <div className={styles.monitoringFormBody}>
                  {FormHelper.TimeSelector({
                    className: styles.conditionSettingButton,
                    onChange: (e) => {
                      const hour =
                        document.getElementById("start_time_start").value;
                      const min =
                        document.getElementById("start_time_end").value;
                      setMonitoringStartTime(`${hour}:${min}`);
                    },
                    values: monitoringStartTime,
                    name: "start_time",
                    separator: "：",
                    period: monitoringInterval,
                  })}
                  <div className={styles.conditionLabel}>〜</div>
                  {FormHelper.TimeSelector({
                    className: styles.conditionSettingButton,
                    onChange: (e) => {
                      const hour =
                        document.getElementById("end_time_start").value;
                      const min = document.getElementById("end_time_end").value;
                      setMonitoringEndTime(`${hour}:${min}`);
                    },
                    values: monitoringEndTime,
                    name: "end_time",
                    separator: "：",
                    period: monitoringInterval,
                  })}
                </div>
              </div>
            )}
            {monitoringMethod === "range" && (
              <div className={styles.monitoringForm}>
                <div className={styles.monitoringFormLabel}>
                  監視する間隔は？
                </div>
                <div className={styles.monitoringFormBody}>
                  <div
                    onClick={openMonitoringIntervalModal}
                    className={styles.conditionSettingButton}
                  >
                    {monitoringInterval}分ごとに確認
                  </div>
                </div>
              </div>
            )}
            <div className={styles.monitoringForm}>
              <div className={styles.monitoringFormLabel}>
                どんな時に通知する？
              </div>
              {/* 設定項目が複数ある時はListsのclassをつけてください。 */}
              <div
                className={cx(
                  styles.monitoringFormWrap,
                  conditions.length > 1 ? styles.lists : null
                )}
              >
                {conditions.map((cond, idx) => {
                  return (
                    <div
                      key={`form_${idx}`}
                      className={styles.monitoringFormBody}
                    >
                      <div className={styles.conditionSettingWrap}>
                        <div className={styles.conditionSettingArea}>
                          <div className={styles.conditionSettingValue}>
                            <select
                              className={styles.conditionSettingButton}
                              onChange={(e) => {
                                updateConditions(
                                  idx,
                                  "sensor_type",
                                  e.target.value
                                );
                              }}
                              value={cond["sensor_type"]}
                            >
                              <option value="temperature">温度</option>
                              <option value="humidity">湿度</option>
                              <option value="illuminance">照度</option>
                              {Telchan.env === "development" && (
                                <option value="waterlevel">水位</option>
                              )}
                            </select>
                            <div className={styles.conditionLabel}>が</div>
                          </div>
                          <div className={styles.conditionSettingValue}>
                            {FormHelper.SettingSelector(cond["sensor_type"], {
                              className: styles.conditionSettingButton,
                              onChange: (e) => {
                                updateConditions(
                                  idx,
                                  "sensor_value",
                                  e.target.value
                                );
                              },
                              value: cond["sensor_value"],
                            })}
                            <select
                              className={styles.conditionSettingButton}
                              onChange={(e) => {
                                updateConditions(
                                  idx,
                                  "operator",
                                  e.target.value
                                );
                              }}
                              value={cond["operator"]}
                            >
                              <option value="more">以上</option>
                              <option value="less">以下</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* 設定項目が２つ以上の時に表示する */}
                      {conditions.length > 1 && (
                        <div className={styles.ddeleteButtonWrap}>
                          <img
                            src="/assets/images/icon-delete-button.svg"
                            alt="削除"
                            width="26"
                            className={styles.monitoringFormDeleteButton}
                            onClick={() => {
                              const newConditions = conditions
                                .map((c, i) => {
                                  if (i != idx) {
                                    return c;
                                  }
                                })
                                .filter((n) => {
                                  return n;
                                });
                              setConditions(newConditions);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}

                <div
                  className={cx(
                    styles.monitoringFormAddButton,
                    notificationType === "immediate" ? styles.immediate : null,
                    notificationType === "delayed" ? styles.specify : null
                  )}
                  onClick={async () => {
                    const newConditions = conditions.concat(
                      await initCondition()
                    );
                    setConditions(newConditions);
                  }}
                >
                  <Icon size={1.2} path={mdiPlus} />
                  <div>条件を追加</div>
                </div>
              </div>
              {/* 設定項目が２つ以上の時に表示する */}
              {conditions.length > 1 && (
                <select
                  className={cx(
                    styles.conditionSettingButton,
                    styles.conditionSettingTerm
                  )}
                  value={conditionSetting}
                  onChange={(e) => {
                    setConditionSetting(e.target.value);
                  }}
                >
                  <option value="all">すべての条件に一致</option>
                  <option value="any">いずれかの条件に一致</option>
                </select>
              )}
            </div>
            <div className={styles.monitoringForm}>
              <div className={styles.monitoringFormLabel}>いつ通知する？</div>
              <div className={styles.monitoringFormBody}>
                <div
                  onClick={openNotificationTypeModal}
                  className={styles.conditionSettingButton}
                >
                  {notificationType === "immediate" && "すぐに通知"}
                  {notificationType === "delayed" && "あとでまとめて通知"}
                </div>
              </div>
              <div>
                {notificationType === "delayed" && (
                  <div
                    className={cx(styles.monitoringFormBody, styles.gutterTop)}
                  >
                    {FormHelper.TimeSelector({
                      className: styles.conditionSettingButton,
                      onChange: (e) => {
                        const hour =
                          document.getElementById("send_time_start").value;
                        const min =
                          document.getElementById("send_time_end").value;
                        setNotificationTime(`${hour}:${min}`);
                      },
                      values: notificationTime,
                      name: "send_time",
                      separator: "：",
                      period: 10,
                    })}
                    <div className={styles.conditionLabel}>頃</div>
                  </div>
                )}
              </div>
            </div>
            {/* TODO:機能開発後に表示お願いします。 */}
            {notificationType === "immediate" && (
              <div className={styles.monitoringForm}>
                <div className={styles.monitoringFormLabel}>
                  同じ異常が続く場合は？
                </div>
                <div className={styles.monitoringFormBody}>
                  <div
                    onClick={openMonitoringPersistModal}
                    className={styles.conditionSettingButton}
                  >
                    {monitoringPersist === 60 && "1時間は通知しない"}
                    {monitoringPersist === 0 && "毎回通知する"}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.monitoringTelChan}>
              <img
                src="/assets/images/fig-notification-telchan.png"
                alt="まかせて"
                width="89"
              />
            </div>
          </div>
          <Wrap>
            <Text variant="h2" gutterBottom>
              自分への通知
            </Text>

            {/* メール通知 */}
            <div
              className={cx(
                styles.destination,
                destinationEmailState ? styles.active : null
              )}
              onClick={() => setDestinationEmailState(!destinationEmailState)}
            >
              <div className={styles.destinationIcon}>
                <img src="/assets/images/notification/mail.svg" width="46" />
              </div>
              <div className={styles.destinationContent}>
                <div className={styles.destinationTitle}>
                  メールを送る（無料）
                </div>
                <div className={styles.destinationDetail} data-private>
                  {targets[0]["email"]["to"]}
                </div>
              </div>
              <div>
                <Switch checked={destinationEmailState} />
              </div>
            </div>

            {/* LINE通知 */}
            {/* ❌ β版では対応しない */}
            {false && (
              <div
                className={cx(
                  styles.destination,
                  destinationLineState ? styles.active : null
                )}
                onClick={() => setDestinationLineState(!destinationLineState)}
              >
                <div className={styles.destinationIcon}>
                  <img src="/assets/images/notification/line.svg" width="46" />
                </div>
                <div className={styles.destinationContent}>
                  <div className={styles.destinationTitle}>
                    LINEを送る（¥{CalcPrice.lineFee}/回）
                  </div>
                  <div className={styles.destinationDetail}>@tel-chan</div>
                </div>
                <div>
                  <Switch checked={destinationLineState} />
                </div>
              </div>
            )}

            {/* SMS通知 */}
            <div
              className={cx(
                styles.destination,
                destinationSmsState ? styles.active : null
              )}
              onClick={() =>
                targets[0]["sms"]["to"]
                  ? setDestinationSmsState(!destinationSmsState)
                  : openRegisteringPhoneNumberModal()
              }
            >
              <div className={styles.destinationIcon}>
                <img src="/assets/images/notification/sms.svg" width="46" />
              </div>
              <div className={styles.destinationContent}>
                <div className={styles.destinationTitle}>
                  SMSを送る（¥{CalcPrice.smsFee}/回）
                </div>
                <div className={styles.destinationDetail} data-private>
                  {targets[0]["sms"]["to"]
                    ? parsePhoneNumberFromString(
                        targets[0]["sms"]["to"],
                        "JP"
                      ).formatNational()
                    : ""}
                </div>
              </div>
              <div>
                <Switch checked={destinationSmsState} />
              </div>
            </div>

            {/* 電話通知 */}
            {notificationType == "immediate" && (
              <div
                className={cx(
                  styles.destination,
                  destinationTelState ? styles.active : null
                )}
                onClick={() =>
                  targets[0]["phone"]["to"]
                    ? setDestinationTelState(!destinationTelState)
                    : openRegisteringPhoneNumberModal()
                }
              >
                <div className={styles.destinationIcon}>
                  <img src="/assets/images/notification/phone.svg" width="46" />
                </div>
                <div className={styles.destinationContent}>
                  <div className={styles.destinationTitle}>
                    電話する（¥{CalcPrice.phoneFee}/分）
                  </div>
                  <div className={styles.destinationDetail} data-private>
                    {targets[0]["phone"]["to"]
                      ? parsePhoneNumberFromString(
                          targets[0]["phone"]["to"],
                          "JP"
                        ).formatNational()
                      : ""}
                  </div>
                </div>
                <div>
                  <Switch checked={destinationTelState} />
                </div>
              </div>
            )}

            {/* 他の人への通知 */}
            {/* ❌ β版では対応しない */}
            {false && (
              <>
                <Text variant="h2" gutterBottom gutterTop>
                  他の人への通知
                </Text>
                <div className={styles.destinationAdd}>通知先を追加する</div>
              </>
            )}

            {/* 対象センサーの選択 */}
            <Text variant="h2" gutterBottom gutterTop>
              対象のセンサー機器
            </Text>

            <div className={styles.targetSensorSelector}>
              <div
                onClick={selectAll}
                className={styles.targetSensorSelectorButton}
              >
                すべて選択
              </div>
              <div
                onClick={deselectAll}
                className={styles.targetSensorSelectorButton}
              >
                すべて解除
              </div>
            </div>

            {/* 稼働中の親機ルーターが0の時に表示 */}
            {props.routers.length <= 0 && (
              <div className={styles.warning}>
                【注意】稼働中の親機ルーターがありません。常に異常が通知される可能性があります。
              </div>
            )}

            {list.map((device) => {
              return (
                <div
                  key={device.device_id}
                  className={cx(
                    styles.targetSensor,
                    tmpSwitches[device.device_id] ? styles.active : null
                  )}
                  onClick={() => {
                    let newSwitches = {};
                    Object.keys(tmpSwitches).map((key) => {
                      newSwitches[key] =
                        key == device.device_id
                          ? !tmpSwitches[key]
                          : tmpSwitches[key];
                    });
                    setTmpSwitches(newSwitches);
                  }}
                >
                  <div className={styles.targetSensorIcon}>
                    <DeviceIcon type="sensor" size={46} />
                  </div>
                  <div className={styles.targetSensorContent}>
                    <div className={styles.targetSensorTitle}>
                      {device.name}
                    </div>
                    <div className={styles.targetSensorDetail}>
                      {device.device_id}
                    </div>
                    {hasWatermark && !device.sensor_type.match(/w/) && (
                      <div className={styles.targetSensorNote}>
                        水位に対応していません
                      </div>
                    )}
                  </div>
                  <div>
                    <Switch checked={tmpSwitches[device.device_id]} />
                  </div>
                </div>
              );
            })}
            {props.settings && props.settings.notification_setting_id && (
              <div className={styles.notificationDeleteButtonWrap}>
                <div
                  onClick={() => openDeletingModal()}
                  className={styles.notificationDeleteButton}
                >
                  この監視を削除
                </div>
              </div>
            )}
          </Wrap>
          {/* 料金計算と保存 */}
          <div className={styles.action}>
            <div className={styles.notificationFeeArea}>
              {/* 開発完了まで使用 */}
              {false && (
                <>
                  <div className={styles.notificationDailyFee}>
                    <div className={styles.notificationDailyFeeLabel}>
                      監視料金
                    </div>
                    <div className={styles.notificationDailyFeeValue}>
                      <div className={styles.dailyFeeValuePrice}>¥0</div>
                      <div className={styles.dailyFeeValueText}>
                        {" "}
                        /日（目安）
                      </div>
                    </div>
                  </div>
                  <div className={styles.notificationDailyFeeDetailText}>
                    現在、料金はかかりません。課金開始時に改めてお知らせします。(SMS/電話の通知料はかかります)
                  </div>
                </>
              )}

              {/* Dev：（課金ロジック開発完了後公開） */}
              {true && (
                <>
                  <div className={styles.notificationDailyFee}>
                    <div className={styles.notificationDailyFeeLabel}>
                      監視料金
                    </div>
                    <div className={styles.notificationDailyFeeValue}>
                      <div className={styles.dailyFeeValuePrice}>
                        ¥{Delimiter(priceData["current_price"])}
                      </div>
                      <div className={styles.dailyFeeValueText}>
                        {" "}
                        /日（目安）
                      </div>
                    </div>
                  </div>
                  <div className={styles.notificationDailyFeeDetail}>
                    <div
                      className={cx(
                        styles.dailyFeeDetailContent,
                        styles.dailyFeeDetailValue
                      )}
                    >
                      ¥2
                    </div>
                    <img
                      src="/assets/images/icon-multiplication.png"
                      alt="X"
                      width="11"
                      className={styles.dailyFeeDetailContent}
                    />
                    <div className={styles.dailyFeeDetailContent}>
                      <span className={styles.dailyFeeDetailValue}>
                        {priceData["uplink_count"]}回
                      </span>
                      の監視
                    </div>
                    <img
                      src="/assets/images/icon-multiplication.png"
                      alt="X"
                      width="11"
                      className={styles.dailyFeeDetailContent}
                    />
                    <div className={styles.dailyFeeDetailContent}>
                      <span className={styles.dailyFeeDetailValue}>
                        {deviceCount}個
                      </span>
                      のセンサー
                    </div>
                  </div>
                </>
              )}
              <div className={styles.notificationDailyFeeButton}>
                <Button onClick={() => openConfirmFeeModal()} positive fit>
                  保存する
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 通知頻度の選択モーダル */}
      <Modal show={isShowingMonitoringIntervalModal} type="Dialog">
        <div
          onClick={() => closeMonitoringIntervalModal(60)}
          className={styles.selectingButton}
        >
          <div className={styles.selectingButtonTitle}>60分ごとに確認</div>
          <div className={styles.selectingButtonText}>
            <strong>1回の確認につき¥{CalcPrice.uplinkFee}（税込）</strong>
            <br />
            子機センサー毎に監視料金がかかります
          </div>
        </div>
        {!hasWatermark && (
          <div
            onClick={() => closeMonitoringIntervalModal(10)}
            className={styles.selectingButton}
          >
            <div className={styles.selectingButtonTitle}>10分ごとに確認</div>
            <div className={styles.selectingButtonText}>
              <strong>1回の確認につき¥{CalcPrice.uplinkFee}（税込）</strong>
              <br />
              子機センサー毎に監視料金がかかります
            </div>
          </div>
        )}
      </Modal>

      {/* すぐに通知・あとで通知の選択モーダル */}
      <Modal show={isShowingNotificationTypeModal} type="Dialog">
        <div
          onClick={() => closeNotificationTypeModal("immediate")}
          className={styles.selectingButton}
        >
          <div
            className={cx(styles.selectingButtonTitle, styles.immediatetely)}
          >
            すぐに通知
          </div>
          <div className={styles.selectingButtonText}>
            センサーの値を確認した時、異常があれば通知します。
          </div>
        </div>
        <div
          onClick={() => closeNotificationTypeModal("delayed")}
          className={styles.selectingButton}
        >
          <div className={cx(styles.selectingButtonTitle, styles.specify)}>
            あとでまとめて通知
          </div>
          <div className={styles.selectingButtonText}>
            指定した時間に、異常があったことをまとめて通知します。緊急ではないもの、夜間の対応などでご利用ください。
          </div>
        </div>
      </Modal>

      {/* 同じ異常が続く場合は？の選択モーダル */}
      <Modal show={isShowingMonitoringPersistModal} type="Dialog">
        <div
          onClick={() => closeMonitoringPersistModal(60)}
          className={styles.selectingButton}
        >
          <div className={cx(styles.selectingButtonTitle)}>
            1時間は通知しない
          </div>
        </div>
        <div
          onClick={() => closeMonitoringPersistModal(0)}
          className={styles.selectingButton}
        >
          <div className={cx(styles.selectingButtonTitle)}>毎回通知する</div>
          <div className={styles.selectingButtonText}>
            「SMSを送る」「電話する」を選択していると通知料金が増える場合があります
          </div>
        </div>
      </Modal>

      {/* 保存しなくていいかの確認モーダル */}
      <Modal show={isShowingConfirmCloseModal} type="Dialog">
        <Text variant="h2" center gutterBottom>
          この変更は保存されていません。
        </Text>
        <div>
          <Button onClick={() => close()} fit negative>
            保存せず閉じる
          </Button>
          <Button onClick={() => closeConfirmCloseModal()} fit>
            キャンセル
          </Button>
        </div>
      </Modal>

      {/* 電話番号の登録を促すモーダル */}
      <Modal show={isShowRegisteringPhoneNumberModal} type="Dialog">
        <Text variant="h2" center gutterBottom>
          先に電話番号を登録してください
        </Text>
        <div>
          <Button onClick={() => navigate("/account")} fit positive>
            設定画面へ
          </Button>
          <Button onClick={() => closeRegisteringPhoneNumberModal()} fit>
            閉じる
          </Button>
        </div>
      </Modal>

      {/* 料金の確認モーダル */}
      <Modal show={isShowingConfirmFeeModal} type="Dialog">
        <div className={styles.monitoringFee}>
          監視料金
          <div className={styles.monitoringFeeWrap}>
            <div className={styles.monitoringFeeValue}>
              ¥{Delimiter(priceData["current_price"])}
            </div>
            <div className={styles.monitoringFeeLabel}>/日（目安）</div>
          </div>
        </div>
        <div className={styles.monitoringFeeTitle}>
          監視の回数に応じて請求されます。監視設定を削除することで、料金がかからなくなります。
        </div>
        <div className={styles.monitoringFeeNote}>
          監視設定は無期限に継続されます。監視設定の継続を希望されない場合は、監視設定でセンサーを監視対象外にしたり、監視設定を削除したりすることで課金を解除できます。
          <br />
          <br />
          監視料金とは別に機器の月額利用料がかかります。利用しなくなった機器を停止して月額利用料を抑えるには［その他］-
          ［機器の管理］画面にて該当機器の［月額利用を停止］の操作をお願いします。詳しくは
          <a href="https://tel-chan.com/law" target="_blank">
            こちら
          </a>
          をご確認ください。
          <br />
          <br />
          機器の購入に際しては、端末代金と別途送料がかかります。
          <br />
          上記以外に、通知料金、確認料金がそれぞれかかります。詳しくは、
          <a href="https://tel-chan.com/law" target="_blank">
            こちら
          </a>
          をご確認ください。
          <br />
          表示の料金は、すべて税込です。
        </div>

        <div className={styles.buttonArea}>
          <Button
            disabled={debounce}
            onClick={async () => {
              save(false);
            }}
            fit
            positive
          >
            承諾する
          </Button>
          <Button
            disabled={debounce}
            onClick={() => closeConfirmFeeModal()}
            fit
          >
            キャンセル
          </Button>
        </div>
      </Modal>

      {/* 削除の確認モーダル */}
      <Modal
        show={isShowDeletingModal}
        title="監視を削除しますか？"
        type="Dialog"
      >
        <div>
          <Button
            onClick={async () => {
              save(true);
            }}
            disabled={debounce}
            fit
            negative
          >
            削除する
          </Button>
          <Button disabled={debounce} onClick={() => closeDeletingModal()} fit>
            キャンセル
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default NotificationSettingsDetail;
