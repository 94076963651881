import cx from "clsx";
import { A, navigate } from "hookrouter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AuthorizedHeader from "../../partials/AuthorizedHeader";
import Blank from "../../partials/Blank";
import Box from "../../partials/Box";
import Button from "../../partials/Button";
import { CheckUser } from "../../partials/checkUser";
import Footer from "../../partials/Footer";
import ItemCard from "../../partials/ItemCard";
import PageTitle from "../../partials/PageTitle";
import ProgressIndicator from "../../partials/ProgressIndicator";
import Wrap from "../../partials/Wrap";
import OrderHistoryDetails from "./details";
import styles from "./index.module.sass";

function OrderHistory(props) {
  const [user, setUser] = useState(null);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(null);

  useEffect(() => {
    onload();
    return () => {
      // on unload
    };
  }, []);

  useEffect(() => {
    if (user) {
      fetchOrderHistory();
    }
  }, [user]);

  const onload = async () => {
    const userData = await CheckUser(props.Auth);
    if (userData) {
      setUser(userData);
    } else {
      navigate("/");
    }
  };

  const fetchOrderHistory = async () => {
    const session = await props.Auth.currentSession();
    const listData = await props.API.get("PurchaseHistory", "/purchase", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    const history = listData.sort((a, b) => {
      if (a.timestamp > b.timestamp) {
        return -1;
      }
      if (a.timestamp < b.timestamp) {
        return 1;
      }
    });
    setList(history);
    setLoading(false);
  };

  if (history != null) {
    return (
      <OrderHistoryDetails
        {...props}
        history={history}
        setHistory={setHistory}
      />
    );
  } else {
    return (
      <>
        <AuthorizedHeader current="menu" />
        <Wrap>
          <PageTitle title="ご注文履歴" backURL="/menu" />

          {/* 読み込み中 */}
          {loading && <ProgressIndicator gutter />}

          {/* 購入履歴なし */}
          {!loading && list.length === 0 && (
            <Blank
              description={
                <div>
                  センサー機器をお持ちでない方は、
                  <A href="/shop/items" className={styles.link}>
                    機器の購入
                  </A>
                  からご購入できます
                </div>
              }
            >
              ご注文履歴はありません
            </Blank>
          )}

          {/* 購入履歴あり */}
          {!loading &&
            list.map((history, idx) => {
              const date = moment(history.timestamp).format("YYYY年 MM月DD日");
              return (
                <Box key={idx} basic gutterBottom>
                  <div className={styles.orderWrap}>
                    <div className={styles.orderTitle}>
                      <div className={styles.date}>{date}</div>
                      <Button
                        size="small"
                        onClick={() => {
                          setHistory(history);
                        }}
                      >
                        詳細
                      </Button>
                    </div>
                    {history.delivered && (
                      <div className={styles.shippingStatus}>発送済み</div>
                    )}
                    {!history.delivered && (
                      <div className={cx(styles.shippingStatus, styles.active)}>
                        準備中
                      </div>
                    )}
                  </div>
                  <div className={styles.orderItem}>
                    <ItemCard
                      imageUrl="/assets/images/icon-router-blue.png"
                      name="親機ルーター"
                      description="Braveridge BraveROUTE"
                      quantity={history.routerCount || 0}
                    />
                  </div>
                  <div className={styles.orderItem}>
                    <ItemCard
                      imageUrl="/assets/images/icon-sensor.png"
                      name="子機センサー（温度・湿度・照度）"
                      description="Braveridge TETRA 防水"
                      quantity={history.deviceCount || 0}
                    />
                  </div>
                </Box>
              );
            })}
        </Wrap>
        <Footer />
      </>
    );
  }
}

export default OrderHistory;
