import React, { useEffect, useState } from 'react';
import styles from './alertMessage.module.sass';
import moment from 'moment';
import parse from 'html-react-parser';

export const useDidMount = (func) => useEffect(() => { func() }, []);
function AlertMessage (props) {

  return <div className={styles.wrap}>
    <div className={styles.body}>
      <div className={styles.alert} >
        {props.alertMessage.message_type == '障害' &&
          <div className={styles.alertCategoryError}>障害情報</div>
        }
        {props.alertMessage.message_type == 'メンテナンス' &&
          <div className={styles.alertCategoryMaintenance}>メンテナンス情報</div>
        }
        {props.alertMessage.message_type == 'お知らせ' &&
          <div className={styles.alertCategoryNotice}>お知らせ</div>
        }
        <div className={styles.alertMessage}>{parse(props.alertMessage.message)}</div>
        <div className={styles.alertDate}>
          {moment(props.alertMessage.created_at).format('YYYY年MM月DD日 HH時mm分')}
        </div>
      </div>
    </div>
  </div>
}

export{AlertMessage};
