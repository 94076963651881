import cx from 'clsx'
import React from 'react';
import { A, navigate } from 'hookrouter';
import PropTypes from 'prop-types';
import ButtonClose from '../partials/ButtonClose';
import styles from './AuthorizedHeader.module.sass';

function AuthorizedHeader({ children, current, user, simple, type, backURL, onClose }){

  return (
    <>
      {type === "TabNavigation" && (
        <>
          <div className={styles.wrap}>
            <div className={styles.mainNavigations}>
              <A href="/dashboard" className={cx(
                styles.navigation,
                current == 'home' ? styles.current : ''
              )}>
                <div className={cx(styles.icon,styles.sensor)}>
                  {current === 'home' 
                    ? <img src="/assets/images/navigation-sensor.png" width="27" /> 
                    : <img src="/assets/images/navigation-sensor-inactive.png" width="27" />
                  }
                  
                </div>
                <div className={styles.label}>センサー</div>
              </A>
              <A href="/notification_settings" className={cx(
                styles.navigation,
                current == 'notification_settings' ? styles.current : ''
              )}>
                <div className={styles.icon}>
                  { current === 'notification_settings' 
                      ? <img src="/assets/images/navigation-notification.png" width="29" /> 
                      : <img src="/assets/images/navigation-notification-inactive.png" width="28" />
                  }
                </div>
                <div className={styles.label}>監視と通知</div>
              </A>
              <A href="/menu" className={cx(
                styles.navigation,
                current == 'menu' ? styles.current : ''
              )}>
                <div className={cx(styles.icon,styles.menu)}>
                  {current === 'menu' 
                    ? <img src="/assets/images/navigation-menu.png" width="22" /> 
                    : <img src="/assets/images/navigation-menu-inactive.png" width="22" />
                  }
                  
                </div>
                <div className={styles.label}>その他</div>
              </A>
            </div>
          </div>
          <div className={styles.offset} />
        </>
      )}
      {type === "Close" && (
        <>
          <div className={styles.wrapSimple}>
            <div className={styles.headerCloseButton}>
              <ButtonClose onClick={() => {
                if (onClose != null) {
                  onClose();
                } else {
                  navigate(backURL)
                }
              }} />
            </div>
          </div>
          <div className={styles.offsetSimple} />
        </>
      )}
    </>
  )
}

AuthorizedHeader.propTypes = {
  children: PropTypes.node,
  current: PropTypes.string,
  simple: PropTypes.bool,
  type: PropTypes.string,
  backURL: PropTypes.string,
}

AuthorizedHeader.defaultProps = {
  type: "TabNavigation"
}

export default AuthorizedHeader
