import React, { useEffect, useState } from 'react';
import { Spinner, ProgressBar, Modal, Button } from 'react-bootstrap';

export const useDidMount = (func) => useEffect(() => { func() }, []);
function Backdrop(props) {
  const [showClose, setShowClose] = useState(false);
  useDidMount(async () => {
    //if (props.onClose) {
    //  setTimeout(() => {
    //    //const r = window.confirm('通信エラーです。処理を中断しますか？');
    //    //if (r) {
    //    //  props.onClose();
    //    //}
    //    setShowClose(true);
    //  }, 180000);
    //}
  });
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return  (
    <Modal onHide={handleClose}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {showClose &&
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
      }
      {!showClose &&
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
      }
      <Modal.Body>
        <ProgressBar now={props.progress} label={`${props.progress}%`} />
      </Modal.Body>
      <Modal.Footer>
        <div><Spinner variant="primary" animation="border" /> {props.label}</div>
      </Modal.Footer>
      </Modal>
  );
}

export{Backdrop};
