import { navigate } from 'hookrouter';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './PageTitle.module.sass';

function PageTitle({ children, title, subTitle, backURL }){
  return (
    <div className={styles.wrap}>
      { backURL && (
        <div onClick={() => navigate(backURL)} className={styles.backButton} />
      )}
      <div>
      <div className={styles.title}>{title}</div>
      {subTitle && (
        <div className={styles.subTitle}>{subTitle}</div>
      )}
      </div>
      {children}
    </div>
  )
}

PageTitle.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  backURL: PropTypes.string,
};

export default PageTitle