import cx from 'clsx'
import React from 'react';
import PropTypes from 'prop-types';
import styles from './DefinitionList.module.sass';

function DefinitionList({ children, title, gutterBottom, borderBottom }){
  return (
    <div className={cx(
      styles.wrap,
      gutterBottom ? styles.gutterBottom : null,
      borderBottom ? styles.borderBottom : null
    )}>
      <div className={styles.title}>{title}</div>
      <div className={styles.contents}>{children}</div>
    </div>
  )
}

DefinitionList.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  gutterBottom: PropTypes.bool,
  borderBottom: PropTypes.bool
}

export default DefinitionList