import React, { useEffect, useState } from 'react';
import { navigate } from 'hookrouter';
import { CheckUser } from '../../partials/checkUser';
import { Alert, Modal } from 'react-bootstrap';
import AuthorizedHeader from '../../partials/AuthorizedHeader';
import Button from '../../partials/Button';
import Checkbox from "../../partials/Checkbox";
import Footer from '../../partials/Footer';
import styles from './Confirm.module.sass';
import Wrap from '../../partials/Wrap';
import PageTitle from '../../partials/PageTitle';
export const useDidMount = (func) => useEffect(() => { func() }, []);

function UnsubscribeConfirm(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [user, setUser] = useState({});
  const [agreeToNotes, setAgreeToNotes ] = useState(false);

  const onload = async () => {
    let currentUser = await CheckUser(props.Auth);
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate('/', false);
    }
  };

  useDidMount(async () => {
    onload();
  });

  const handleClick = async () => {
    const session = await props.Auth.currentSession();
    try {
      await props.API.post('Unsubscribe', '/unsubscribe', {
        headers: {Authorization: session.idToken.jwtToken}, body: {}});
      await props.Auth.signOut();
      navigate('/unsubscribe/finish', false);
    } catch(e) {
      setShowAlert(true);
    }
  };

  if (!user.attributes) {
    return <div></div>;
  }

  return (<>
   <AuthorizedHeader current="menu" />
    {showAlert &&
      <Alert style={{borderRadius:0}} variant="danger">通信エラーが発生しました</Alert>
    }
    <Wrap>
      <PageTitle title="ご解約の確認" backURL="/unsubscribe" />
      <div className={styles.text}>・解約申請を行なった時点でご購入した全ての機器は使用できなくなります。</div>
      <div className={styles.text}>・通知が届かなくなり、全ての設定が削除されます。
      <br />・月額料金は解約月まで発生します。<br />・解約月の翌々月まで請求がある場合があります。</div>
      {/* <Text variant="h2" gutterBottom>ご契約者　{user.attributes.name}さまのサービスを解約する</Text> */}
      <div className={styles.checkboxWrap}>
        <Checkbox
          checked={agreeToNotes}
          label="解約の注意事項に同意する"
          onClick={(e) => {setAgreeToNotes(!agreeToNotes)}}
        />
        <div className={styles.checkboxText}>※ 解約後のキャンセルはできません。</div>
      </div>
      <div className={styles.buttonWrap}>
        <Button negative fit onClick={handleClick} disabled={!agreeToNotes}>解約する</Button>
        <div style={{ marginTop:'8px'}}>
          <Button fit onClick={() => {window.history.back();}}>キャンセル</Button>
        </div>
      </div>
    </Wrap>
    <Footer />
    </>);
}

export default UnsubscribeConfirm;
