import React, { useEffect, useState } from "react";
import { CalcPrice } from "../../../../web/src/partials/calcPrice";
import { Config } from "../../config";
import AlertBox from "../../partials/AlertBox";
import Box from "../../partials/Box";
import Button from "../../partials/Button";
import CheckoutSummary from "../../partials/CheckoutSummary";
import Footer from "../../partials/Footer";
import InvoicePaymentAuthorizationModal from "../../partials/InvoicePaymentAuthorizationModal";
import ItemCard from "../../partials/ItemCard";
import PageTitle from "../../partials/PageTitle";
import PurchaseHeader from "../../partials/PurchaseHeader";
import Stepper from "../../partials/Stepper";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import { AtomicCounter } from "../../partials/atomicCounter";
import { CheckUser } from "../../partials/checkUser";
import styles from "./Review.module.sass";
export const useDidMount = (func) =>
  useEffect(() => {
    func();
  }, []);

function Review(props) {
  const [user, setUser] = useState({});
  const [debounce, setDebounce] = useState(false);
  const [carrierForm, setCarrierForm] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [savedData, setSavedData] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showEditButton, setShowEditButton] = useState(false);
  const [
    isShowingInvoicePaymentAuthorizationModal,
    setShowingInvoicePaymentAuthorizationModal,
  ] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (savedData != null) {
      saveSession();
    }
  }, [savedData]);

  useEffect(() => {
    if (uniqueId != null) {
      startPayment();
    }
  }, [uniqueId]);

  useEffect(() => {
    if (carrierForm != null && carrierForm.startUrl) {
      setTimeout(() => {
        document.getElementById("carrierForm").submit();
      }, 100);
    } else if (carrierForm != null) {
      //console.log('carrierForm', carrierForm);
    }
  }, [carrierForm]);

  const saveSession = async () => {
    const session = await props.Auth.currentSession();
    const sessionData = await props.API.post("SecureSession", "/session", {
      headers: { Authorization: session.idToken.jwtToken },
      body: savedData,
    });
    const unique_id = sessionData.session_id;
    if (!unique_id) {
      raiseError("通信エラーが発生しました。お手数ですが再度お試しください。");
      setDebounce(false);
    } else {
      setUniqueId(unique_id);
    }
  };

  const startPayment = async () => {
    // GMOのtokenライブラリがasyncに対応しないので
    // card実行後に処理を入れても順序が前後する可能性がある
    const session = await props.Auth.currentSession();
    if (props.paymentMethod == "credit") {
      await card(session);
    } else if (props.paymentMethod == "carrier") {
      await carrier(session);
    } else if (props.paymentMethod == "GMO掛け払い") {
      await gmoCredit(session);
    }
  };

  const onLoad = async () => {
    const me = await CheckUser(props.Auth);
    checkPayment();
    setUser(me);
  };

  const checkPayment = async () => {
    let card = false;
    let carrier = false;
    const session = await props.Auth.currentSession();
    const response = await props.API.get("Purchase", "/payments", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    if (response.length > 0) {
      card = true;
    }
    try {
      const response = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          action: "list-cards",
        },
      });
      if (response[0] != null) {
        carrier = true;
      }
    } catch (e) {
      //setShowEditButton(true);
    }
    const show = carrier || card ? false : true;
    setShowEditButton(show);
  };

  const saveToTmp = async (orderId, totalPrice) => {
    let carrier;
    if (props.paymentMethod == "credit") {
      carrier = 'credit';
    } else if (props.paymentMethod == "GMO掛け払い") {
      carrier = "GMO掛け払い";
    } else {
      carrier = props.carrierBillingType;
    }
    let deviceInfo = '';
    const node = document.getElementById('ps_data_collector');
    if (node != null) {
      deviceInfo = node.value;
    }
    const saveData = {
      user_id: user.attributes.sub,
      email: user.attributes.email,
      orderId: orderId,
      amount: totalPrice,
      orderType: "新規/追加",
      paymentMethod: props.paymentMethod,
      carrier: carrier,
      token: props.carrierToken || "",
      routerCount: props.routerCount,
      deviceCount: props.deviceCount,
      routerPrice: props.routerPrice,
      devicePrice: props.devicePrice,
      shippingAddressName: props.shippingAddressName,
      organizationName: props.organizationName,
      divisionName: props.divisionName,
      shippingAddressName: props.shippingAddressName,
      shippingAddress1: props.shippingAddress1,
      shippingAddress2: props.shippingAddress2,
      shippingAddress3: props.shippingAddress3,
      shippingAddressZipcode: props.shippingAddressZipcode,
      shippingAddressPhoneNumber: props.shippingAddressPhoneNumber,
      billingAddressName: props.billingAddressName || props.shippingAddressName,
      billingOrganizationName: props.billingOrganizationName || props.organizationName,
      billingDivisionName: props.billingDivisionName || props.divisionName,
      billingAddress1: props.billingAddress1 || props.shippingAddress1,
      billingAddress2: props.billingAddress2 || props.shippingAddress2,
      billingAddress3: props.billingAddress3 || props.shippingAddress3,
      billingAddressZipcode: props.billingAddressZipcode || props.shippingAddressZipcode,
      billingAddressPhoneNumber: props.billingAddressPhoneNumber || props.shippingAddressPhoneNumber,
      shipmentFee: props.shipmentFee,
      regularPurchase: false,
      campaignChecked: props.campaignChecked,
      deviceInfo: deviceInfo,
      gmoChargeWithTax: props.paymentMethod == 'GMO掛け払い' ? CalcPrice.gmoChargeWithTax : 0,
      gmoCharge: props.paymentMethod == 'GMO掛け払い' ? CalcPrice.gmoCharge : 0,
    };
    setSavedData(saveData);
  };

  const purchase = async () => {
    setShowErrorMessage(false);
    setDebounce(true);
    const counter = await AtomicCounter.get();
    const orderId = String(counter).padStart(20, "0");
    let totalPrice =
      parseInt(props.devicePrice) +
      parseInt(props.routerPrice) +
      parseInt(props.shipmentFee);
    if (props.paymentMethod == 'GMO掛け払い') {
      totalPrice += parseInt(CalcPrice.gmoChargeWithTax);
    }
    const unique_id = await saveToTmp(orderId, totalPrice);
  };

  const card = async (session) => {
    if (props.cardSeq == null) {
      // カードの新規登録
      try {
        window.Multipayment.init(Config.gmo_shop_id);
        const tokenResponse = await window.Multipayment.getToken(
          {
            cardno: props.cardNumber,
            expire: `${props.cardYear}${props.cardMonth}`,
            securitycode: props.cardCVV,
          },
          async (res) => {
            if (res.resultCode == "000") {
              const token = res.tokenObject.token;
              const data = {
                action: "create-credit-card",
                token: token,
              };
              try {
                const response = await props.API.post("Purchase", "/billing", {
                  headers: { Authorization: session.idToken.jwtToken },
                  body: data,
                });
                await _card();
              } catch (e) {
                raiseError(
                  "カードの登録時にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
                );
                setDebounce(false);
              }
            } else {
              raiseError(
                "カードの登録時にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
              );
              setDebounce(false);
            }
          }
        );
        //const data = {
        //  action: 'create-credit-card',
        //  //cardNo: props.cardNumber,
        //  //expire: props.cardYear,
        //};
        //const response = await props.API.post('Purchase', '/billing', {
        //  headers: {Authorization: session.idToken.jwtToken}, body: data});
      } catch (e) {
        raiseError(
          "カードの登録時にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
        );
        setDebounce(false);
      }
    } else {
      await _card();
    }
  };
  const _card = async () => {
    const session = await props.Auth.currentSession();
    try {
      // カード決済は必ず0番目のカードで決済
      const charge = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          amount: savedData.amount,
          orderId: savedData.orderId,
          cardSeq: 0,
          action: "charge_with_member_id",
          unique_id: uniqueId,
        },
      });
      props.setPage("finish");
    } catch (e) {
      raiseError(
        "カードの決済時にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
      );
      setDebounce(false);
    }
  };

  const raiseError = async (message) => {
    window.scrollTo(0, 0);
    setErrorMessage(message);
    setShowErrorMessage(true);
  };

  const carrier = async (session) => {
    if (props.carrierToken) {
      // 二回目以降
      try {
        const charge = await props.API.post("Purchase", "/billing", {
          headers: { Authorization: session.idToken.jwtToken },
          body: { action: "carrier_casual_payment", data: savedData },
        });
        props.setPage("finish");
      } catch (e) {
        raiseError(
          "キャリア決済にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
        );
        setDebounce(false);
      }
    } else {
      try {
        const charge = await props.API.post("Purchase", "/billing", {
          headers: { Authorization: session.idToken.jwtToken },
          body: {
            sessionId: uniqueId,
            amount: savedData.amount,
            orderId: savedData.orderId,
            carrier: props.carrierBillingType,
            action: "carrier_payment",
            returnUrl: `${Config.api_url_base2}/carrier_payment/${savedData.orderId}/${uniqueId}`,
          },
        });
        if (!charge.startUrl) {
          raiseError(
            "キャリア決済登録にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
          );
          setDebounce(false);
        } else {
          setCarrierForm(charge); //利用許諾にリダイレクト
        }
      } catch (e) {
        raiseError(
          "キャリア決済登録にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
        );
        setDebounce(false);
      }
    }
  };

  const gmoCredit = async (session) => {
    let result;
    if (props.customerId) {
      // 購入企業登録済み
      result = await _gmoCredit(session);
    } else {
      result = await _gmoCreditRegisterCustomer(session);
    }
    if (!result) {
      raiseError(
        "決済時にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
      );
      setDebounce(false);
    } else {
      if (result.message) {
        if (result.message == '審査中') {
          props.setPage("finish_credit");
        } else if (result.message == 'NG') {
          // FIXME
          raiseError("このお支払い方法は現在ご利用いただけません。お手数ですが、別のお支払方法をご利用ください。");
          setDebounce(false);
        } else {
          props.setPage("finish");
        }
      } else {
        throw new Error('不明なエラー');
      }
    }
  };

  const _gmoCreditRegisterCustomer = async (session) => {
    try {
      const charge = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          action: "gmo-credit-create-customer",
          uniqueId: uniqueId,
        },
      });
      if (charge.error) {
        raiseError(
          charge.message
        );
        setDebounce(false);
      } else {
        const chargeResult = await _gmoCredit(session);
        return chargeResult;
      }
    } catch (e) {
      raiseError(
        "決済時にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
      );
      setDebounce(false);
    }
  };

  const _gmoCredit = async (session) => {
    try {
      const charge = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          action: "gmo-credit",
          uniqueId: uniqueId,
        },
      });
      return charge;
    } catch (e) {
      raiseError(
        "決済時にエラーが発生しました。恐れ入りますが入力された内容をご確認の上、再度お試しください。"
      );
      setDebounce(false);
    }
  };

  if (!user.attributes) {
    return <div />;
  }

  return (
    <>
      {carrierForm && (
        <form id="carrierForm" action={carrierForm.startUrl} method="POST">
          <input type="hidden" name="AccessID" value={carrierForm.accessId} />
          <input type="hidden" name="Token" value={carrierForm.token} />
        </form>
      )}
      <PurchaseHeader />
      <Wrap>
        {showErrorMessage && <AlertBox gutterBottom text={errorMessage} />}
        <PageTitle title="内容をご確認ください"></PageTitle>
        <Stepper steps={[1, 2, 3, 4]} current={4} />
        <div className={styles.headerAction}>
          <Button disabled={debounce} onClick={purchase} fit positive>
            注文を確定する
          </Button>
          <div className={styles.agreeUserPolicyText}>
            「注文を確定する」ボタンを押すことで当社の利用規約に同意された事になります。
          </div>
        </div>
        <Box basic gutterBottom>
          <div className={styles.reviewDeviceArea}>
            <Text variant="h3">ご購入製品</Text>
            <Button
              size="small"
              onClick={() => {
                props.setPage("items");
              }}
            >
              変更
            </Button>
          </div>
          <ItemCard
            imageUrl="/assets/images/icon-router-blue.png"
            name="親機ルーター"
            description="Braveridge BraveROUTE"
            description2="メーカー保証 1年"
            price={props.routerPrice}
            monthlyFee={props.routerMonthlyFee}
            quantity={props.routerCount}
          ></ItemCard>
          <div className={styles.divider} />
          <ItemCard
            imageUrl="/assets/images/icon-sensor.png"
            name="子機センサー（温度・湿度・照度）"
            description="Braveridge TETRA 防水"
            description2="メーカー保証 1年"
            price={props.devicePrice}
            monthlyFee={props.deviceMonthlyFee}
            quantity={props.deviceCount}
          ></ItemCard>
          <div className={styles.divider} />
          <CheckoutSummary
            title="初回のみのお支払い料金"
            paymentMethod={props.paymentMethod}
            items={[
              {
                name:
                  props.routerCount > 1
                    ? `親機ルーター端末代金 ×${props.routerCount}`
                    : "親機ルーター端末代金",
                price: props.routerPrice,
              },
              {
                name:
                  props.deviceCount > 1
                    ? `子機センサー端末代金 ×${props.deviceCount}`
                    : "子機センサー端末代金",
                price: props.devicePrice,
              },
              { name: "送料", price: props.shipmentFee },
              { name: "手数料", price: CalcPrice.gmoChargeWithTax},
            ]}
          />
          <div className={styles.divider} />
          <CheckoutSummary
            paymentMethod={props.paymentMethod}
            title="毎月のお支払い料金"
            items={[
              {
                name:
                  props.routerCount > 1
                    ? `親機ルーター月額利用料 ×${props.routerCount}`
                    : "親機ルーター月額利用料",
                price: props.routerMonthlyFee,
              },
              {
                name:
                  props.deviceCount > 1
                    ? `子機センサー月額利用料 ×${props.deviceCount}`
                    : "子機センサー月額利用料",
                price: props.deviceMonthlyFee,
              },
              { name: "手数料", price: CalcPrice.gmoChargeWithTax},
            ]}
          />
          <div className={styles.divider} />
          <div className={styles.checkoutNote}>
            ※上記以外に、監視料金、通知料金、確認料金がそれぞれかかります。詳しくは、
            <a
              href="https://support.tel-chan.com/hc/ja/articles/900004699186-%E8%B3%AA%E5%95%8F-%E3%81%A6%E3%82%8B%E3%81%A1%E3%82%83%E3%82%93%E3%81%AE%E6%96%99%E9%87%91%E4%BD%93%E7%B3%BB%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
              target="_blank"
            >
              こちら
            </a>
            をご確認ください。
            <br />
            ※注文のキャンセル、返品に関しては<a href="https://tel-chan.com/law" target="_blank">こちら</a>をご確認ください
            <br />
            ※表示の料金は、すべて税込です。
          </div>
        </Box>

        {/* キャンペーン期間中のみ表示 */}
        {props.campaignChecked &&(
        <Box basic gutterBottom>
          <div className={styles.reviewDeviceArea}>
            <Text variant="h3">キャンペーン</Text>
          </div>
          <div data-private>
            <Text>【先着50名様】電圧変換器＆接続ケーブルプレゼントキャペーン</Text>
          </div>
        </Box>
        )}

        <Box basic gutterBottom>
          <div className={styles.reviewDeviceArea}>
            <Text variant="h3">お届け先</Text>
            <Button
              onClick={() => {
                props.setPage("shipping");
              }}
              size="small"
            >
              変更
            </Button>
          </div>
          <div data-private>
            <Text>{props.shippingAddressName}</Text>
            {props.organizationName &&
              <Text>{props.organizationName}</Text>
            }
            {props.divisionName &&
              <Text>{props.divisionName}</Text>
            }
            <Text>〒{props.shippingAddressZipcode}</Text>
            <Text>
              {props.shippingAddress1} {props.shippingAddress2}
              {props.shippingAddress3}
            </Text>
            <Text>{props.shippingAddressPhoneNumber}</Text>
          </div>
          <div className={styles.deliveryDateText}>
            お届け予定日
            <strong>約2週間前後</strong>
          </div>
        </Box>

        <Box basic>
          <div className={styles.reviewDeviceArea}>
            <Text variant="h3">お支払い方法</Text>
            {showEditButton && (
              <Button
                size="small"
                onClick={() => {
                  props.setPage("billing");
                }}
              >
                変更
              </Button>
            )}
          </div>
          {props.paymentMethod == "credit" && (
            <>
              <Text>クレジットカード</Text>
              <Text>************{props.cardNumber.substr(-4)}</Text>
              <Text>
                有効期限 {`${props.cardYear.split('').splice(0, 2).join('')}/${props.cardYear.split('').splice(2, 2).join('')}`}
              </Text>
            </>
          )}
          {props.paymentMethod == "carrier" && (
            <>
              <Text>キャリア決済</Text>
              <Text>{props.carrierBillingType}</Text>
            </>
          )}
          {props.paymentMethod == "GMO掛け払い" && (
            <>
              <Text>請求書払い</Text>
              <Text>※製品を購入した月の翌月に請求書を発送します。</Text>
            </>
          )}
        </Box>

        <div className={styles.action}>
          <Button disabled={debounce} onClick={purchase} fit positive>
            注文を確定する
          </Button>
        </div>
        <div className={styles.agreeUserPolicyText}>
          「注文を確定する」ボタンを押すことで当社の利用規約に同意された事になります。
        </div>
      </Wrap>
      <Footer openLinkInBlank={true} />

      {/* 請求書払い審査中モーダル */}
      {/* TODO: GMO掛け払い（変更実行後にモーダルを表示してください。審査に失敗した場合、statusをNGに切り替えてください） */}
      <InvoicePaymentAuthorizationModal
        open={isShowingInvoicePaymentAuthorizationModal}
        status="Progress"
        onClose={() => setShowingInvoicePaymentAuthorizationModal(false)}
      />
    </>
  );
}

export default Review;
