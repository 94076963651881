//FIXME
const endPoint = 'https://backend.prd.tel-chan.com';
const endPoint2 = 'https://api.prd.tel-chan.com'
const endPointOstiaries = 'https://ostiaries.prd.tel-chan.com'

const awsconfig = {
  Auth: {
    region: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:aa9cd882-7960-4c06-b9d6-3107f12b2feb',
    identityPoolRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_2exmbwdCt',
    userPoolWebClientId: '3d20lnrj2jq32131g0kfq9o2hv',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  API: {
    endpoints: [
      {
        name: 'SecureSession',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'StatusMessages',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'AlertToList',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'DeviceList',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'RouterDeviceList',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateDeviceSettings',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateDevice',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateRouter',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateAllDevices',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'GetHistory',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'GetBilling',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateAlertTo',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'GetDispatch',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateDispatch',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateAccount',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'Purchase',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'PurchaseHistory',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'AuditHistory',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'SendContactMail',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'Unsubscribe',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'AlertList',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateDeviceStatus',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'ConfirmLineUser',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'SaveTmpData',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'ConfirmCode',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'ResendConfirmCode',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'NotificationSettings',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'PriceData',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'UpdateProfile',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'EncryptPassword',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'StartPhoneNumberAuthorization',
        endpoint: endPointOstiaries,
        region: 'ap-northeast-1'
      },
      {
        name: 'CheckDeviceStatus',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'SignOut',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'SensorValues',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
      {
        name: 'RouterStatus',
        endpoint: endPoint2,
        region: 'ap-northeast-1'
      },
    ]
  },
};

export{awsconfig};
