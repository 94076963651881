import PropTypes from "prop-types";
import React from "react";
import Modal from "./Modal";
import ProgressIndicator from "./ProgressIndicator";
import styles from "./RouterSuspendingProcessModal.module.sass";
import Text from "./Text";

RouterSuspendingProcessModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function RouterSuspendingProcessModal({ open, onClose }) {
  return (
    <Modal show={open} type="FullScreen">
      <div className={styles.wrap}>
        <ProgressIndicator />
        <Text variant="h3" center className={styles.title}>
          画面を閉じずにお待ちください
        </Text>
        <Text center className={styles.detailText}>停止処理は2分ほどかかります</Text>
      </div>
    </Modal>
  );
}

export default RouterSuspendingProcessModal;
